import { Offer } from './Offer.model';

export class Subscription {
  _id: string;
  startDate: Date;
  endDate: Date;
  idSubscription: string;
  isCanceled: Boolean;
  offer: Offer;
}
