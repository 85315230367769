import { Component, Input } from "@angular/core";
import { File } from "src/app/interfaces/library-manager";

@Component({
    selector: 'app-video',
    templateUrl: './video.component.html',
    styleUrls: ['./video.component.scss']
})
export class VideoComponent {
    @Input() file: File | null ;
    
    formatName(title: string) {
        return title.length > 15 && typeof title !== 'undefined' ? title?.substr(0, 15) + '...' : title;
    }
}