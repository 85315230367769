import {
  AfterViewInit,
  Component,
  ElementRef,
  HostListener,
  OnDestroy,
  OnInit,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ResizeEvent } from 'angular-resizable-element';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap';
import { ClipboardService } from 'ngx-clipboard';
import { ToastrService } from 'ngx-toastr';
import { Event, Space } from 'src/app/models/Event.model';
import { NotificationSpace } from 'src/app/models/NotificationSpace.model';
import { User } from 'src/app/models/User.model';
import { CanvaService } from 'src/app/services/event-core/canva.service';
import { ChatService } from 'src/app/services/event-core/chat.service';
import { EventService } from 'src/app/services/event-core/event.service';
import { UserService } from 'src/app/services/event-core/user.service';
import { CanvaManagerComponent } from '../canva-manager/canva-manager.component';
import { ChatManagerComponent } from '../chat-manager/chat-manager.component';
import { SoundManagerComponent } from '../sound-manager/sound-manager.component';
import { WebcamManagerComponent } from '../webcam-manager/webcam-manager.component';

@Component({
  selector: 'app-core',
  templateUrl: './core.component.html',
  styleUrls: ['./core.component.scss'],
})
export class CoreComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('canvaManager') canvaManager: CanvaManagerComponent;
  @ViewChild('chatManager') chatManager: ChatManagerComponent;
  @ViewChild('soundManager') soundManager: SoundManagerComponent;
  @ViewChild('webcamManager') webcamManager: WebcamManagerComponent;
  @ViewChild('modal') modal: ElementRef;
  @ViewChild('introVideo') introVideo: any;

  //listen resize event
  @HostListener('window:resize', ['$event'])
  onResize(_event) {
    this.screenOrientation =
      window.innerHeight < window.innerWidth ? 'landscape' : 'portrait';
  }
  //listen user close browser
  @HostListener('window:beforeunload', ['$event'])
  beforeLeave() {
    this.canvaManager.leaveSeat();
    this.canvaManager.leaveRoom();
    this.canvaManager.leaveSpace();
    this.exitAll();
    navigator.mediaDevices
      .getUserMedia({ audio: true, video: true })
      .then((res) => {
        res.getVideoTracks()[0].stop();
        res.getAudioTracks()[0].stop();
        res.getTracks()[0].stop();
      })
      .catch();
  }

  public event: Event;
  public user: User;
  public currentSpace: Space;
  public navigationHistory: string[] = []; //array of spaceId
  public modalRef: BsModalRef;
  public showPasswordForm: boolean = false;
  public showShareCodeForm: boolean = false;
  public spacePassword: string;
  public privateSpace: Space;
  public scrIntroVideo: string;
  public isFullScreen: boolean = false;
  public modalInfos: any;
  public screenOrientation: 'landscape' | 'portrait' = 'landscape';
  public emotesStyle = {
    height: undefined,
    padding: false,
  };
  public notifications: NotificationSpace[] = [];
  public numberNotification: number = 0;
  public showHubinarSettings: boolean = false;
  public accessCodeMeetingValid: boolean = false;
  public accessCodeMeetingUser: string = '';
  public isEmojiPickerVisible = false;
  public sendBtnDisabled = false;
  public userCard: {
    user: User;
    coords: {
      x: number;
      y: number;
    };
    isPrivate: boolean;
    showCard?: boolean;
  };

  public showAnimation = {
    isShow: false,
    coords: {
      x: 0,
      y: 0,
    },
  };
  public localVideoSize: {
    width: number;
    height: number;
  } = {
    width: 200,
    height: 110,
  };
  public deviceStatus = {
    microphone: false,
    video: false,
    screen: false,
  };
  private shareCodeData: {
    users: any[];
    spaces: Space[];
  } = {
    users: [],
    spaces: [],
  };
  public localDevices: {
    microphones: any[];
    webcams: any[];
    speakers: any[];
    selectedMicrophone;
    selectedWebcam;
    selectedSpeaker;
    tmpMicrophone;
  } = {
    microphones: [],
    webcams: [],
    speakers: [],
    selectedMicrophone: undefined,
    selectedWebcam: undefined,
    selectedSpeaker: undefined,
    tmpMicrophone: {
      micro: null,
      analyser: null,
      javascriptNode: null,
    },
  };
  public showCanva: Boolean = false;
  public peopleInEvent: User[] = [];
  public otherMenu: boolean = false;
  public showChatbox: boolean = false;
  public numberUnreadMessage: number = 0;
  public clickedWebcam: boolean = false;
  public clickedMicrophone: boolean = false;
  public useCode: boolean = false;
  public showCompter: boolean = false;
  public timestamp: number = 0;
  public access: boolean = false;
  public isMenu: boolean = true;
  public currentTexte: string = '';
  public isGuest: boolean = false;
  public isAdmin: boolean = false;
  public isWhiteboard: boolean = false;
  constructor(
    private eventService: EventService,
    private router: Router,
    private toastr: ToastrService,
    private userService: UserService,
    private activatedRoute: ActivatedRoute,
    private modalService: BsModalService,
    private renderer: Renderer2,
    private canvaService: CanvaService,
    private chatService: ChatService,
    private clipboardService: ClipboardService
  ) {
    this.init();
    this.initialiseDevices();
  }

  async ngOnInit() {
    //listen for intro video ending
    this.introVideoListener();
    this.checkScreenOrientation();
    this.initSubscribers();
    // setTimeout(() => {
    // this.chatManager.initSubscribers();
    // }, 5000);
  }

  private initSubscribers() {
    this.eventService.$knockdoor.subscribe((res) => {
      this.toastr.info(res.name + ' a ouvert la port ' + res.space);
    });

    this.canvaService.$updating.subscribe(() => {
      this.toastr.info(
        'Vous devez actualiser la page pour avoir les dernières modifications!'
      );
    });
  }
  private async init() {
    try {
      this.user = await this.userService.getCurrentUser().toPromise();
    } catch (error) {}
    const paramsId: string = this.activatedRoute.snapshot.params.id;
    console.log('params: ', paramsId);
    if (!this.user) {
      if (localStorage.getItem('guest')) {
        this.user = await JSON.parse(localStorage.getItem('guest'));
        this.isGuest = true;
      } else {
        this.router.navigate(['public', 'landing', paramsId]);
      }
    }
    //check if direct link to meet
    if (window.location.toString().includes('meeting')) {
      const currentUrl: string = window.location.toString();
      let linkMeet: string;
      if (currentUrl.includes('app.hublive')) {
        linkMeet = 'https://app.hublive.io/#/meeting/' + paramsId;
      } else if (currentUrl.includes('localhost')) {
        linkMeet = 'http://localhost:4200/#/meeting/' + paramsId;
      } else if (currentUrl.includes('upstream')) {
        linkMeet = 'https://upstream.upskilling.com/#/meeting/' + paramsId;
      } else {
        linkMeet = 'https://hublive.io/#/meeting/' + paramsId;
      }
      this.eventService.getByMeetingPath(linkMeet).subscribe(
        (result: any) => {
          const idSpace: string = result.event.spaces.find((space) =>
            space.elements.find((element) => element._id === result.meetingId)
          )._id;
          const directLinkMeet = {
            isDirect: true,
            link: ['core-test', 'event', result.event._id],
            meetingId: result.meetingId,
            eventId: result.event._id,
            spaceId: idSpace,
            codeMeet: this.extractCodeMeet(
              result.event,
              result.meetingId,
              idSpace
            ),
          };
          //save direct link data to local storage
          localStorage.setItem(
            'directLinkMeet',
            JSON.stringify(directLinkMeet)
          );
          if (this.user) {
            this.router.navigate(['core-test', 'event', result.event._id]);
          } else {
            this.router.navigate(['public', 'guest', result.event._id]);
          }
        },
        () => {
          this.router.navigate(['']);
        }
      );
    } else if (window.location.toString().includes('space')) {
      const currentUrl: string = window.location.toString();
      let linkSpace: string;
      if (currentUrl.includes('app.hublive')) {
        linkSpace = 'https://app.hublive.io/#/space/' + paramsId;
      } else if (currentUrl.includes('localhost')) {
        linkSpace = 'http://localhost:4200/#/space/' + paramsId;
      } else if (currentUrl.includes('upstream')) {
        linkSpace = 'https://upstream.upskilling.com/#/space/' + paramsId;
      } else {
        linkSpace = 'https://hublive.io/#/space/' + paramsId;
      }
      this.eventService.getEventBySpaceId(linkSpace).subscribe(
        (result: any) => {
          const idSpace: string = result.spaceId;
          const directLinkSpace = {
            isDirect: true,
            spaceId: idSpace,
            direct: paramsId,
            eventId: result.event._id,
          };
          // //save diirect link data to local storage
          localStorage.setItem(
            'directLinkSpace',
            JSON.stringify(directLinkSpace)
          );
          if (this.user) {
            this.router.navigate(['core-test', 'event', result.event._id]);
          } else {
            this.router.navigate(['public', 'guest', result.event._id]);
          }
        },
        () => {
          this.router.navigate(['']);
        }
      );
    } else {
      this.eventService
        .getEventById(paramsId)
        .toPromise()
        .then(
          async (event) => {
            this.event = event;
            this.deviceStatus.microphone = event.micActive;
            this.deviceStatus.video = event.camActive;
            this.currentSpace = event.spaces[0];
            if (
              this.currentSpace.isLandingVideo &&
              this.currentSpace.landingVideo.length > 1 &&
              !localStorage.getItem(this.currentSpace.landingVideo)
            ) {
              if (this.currentSpace.landingVideo.includes('youtube')) {
                const ampersandPosition =
                  this.currentSpace.landingVideo.indexOf('?v=');
                if (ampersandPosition !== -1) {
                  this.scrIntroVideo = this.currentSpace.landingVideo.substring(
                    ampersandPosition + 3,
                    this.currentSpace.landingVideo.length
                  );
                }
              } else {
                this.scrIntroVideo = this.currentSpace.landingVideo;
              }
            }
            this.initPeopleInEvent();
            await this.delay(1000);
            const isOwner =
              this.event?.owner.toString() === this.user?._id.toString();
            // @ts-ignore
            const isShared =
              this.event.shared.indexOf(
                this.user?._id.toString() as string & User
              ) > -1;
            if (isShared || isOwner) this.isAdmin = true;
            this.webcamManager.deviceStatus.video = this.event.camActive;
            this.soundManager.deviceStatus.microphone = this.event.micActive;
          },
          () => {
            this.router.navigate(['public', 'landing', paramsId]);
          }
        )
        .finally(() => {
          //case direct link to space
          if (this.user) {
            const directLinkSpace = JSON.parse(
              localStorage.getItem('directLinkSpace')
            );
            const newDirectLinkSpace = JSON.parse(
              localStorage.getItem('newDirectLinkSpace')
            );
            if (
              directLinkSpace &&
              directLinkSpace.isDirect &&
              directLinkSpace.spaceId &&
              directLinkSpace.spaceId !== this.currentSpace._id
            ) {
              localStorage.setItem(
                'newDirectLinkSpace',
                localStorage.getItem('directLinkSpace')
              );
              localStorage.removeItem('directLinkSpace');
              window.location.reload();
            }

            if (
              newDirectLinkSpace &&
              newDirectLinkSpace.isDirect &&
              newDirectLinkSpace.spaceId &&
              newDirectLinkSpace.spaceId !== this.currentSpace._id
            ) {
              setTimeout(() => {
                this.changeSpace(newDirectLinkSpace.spaceId);
              }, 1000);
              localStorage.removeItem('newDirectLinkSpace');
            }
            //case direct link to meeting
            const directLinkMeet = JSON.parse(
              localStorage.getItem('directLinkMeet')
            );
            const newDirecLinkMee = JSON.parse(
              localStorage.getItem('newDirecLinkMee')
            );
            if (directLinkMeet && directLinkMeet.isDirect) {
              console.log('directLinkMeet------->', directLinkMeet);
              if (directLinkMeet.codeMeet) {
                this.openModal({ type: 'askCodeMeet' });
              } else {
                localStorage.setItem(
                  'newDirecLinkMee',
                  localStorage.getItem('directLinkMeet')
                );
                localStorage.removeItem('directLinkMeet');
                window.location.reload();
                // setTimeout(() => {
                //   this.changeSpace(directLinkMeet.spaceId);
                // }, 1000);
                // setTimeout(() => {
                //   this.canvaManager.hasCurrentMeeting = true;
                //   this.canvaService.setCurrentMeeting$.next(
                //     directLinkMeet.meetingId
                //   );
                // }, 2000);
              }
            }

            if (newDirecLinkMee && newDirecLinkMee.isDirect) {
              console.log('directLinkMeet------->', directLinkMeet);
              if (newDirecLinkMee.codeMeet) {
                this.openModal({ type: 'askCodeMeet' });
              } else {
                setTimeout(() => {
                  this.changeSpace(newDirecLinkMee.spaceId);
                }, 1000);
                setTimeout(() => {
                  this.canvaManager.hasCurrentMeeting = true;
                  this.canvaService.setCurrentMeeting$.next(
                    newDirecLinkMee.meetingId
                  );
                }, 2000);
              }
            }
          }
        });
    }
    //susbscribe new message
    this.chatService.numberUnreadMessage$.subscribe((data) => {
      switch (data.type) {
        case 'init':
          this.numberUnreadMessage = data.nb;
          break;
        case 'add':
          this.numberUnreadMessage += data.nb;
          break;
        case 'remove':
          this.numberUnreadMessage -= data.nb;
          break;
        default:
          break;
      }
    });

    this.userService.currentUserClicked$.subscribe((res) => {
      if (res.user && this.user) {
        this.userCard = {
          user: res.user,
          coords: {
            x: res.coords.x,
            y: res.coords.y,
          },
          isPrivate: res.user.privateKey?.length >= 5 ? true : false,
        };
      }
    });

    this.userService.$showInformation.subscribe((res) => {
      this.showAnimation = {
        isShow: true,
        coords: {
          x: res.coords.x,
          y: res.coords.y,
        },
      };
    });
    this.userService.currentStatus$.subscribe((status) => {
      this.userCard.showCard = status;
    });
  }

  public checkPrivacy(value: string) {
    return this.userCard.user.privateKey != undefined
      ? this.userCard.user.privateKey.includes(value)
      : false;
  }
  public checkKey(key: string) {
    return this.userCard.user.address != undefined
      ? this.userCard.user.address.hasOwnProperty(key)
      : false;
  }

  public toTranslate(x: any, y: any) {
    let transforme: string = '';
    let vw = document.body.offsetWidth;
    let vh = document.body.offsetHeight;
    if (x > vw / 2) transforme += ' translateX(-100%) ';
    if (y > vh / 2) transforme += ' translateY(-100%) ';
    return transforme !== '' ? 'transform: ' + transforme : transforme;
  }

  public addEmoji(event: any) {
    this.chatManager.messageContent += event.emoji.native;
    this.isEmojiPickerVisible = false;
  }

  public async sendMessageCard() {
    this.sendBtnDisabled = true;
    this.chatManager.onSendMessage(this.userCard.user._id);
    this.chatManager.setCurrentReceiver(this.userCard.user);
    setTimeout(() => {
      this.sendBtnDisabled = false;
    }, 1500);
  }

  async ngAfterViewInit() {
    this.showCanva = true;
    await this.delay(1000);
    // this.canvaManager.$changeSpace.subscribe(async (idSpace) => {
    //   this.showCanva = false;
    //   await this.delay(500);
    //   this.changeSpace(String(idSpace));
    // });
  }

  public changeSpace(idSpace: string) {
    // this.mediasoupService.exit();
    try {
      this.canvaManager.leaveRoom();
    } catch (error) {}
    this.navigationHistory.push(this.currentSpace._id);
    const targetSpace = this.event.spaces.find(
      (space) => space._id === idSpace
    );
    if (!targetSpace) {
      this.toastr.error("Espace n'existe plus");
      return;
    }

    // var closes = [localStorage.getItem('close')];
    this.currentTexte = targetSpace.texte;
    this.access = targetSpace.accessCode !== '';
    this.showCompter = targetSpace.showCompter;
    this.timestamp = new Date(targetSpace.OpeningDate).getTime() / 1000;
    const now = new Date().getTime() / 1000;
    if (
      !targetSpace.isPrivate ||
      (targetSpace.isPrivate && !targetSpace.close) ||
      (targetSpace.isPrivate && targetSpace.close && this.timestamp < now)
    ) {
      this.showCanva = false;
      this.currentSpace = targetSpace;
      this.loadSpace();
    } else if (
      targetSpace.isPrivate &&
      targetSpace.close &&
      this.timestamp > now /*&& !closes?.includes(targetSpace._id)*/
    ) {
      this.privateSpace = targetSpace;
      this.eventService.onknockDoor(
        this.event._id,
        this.user.firstName,
        this.currentSpace.title
      );
      this.openModal({ type: 'privateSpace' });
    }
  }

  public getTimestamp(value: Date) {
    return new Date(value).getTime() / 1000;
  }

  onAccessPrivateSpace(space: Space, code: string) {
    if (code === space.accessCode) {
      this.currentSpace = space;
      if (!this.currentSpace.messages) this.currentSpace.messages = [];
      // const existing = localStorage.getItem('close');
      // const close = existing ? existing.split(',') : [];
      // if (!close.includes(this.currentSpace._id))
      //   close.push(this.currentSpace._id);
      // if (this.currentSpace.codeAccessNumber > 0) {
      //   localStorage.setItem('close', close.toString());
      //   this.currentSpace.close = false;
      //   const tmpSpace = space;
      //   let i = 0;
      //   const time = setInterval(() => {
      //     i += 1;
      //     if (i === tmpSpace.codeAccessNumber * 60) {
      //       const idx = this.event.spaces.findIndex(
      //         (s) => s._id === tmpSpace._id.toString()
      //       );
      //       this.event.spaces[idx].close = true;
      //       const closes = [localStorage.getItem('close')];
      //       const closeTmp = closes.filter((e) => e != tmpSpace._id.toString());
      //       localStorage.setItem('close', closeTmp.toString());
      //       // this.changeSpace(this.currentSpace._id);
      //       clearInterval(time);
      //     }
      //     console.log('anatiny');
      //   }, 1000);
      // }
      this.modalRef.hide();
      this.showCanva = false;
      this.loadSpace();
    } else {
      this.toastr.error('Code incorrect!');
    }
    this.spacePassword = '';
  }

  async loadSpace() {
    this.eventService.onChangeSpace(
      this.event._id,
      this.currentSpace._id,
      this.currentSpace.title,
      this.user._id,
      this.user.firstName,
      this.user.lastName
    );
    //detect if space has landing video
    console.log('onchange space *********** ');
    if (
      this.currentSpace.isLandingVideo &&
      this.currentSpace.landingVideo.length > 1 &&
      !localStorage.getItem(this.currentSpace.landingVideo)
    ) {
      if (this.currentSpace.landingVideo.includes('youtube')) {
        const ampersandPosition = this.currentSpace.landingVideo.indexOf('?v=');
        if (ampersandPosition !== -1) {
          this.scrIntroVideo = this.currentSpace.landingVideo.substring(
            ampersandPosition + 3,
            this.currentSpace.landingVideo.length
          );
        }
      } else {
        this.scrIntroVideo = this.currentSpace.landingVideo;
      }
    }
    await this.delay(500);
    this.showCanva = true;
    // const id = this.eventService.directLinkData.isDirect ? this.eventService.directLinkData.eventId : this.route.snapshot.params.id;

    //case direct link to meeting
    const directLinkMeet = JSON.parse(localStorage.getItem('newDirecLinkMee'));
    if (directLinkMeet && directLinkMeet.isDirect) {
      localStorage.removeItem('newDirecLinkMee');
      await this.delay(1000);
      console.log('check if');

      // this.canvaManager.joinDirectMeet(directLinkMeet.meetingId);
      this.canvaManager.setAvatarPosition$.next({
        meetingId: directLinkMeet.meetingId,
        avatarId: this.user._id,
      });
    }
  }

  async getConnectedUsers() {
    return await this.userService.getConnected().toPromise();
  }

  onVideoEventChange($event) {
    if ($event.data === 0) {
      localStorage.setItem(this.currentSpace.landingVideo, 'true');
      this.scrIntroVideo = '';
      this.canvaService.$isAnimation.next(true);
    }
  }

  onSkipVideo() {
    localStorage.setItem(this.currentSpace.landingVideo, 'true');
    this.scrIntroVideo = '';
    this.canvaService.$isAnimation.next(true);
  }

  public isModeratorCurrentSpace(): boolean {
    return this.currentSpace.moderator.includes(<string & User>this.user._id);
  }

  public isModeratorCurrentMeeting(): boolean {
    return (
      this.canvaManager.currentMeeting?.attrs.moderatorMeet.findIndex(
        (elem) => elem.email == this.user.email
      ) != -1
    );
  }

  public isModeratorCurrentEvent(): boolean {
    return (
      this.event.owner.toString() === this.user._id.toString() ||
      this.event.shared.indexOf(this.user?._id.toString() as string & User) > -1
    );
  }

  public canChangeVirtualBg(): boolean {
    if (this.event) {
      return (
        this.event.canChangeBackgroundVideo &&
        (this.event.typeUserCanChangeBgVideo == 'all' ||
          this.event.typeUserCanChangeBgVideo == 'hasAccount')
      );
    } else {
      return false;
    }
  }

  //ctrl buttons - left side
  public onRefresh() {
    let currentUrl = this.router.url;
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.router.navigate([currentUrl]);
    });
  }

  public onPrevious() {
    console.log('previous');

    if (this.navigationHistory && this.navigationHistory.length > 0) {
      this.changeSpace(
        this.navigationHistory[this.navigationHistory.length - 1]
      );
      this.navigationHistory.pop();
    }
  }

  public onClickHome() {
    if (this.currentSpace._id !== this.event.spaces[0]._id) {
      this.changeSpace(this.event.spaces[0]._id);
    }
  }

  public toggleFullscreen() {
    if (!this.isFullScreen) {
      const elem = document.documentElement as any;
      const methodToBeInvoked =
        elem.requestFullscreen ||
        elem.webkitRequestFullScreen ||
        elem['mozRequestFullscreen'] ||
        elem['msRequestFullscreen'];
      if (methodToBeInvoked) methodToBeInvoked.call(elem);
      this.isFullScreen = true;
    } else {
      this.onCloseFullSreen();
    }
  }

  private onCloseFullSreen() {
    if (this.isFullScreen) {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if ((document as any).webkitExitFullscreen) {
        /* Safari */
        (document as any).webkitExitFullscreen();
      } else if ((document as any).msExitFullscreen) {
        /* IE11 */
        (document as any).msExitFullscreen();
      }
      this.isFullScreen = false;
    }
  }

  public onExit() {
    this.canvaManager.exitHubinar();
    this.canvaManager.leaveSpace();
    this.canvaManager.disconnectSocket();
    localStorage.removeItem('guest');
    localStorage.removeItem('firstConnexion');
    this.router.navigate(['public', 'landing', this.event._id]);
    this.eventService.exitEvent(this.event._id, this.user._id);
  }

  public exitAll() {
    this.canvaManager.exitHubinar();
    this.canvaManager.leaveSpace();
    this.canvaManager.disconnectSocket();
    localStorage.removeItem('firstConnexion');
    this.eventService.exitEvent(this.event._id, this.user._id);
  }

  public openStats() {
    this.openModal({ type: 'stats' });
  }

  public openShareSpaceCode() {
    this.openModal({ type: 'shareHubdoorCode' });
  }
  //end ctrl buttons - left side

  //ctrl buttons - right side
  public onToggleMicrophone() {
    this.deviceStatus.microphone = !this.deviceStatus.microphone;
    console.log('devicestatus ', this.localDevices.selectedMicrophone);
    this.soundManager.addLocalMicrophone(this.localDevices.selectedMicrophone);
  }
  public onToggleCamera() {
    this.deviceStatus.video = !this.deviceStatus.video;
    console.log('devicestatus webcam', this.localDevices.selectedWebcam);
    this.webcamManager.addLocalWebcam(this.localDevices.selectedWebcam);
  }
  public onToggleScreen() {
    this.deviceStatus.screen = !this.deviceStatus.screen;
    this.webcamManager.addLocalScreen();
  }
  public onToggleDeviceSettings() {
    this.openModal({ type: 'settings', value: '' });
  }
  public onOpenHelp() {
    this.openModal({
      type: 'iframe',
      value: 'https://www.youtube.com/embed/Bvtg8wzfFs8?autoplay=1',
    });
  }
  public openAnnouncementDialog() {
    this.openModal({ type: 'announcement' });
    // this.announcementManager.openDialogSetting()
  }
  //end ctrl buttons - right side

  //modal handler
  public openModal(event: any) {
    let size = 'modal-sm';
    let options: ModalOptions = { class: size, show: true };
    switch (event.type) {
      case 'iframe':
        options.class = 'modal-lg';
        break;
      case 'stats':
        options.class = 'modal-lg';
        break;
      case 'announcement':
        options.class = 'modal-lg';
        break;
      case 'linkSettings':
        options.class = 'modal-lg';
        break;
      case 'askCodeMeet':
        options.class = 'modal-md';
        break;
      case 'directLink':
        options.class = 'modal-md';
        break;
      case 'shareHubdoorCode':
        options = {
          ...options,
          ignoreBackdropClick: true,
          class: 'to-width',
        };
        break;
      case 'privateSpace':
        options = {
          ...options,
          ignoreBackdropClick: true,
          class: 'to-width',
        };
        console.log('options ********** ', options);
        break;
      case 'settings':
        options.class = 'modal-md';
        this.testMicrophone();
        break;
    }
    this.modalInfos = event;
    this.modalRef = this.modalService.show(this.modal, options);
  }

  private initialiseDevices() {
    navigator.mediaDevices.enumerateDevices().then((devices) => {
      this.localDevices.microphones = [];
      this.localDevices.webcams = [];
      this.localDevices.speakers = [];
      for (const device of devices) {
        if (device.kind === 'audioinput')
          this.localDevices.microphones.push(device);
        if (device.kind === 'videoinput')
          this.localDevices.webcams.push(device);
        if (device.kind === 'audiooutput')
          this.localDevices.speakers.push(device);
      }
      if (this.localDevices.microphones.length > 0) {
        if (localStorage.getItem('microphone')) {
          const index = this.localDevices.microphones.findIndex(
            (e) => e.deviceId === localStorage.getItem('microphone')
          );
          if (index > -1)
            this.localDevices.selectedMicrophone =
              this.localDevices.microphones[index].deviceId;
        } else {
          this.localDevices.selectedMicrophone =
            this.localDevices.microphones[0].deviceId;
        }
      }
      if (this.localDevices.webcams.length > 0) {
        if (localStorage.getItem('webcam')) {
          const index = this.localDevices.webcams.findIndex(
            (e) => e.deviceId === localStorage.getItem('webcam')
          );
          if (index > -1)
            this.localDevices.selectedWebcam =
              this.localDevices.webcams[index].deviceId;
        } else {
          this.localDevices.selectedWebcam =
            this.localDevices.webcams[0].deviceId;
        }
      }
      if (this.localDevices.speakers.length > 0)
        this.localDevices.selectedSpeaker =
          this.localDevices.speakers[0].deviceId;
    });
  }

  public hidePopup() {
    this.modalRef.hide();
  }

  //modal share code handler
  public onModalShareCodeChange(
    type: 'userChecked' | 'spaceChecked' | 'shareCode',
    event?: any,
    data?: any
  ) {
    switch (type) {
      case 'userChecked':
        if (event.target.checked) {
          this.shareCodeData.users.push(data);
        } else {
          this.shareCodeData.users = this.shareCodeData.users.filter(
            (e) => e.userId != data.userId
          );
        }
        break;
      case 'spaceChecked':
        if (event.target.checked) {
          this.shareCodeData.spaces.push(data);
        } else {
          this.shareCodeData.spaces = this.shareCodeData.spaces.filter(
            (e) => e._id != data._id
          );
        }
        break;
      case 'shareCode':
        if (
          this.shareCodeData.users.length > 0 &&
          this.shareCodeData.spaces.length > 0
        ) {
          this.shareCodeData.spaces.forEach((space: Space) => {
            this.shareCodeData.users.forEach((usr) => {
              this.chatManager.messageContent =
                "Voici le code d'accès: " +
                space.accessCode +
                ' de la porte ' +
                space.title;
              this.chatManager.onSendMessage(usr.userId);
              this.hidePopup();
            });
          });
        }
        break;

      default:
        break;
    }
  }

  private async testMicrophone(focusOnSpeaker?: boolean) {
    //celui qui parle
    const $this = this;
    var micro = await navigator.mediaDevices.getUserMedia({
      audio: { deviceId: this.localDevices.selectedMicrophone },
    });
    var audioContext = new AudioContext();
    var analyser = audioContext.createAnalyser();
    var microphone = audioContext.createMediaStreamSource(micro);
    var javascriptNode = audioContext.createScriptProcessor(2048, 1, 1);

    analyser.smoothingTimeConstant = 0.8;
    analyser.fftSize = 1024;
    if (
      this.localDevices.tmpMicrophone.micro &&
      this.localDevices.tmpMicrophone.analyser &&
      this.localDevices.tmpMicrophone.javascriptNode
    ) {
      this.localDevices.tmpMicrophone.micro.disconnect();
      this.localDevices.tmpMicrophone.analyser.disconnect();
      this.localDevices.tmpMicrophone.javascriptNode.disconnect();
    }
    this.localDevices.tmpMicrophone.micro = microphone;
    this.localDevices.tmpMicrophone.analyser = analyser;
    this.localDevices.tmpMicrophone.javascriptNode = javascriptNode;
    microphone.connect(analyser);
    analyser.connect(javascriptNode);
    javascriptNode.connect(audioContext.destination);
    javascriptNode.onaudioprocess = () => {
      const array = new Uint8Array(analyser.frequencyBinCount);
      analyser.getByteFrequencyData(array);
      let values = 0;
      const length = array.length;
      for (let i = 0; i < length; i++) {
        values += array[i];
      }
      const average = values / length;
      if (focusOnSpeaker && $this.deviceStatus.microphone) {
        $this.soundManager.onSpeaking({
          eventId: $this.event._id,
          userId: $this.user._id,
          spaceId: $this.currentSpace._id,
          volume: Math.round(average),
        });
      }
      $this.updateVolume(Math.round(average));
    };
  }

  private updateVolume(audioVolume) {
    const all_pids = document.querySelectorAll('.pid');
    const amout_of_pids = Math.round(audioVolume / 10);
    all_pids.forEach((elem, i) => {
      if (i < amout_of_pids) {
        (elem as HTMLElement).style.backgroundColor = 'greenyellow';
      } else {
        (elem as HTMLElement).style.backgroundColor = 'black';
      }
    });
  }

  private introVideoListener() {
    if (this.introVideo) {
      this.renderer.listen(this.introVideo.getVideoTag(), 'ended', () => {
        this.onSkipVideo();
      });
    }
  }

  public adjustVolumeBackgroundSound(event: any) {
    this.soundManager.changeBackgroundSound(event.value);
  }

  private checkScreenOrientation() {
    if (window.innerHeight > window.innerWidth) {
      this.screenOrientation = 'portrait';
    }
  }

  public async onChangeMicrophone() {
    localStorage.setItem('microphone', this.localDevices.selectedMicrophone);
    if (this.webcamManager.connected && this.deviceStatus.microphone) {
      this.soundManager.removeLocalMicrophone();
      await this.soundManager.addLocalMicrophone(
        this.localDevices.selectedMicrophone
      );
    }
    this.testMicrophone();
  }

  public async onChangeWebcam() {
    localStorage.setItem('webcam', this.localDevices.selectedWebcam);
    if (this.webcamManager.connected && this.deviceStatus.video) {
      this.webcamManager.removeLocalWebcam();
      await this.webcamManager.addLocalWebcam(this.localDevices.selectedWebcam);
    }
  }

  public toggleNotificationMenu(menu) {
    if (this.notifications.length > 0) {
      menu.classList.toggle('showElement-notification');
      if (this.numberNotification > 0) {
        this.numberNotification = 0;
      }
    }
  }

  public notifyLocal(message) {
    const notification = new NotificationSpace();
    notification.text = message;
    notification.sender = this.user._id;
    notification.createdDate = new Date();
    this.notifications.unshift(notification);
    this.numberNotification++;
  }

  onResizeLocalVideo(event: ResizeEvent) {
    this.localVideoSize = {
      width: event.rectangle.width,
      height: event.rectangle.height,
    };
  }

  extractCodeMeet(event: Event, meetingId: string, spaceId: string) {
    const space = event.spaces.find((elem) => elem._id === spaceId);
    if (space) {
      const codeMeet =
        space.elements[
          space.elements.findIndex((item) => item._id === meetingId)
        ].codeMeet;
      if (codeMeet) {
        return codeMeet;
      }
    }
    return '';
  }

  onExitMeeting() {
    this.hidePopup();
    localStorage.removeItem('directLinkData');
    this.canvaManager.resetAvatarPosition$.next(this.user._id);
    //   this.modalRef = undefined;
    //   if (this.eventService.directLinkData.incorrectAvatarId) {
    //     this.eventService.moveAvatar$.next({
    //       id: this.eventService.directLinkData.incorrectAvatarId,
    //       x: 200,
    //       y: 200,
    //     });
    //   } else this.router.navigate(['']);
    //   this.modalOpen = false;
  }

  onVerifyMeetCode() {
    this.accessCodeMeetingValid = false;
    const directLinkData = JSON.parse(localStorage.getItem('directLinkData'));
    if (directLinkData && directLinkData.codeMeet && directLinkData.avatarId) {
      if (this.accessCodeMeetingUser == directLinkData.codeMeet) {
        this.canvaService.$startMeeting.next(true);
        localStorage.removeItem('directLinkData');
        this.hidePopup();
      } else {
        this.accessCodeMeetingValid = true;
        this.accessCodeMeetingUser = '';
      }
      return;
    }

    const directLinkMeet = JSON.parse(localStorage.getItem('directLinkMeet'));
    if (this.accessCodeMeetingUser == directLinkMeet.codeMeet) {
      this.changeSpace(directLinkMeet.spaceId);
      this.hidePopup();
      setTimeout(() => {
        this.canvaService.setCurrentMeeting$.next(directLinkMeet.meetingId);
        this.canvaManager.hasCurrentMeeting = true;
      }, 3000);
    } else {
      this.accessCodeMeetingValid = true;
      this.accessCodeMeetingUser = '';
      localStorage.removeItem('directMeetLink');
    }
  }

  async delay(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  ngOnDestroy() {
    if (this.isFullScreen) {
      this.onCloseFullSreen();
    }
    try {
      this.canvaManager.leaveSpace();
    } catch (error) {}
    try {
      this.exitAll();
    } catch (error) {}
    navigator.mediaDevices
      .getUserMedia({ audio: true, video: true })
      .then((res) => {
        res.getVideoTracks()[0].stop();
        res.getAudioTracks()[0].stop();
        res.getTracks()[0].stop();
      })
      .catch();
  }

  async enableWebcam() {
    this.clickedWebcam = true;
    this.deviceStatus.video = true;
    if (
      this.webcamManager.connected ||
      this.currentSpace.activeCameraPastille
    ) {
      this.webcamManager.changeCameraStatus(true);
      if (this.canvaManager.hubinarData.isHubinarMode) {
        if (this.canvaManager.canToggle.camera) {
          this.webcamManager.addLocalWebcam(this.localDevices.selectedWebcam);
        } else {
          this.toastr.error('Unauthorized');
        }
      } else {
        this.webcamManager.addLocalWebcam(this.localDevices.selectedWebcam);
      }
    }
    await this.delay(2000);
    this.clickedWebcam = false;
  }

  async desableWebcam() {
    this.clickedWebcam = true;
    this.deviceStatus.video = false;
    this.webcamManager.changeCameraStatus(false);
    if (!this.canvaManager.isInvite) this.canvaManager.addPeople();
    this.webcamManager.removeLocalWebcam();
    await this.delay(2000);
    this.clickedWebcam = false;
  }

  async enableMicrophone() {
    console.log(this.localDevices);
    this.deviceStatus.microphone = true;
    if (
      this.webcamManager.connected ||
      this.currentSpace.activeCameraPastille
    ) {
      if (this.canvaManager.hubinarData.isHubinarMode) {
        if (this.canvaManager.canToggle.microphone) {
          this.soundManager.addLocalMicrophone(
            this.localDevices.selectedMicrophone
          );
          this.canvaManager.removeMicrophoneOnAvatar(this.user, true);
        } else {
          this.toastr.error('Unauthorized');
        }
      } else {
        this.soundManager.addLocalMicrophone(
          this.localDevices.selectedMicrophone
        );
        this.canvaManager.removeMicrophoneOnAvatar(this.user, true);
      }
    }
  }

  async desableMicrophone() {
    this.deviceStatus.video = false;
    this.soundManager.removeLocalMicrophone();
    this.canvaManager.addMicrophoneOnAvatar(this.user, null, true);
    await this.delay(2000);
  }

  shareScreen() {
    if (this.webcamManager.connected && !this.webcamManager.isShareScreen) {
      if (this.canvaManager.hubinarData.isHubinarMode) {
        if (this.canvaManager.canToggle.screen) {
          this.webcamManager.addLocalScreen();
        } else {
          this.toastr.error('Unauthorized');
        }
      } else {
        this.webcamManager.addLocalScreen();
      }
    } else {
      this.webcamManager.removeLocalScreen();
    }
  }

  toggleMenu() {
    this.otherMenu = !this.otherMenu;
  }

  initPeopleInEvent() {
    const sub = this.canvaService.$getAnotherUserConnectedInEvent.subscribe(
      (data) => {
        if (data.eventId === this.event._id) {
          data.people.push(this.user);
          this.peopleInEvent = data.people;
          sub.unsubscribe();
          // console.log('this.peopleInEvent', this.peopleInEvent);
        }
      }
    );
    this.eventService.updatePeopleInEvent$.subscribe((response) => {
      if (response.eventId === this.event._id) {
        this.peopleInEvent = response.people;
        // console.log('this.peopleInEvent', this.peopleInEvent);
      }
    });
  }

  changeViewmode() {
    this.webcamManager.changeViewMode();
  }

  closeModal() {
    if (
      this.localDevices.tmpMicrophone.micro &&
      this.localDevices.tmpMicrophone.analyser &&
      this.localDevices.tmpMicrophone.javascriptNode
    ) {
      this.localDevices.tmpMicrophone.micro.disconnect();
      this.localDevices.tmpMicrophone.analyser.disconnect();
      this.localDevices.tmpMicrophone.javascriptNode.disconnect();
    }
    this.modalRef.hide();
  }

  produceVideoHubinar(isCamera) {
    isCamera ? this.enableWebcam() : this.desableWebcam();
  }
  produceAudioHubinar(isMicrophone) {
    isMicrophone ? this.enableMicrophone() : this.desableMicrophone();
  }
  produceScreenHubinar(isScreen) {
    if (!isScreen && this.webcamManager.isShareScreen)
      this.webcamManager.removeLocalScreen();
  }

  generateMeetLink() {
    const currentUrl: string = window.location.toString();
    let newLink: string;
    if (currentUrl.includes('app.hublive.io')) {
      newLink =
        'https://app.hublive.io/#/meeting/' +
        this.randomString(3) +
        '-' +
        this.randomString(2) +
        '-' +
        this.randomString(3);
    } else if (currentUrl.includes('localhost')) {
      newLink =
        'http://localhost:4200/#/meeting/' +
        this.randomString(3) +
        '-' +
        this.randomString(2) +
        '-' +
        this.randomString(3);
    } else if (currentUrl.includes('upstream')) {
      newLink =
        'https://upstream.upskilling.com/#/meeting/' +
        this.randomString(3) +
        '-' +
        this.randomString(2) +
        '-' +
        this.randomString(3);
    } else if (currentUrl.includes('https://hublive.io/')) {
      newLink =
        'https://hublive.io/#/meeting/' +
        this.randomString(3) +
        '-' +
        this.randomString(2) +
        '-' +
        this.randomString(3);
    }
    this.canvaManager.currentMeeting.attrs.linkMeet = newLink;
    this.canvaService.updateMeeting(
      'update',
      this.canvaManager.currentMeeting,
      'update',
      this.currentSpace._id,
      this.event._id
    );
  }

  copyMeetLink() {
    this.clipboardService.copy(this.canvaManager.currentMeeting.attrs.linkMeet);
    this.toastr.success('Lien du meeting copié');
  }

  deleteMeetLink() {
    this.canvaManager.currentMeeting.attrs.linkMeet = '';
    this.canvaService.updateMeeting(
      'update',
      this.canvaManager.currentMeeting,
      'update',
      this.currentSpace._id,
      this.event._id
    );
  }

  generateMeetCode(code?: boolean) {
    let newCode: string = '';
    if (code) {
      newCode = this.canvaManager.currentMeeting.attrs.codeMeet;
    } else {
      newCode = this.randomString(6);
    }
    this.canvaManager.currentMeeting.attrs.codeMeet = newCode;
    this.canvaService.updateMeeting(
      'update',
      this.canvaManager.currentMeeting,
      'update',
      this.currentSpace._id,
      this.event._id
    );
  }

  copyMeetCode() {
    this.clipboardService.copy(this.canvaManager.currentMeeting.attrs.codeMeet);
    this.toastr.success("Code d'accès au meeting copié");
  }

  deleteMeetCode() {
    this.canvaManager.currentMeeting.attrs.codeMeet = '';
    this.canvaService.updateMeeting(
      'update',
      this.canvaManager.currentMeeting,
      'update',
      this.currentSpace._id,
      this.event._id
    );
  }

  randomString(length: number) {
    var result = '';
    var characters =
      'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }
  startOrStopRecord(isRecord) {
    if (isRecord) {
      this.webcamManager.startRecord();
    } else {
      this.webcamManager.stopRecord();
    }
  }

  pauseOrResumeRecord(isPaused) {
    isPaused
      ? this.webcamManager.pauseRecord()
      : this.webcamManager.resumeRecord();
  }

  toggleBackgroundSound() {
    this.soundManager.backgourndSoundActive =
      !this.soundManager.backgourndSoundActive;
  }

  openVbgSettings() {
    this.webcamManager.openVbgSettings();
  }

  openOrCloseMenu() {
    this.isMenu = !this.isMenu;
  }

  async onfinishCountdown() {
    const idx = await this.event.spaces.findIndex(
      (space) => space._id === this.privateSpace._id
    );
    if (idx != -1) {
      this.event.spaces[idx].close = false;
      this.changeSpace(this.event.spaces[idx]._id);
    }
  }
}
