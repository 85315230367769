import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Socket } from 'ngx-socket-io';
import { Observable, Subject } from 'rxjs';
import { Event } from 'src/app/models/Event.model';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class EventService {
  private API_URL = environment.apiUrl;
  public updatePeopleInEvent$ = new Subject<any>();
  public $knockdoor = new Subject<any>();
  constructor(private httpClient: HttpClient, private socket: Socket) {
    this.initSocket();
  }

  private async initSocket() {
    await this.socket.connect();
    this.socket.on('peopleUpdate', (content) => {
      if (content.type === 'peopleInEvent') {
        console.log('**people e**', content);
        this.updatePeopleInEvent$.next(content.people);
      } else if (content.type === 'knockedDoor') {
        this.$knockdoor.next(content.data);
      }
    });
  }

  public getEventById(id: string): Observable<Event> {
    return this.httpClient.get<Event>(this.API_URL + 'events/public/' + id);
  }

  public getEventBySpaceId(path: string): Observable<Event> {
    return this.httpClient.post<any>(
      this.API_URL + 'events/getEventBySpaceId',
      { path: path }
    );
  }

  public getByMeetingPath(path: string): Observable<any> {
    return this.httpClient.post<any>(this.API_URL + 'events/meetingPath', {
      path: path,
    });
  }

  public onChangeSpace(
    eventId: string,
    spaceId: string,
    spaceName: string,
    userId: string,
    userFirstName: string,
    userLastName: string
  ) {
    this.socket.emit('spaceChange', {
      eventId: eventId,
      spaceId: spaceId,
      spaceName: spaceName,
      userId: userId,
      userFirstName: userFirstName,
      userLastName: userLastName,
      status: 'connected',
      date: new Date().getTime(),
    });
  }

  public onknockDoor(eventId: string, userName: string, spaceTitle: string) {
    this.socket.emit('knockDoor', {
      eventId: eventId,
      name: userName,
      space: spaceTitle,
    });
  }

  public exitEvent(eventId: string, userId: string) {
    this.socket.emit('removeChange', {
      userId: userId,
      eventId: eventId,
    });
  }
}
