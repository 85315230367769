import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { User } from 'src/app/models/User.model';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private API_URL = environment.apiUrl;
  public currentUserClicked$ = new Subject<{
    user: User;
    coords: {
      x: number;
      y: number;
    };
  }>();
  public $showInformation = new Subject<{
    coords: {
      x: number;
      y: number;
    };
  }>();
  public currentStatus$ = new Subject<boolean>();

  constructor(private httpClient: HttpClient) {}

  public getUserById(id: string): Observable<User> {
    return this.httpClient.get<User>(this.API_URL + 'users/get/' + id);
  }

  public getCurrentUser(): Observable<User> {
    return this.httpClient.get<User>(this.API_URL + 'users/current');
  }

  public getConnected(): Observable<any> {
    return this.httpClient.get<any>(this.API_URL + 'users/userConnected');
  }

  public getUserInRoom(roomId: string): Observable<any> {
    return this.httpClient.get<any>(
      this.API_URL + 'users/user-in-meets/' + roomId
    );
  }

  public getSpeaker(roomId: string): Observable<any> {
    return this.httpClient.get<any>(
      this.API_URL + 'users/user-speaker/' + roomId
    );
  }
}
