import Konva from 'konva';
import { User } from './User.model';
import { GuestWebinar, Webinar } from './Webinar';

export enum agendaElementType {
  'BLOCK' = 'BLOCK',
  'ALERT' = 'ALERT',
  'HUBINAR' = 'HUBINAR',
}

enum spaceElementType {
  'REDIRECT' = 'REDIRECT',
  'ZONE' = 'ZONE',
  'SEAT' = 'SEAT',
  'ICON' = 'ICON',
  'HUBINAR' = 'HUBINAR',
  'SHARE' = 'SHARE',
  'CAMERA' = 'CAMERA',
}

export class Message {
  user: User;
  date: Date;
  content: string;
}

export class Emoji {
  name: String;
  isActive: Boolean;
  mute: Boolean;
  srcAudio: String;
}

export class SpaceElement {
  _id: string;
  type: string = 'REDIRECT' as spaceElementType;
  title = '';
  values = [] as Array<string>;
  args = [] as Array<any>;
  webinar?: Webinar = null;
  hideImage?: boolean = true;
  intro = [] as Array<string>;
  feedback = false;
  mosaic = false;
  saveMeet = false;
  contentFeedback = [] as Array<any>;
  responseFeedback = [] as Array<any>;
  hubinarId = '';
  userId = '';
  moderatorMeet = [];
  imageOrColor = 'Mettre un couleur';
  bgColor = '';
  points?: number[] = [];
  link? = '';
  linkTitle? = '';
  linkMeet = '';
  codeMeet = '';
  isActiveWelcomeSound = false;
  welcomeSound = '';
  notification = [];
}

export class Space {
  _id: string;
  title = '';
  backgroundImage = '';
  backgroundSound = '';
  isLandingVideo = false;
  landingVideo = '';
  isChat = false;
  backgroundChat = '';
  backgroundLeft = '';
  backgroundRight = '';
  colorRest = '';
  isDefault = false;
  elements = [] as Array<SpaceElement>;
  messages = [] as Array<Message>;
  moderator = [] as Array<String>;
  nbInvite = 20;
  isPrivate = false;
  close = false;
  texte = '';
  informModerator = false;
  accessCode = '';
  showCompter = false;
  OpeningDate = new Date();
  codeAccessNumber = 0;
  userAccess = [] as any;
  chatBulle = false;
  activeCameraPastille = false;
  durationBulle = 2;
  activeEmojis = false;
  emoji = [] as Array<Emoji>;
  isDirectLink = false;
  directLink = '';
}

// export class AgendaElement {
//     _id: string;
//     title = '';
//     type = 'BLOCK' as agendaElementType
//     startDate = new Date();
//     endDate = new Date();
//     color = '#1c3459';
//     description = '';
//     moderator = [] as Array<string>;
//     idEvent = '';
//     idSpace = '';
//     idZone = '';
//     guestList = [] as Array<GuestWebinar>;
//     isRecord: boolean = false;
//     isPublic: Boolean = false;
//     descriptionShort: string = '';
//     descriptionLong: string = '';
//     mainImage = '';
//     capacity: number = 10;
//     sharedLink = '';
// }
export class AgendaElement {
  _id: string;
  owner: User | string;
  title: string = '';
  startDate = new Date();
  endDate = new Date();
  descriptionShort: string = '';
  descriptionLong: string = '';
  image = '';
  capacity: number = 10;
  socialFacebook = '';
  socialInstagram = '';
  socialTwitter = '';
  socialLinkedin = '';
  guestList = [] as Array<GuestWebinar>;
  isRecord: boolean = false;
  isPublic: boolean = false;
  color = '#ce008e';
  idEvent = '';
  idSpace = '';
  idZone = '';
  url = '';
  top: number = 30;
  font: string = '';
  newTab: boolean = true;
}

// export class AgendaElement {
//     _id: string;
//     title: string = '';
//     startDate = new Date();
//     endDate = new Date();
//     image = '';
//     color = '#ce008e';
//     idEvent = '';
//     idSpace = '';
//     url = '';
//     top: number = 30;
//     font: string = '';
//     newTab: boolean = false;
// }

export class Badge {
  _id: string;
  title: string = '';
  icon: string = '';
  user = [] as Array<String>;
}

export class Hours {
  monday = { open: 0, close: 0 };
  tuesday = { open: 0, close: 0 };
  wednesday = { open: 0, close: 0 };
  thursday = { open: 0, close: 0 };
  friday = { open: 0, close: 0 };
  saturday = { open: 0, close: 0 };
  sunday = { open: 0, close: 0 };
}

export class Guest {
  firstName? = '';
  lastName? = '';
  email = '';
  _id? = '';
}

export const Category = [
  { label: 'Divers', value: 'Divers', isActive: true },
  { label: 'Yoga', value: 'Yoga', isActive: false },
  { label: 'Culture', value: 'Culture', isActive: false },
  { label: 'Jeux vidéo', value: 'Jeux vidéo', isActive: false },
  { label: 'Sport', value: 'Sport', isActive: false },
  { label: 'Randonné', value: 'Randonne', isActive: false },
  { label: 'Musique', value: 'Musique', isActive: false },
  { label: 'Cuisine', value: 'Cuisine', isActive: false },
  { label: 'Voyage', value: 'Voyage', isActive: false },
  { label: 'Nature', value: 'Nature', isActive: false },
];

export class Event {
  _id: string;
  owner: User | string;
  shared = [] as Array<User> | Array<string>;
  title = '';
  company = '';
  startDate = new Date();
  endDate = new Date();
  durationString = '';
  descriptionShort = '';
  descriptionLong = '';
  mainImage = '';
  landingImage = '';
  galery = [] as Array<string>;
  isPublic: boolean = true;
  guestList = [] as Array<Guest>;
  shareableLink = '';
  capacity = 50;
  agenda = [] as Array<AgendaElement>;
  spaces = [] as Array<Space>;
  isLandingPage = false;
  landingPageSlug = '';
  socialFacebook = '';
  socialInstagram = '';
  socialTwitter = '';
  socialLinkedin = '';
  isPermanent = false;
  hours: Hours;
  badge = [] as Array<Badge>;
  navigationButton = false;
  timeActif = 5;
  timeDeconnexion = 15;
  autoDeconnect = false;
  autoScroll = false;
  category: string = '';
  annonce = [];
  micActive: boolean = false;
  camActive: boolean = false;
  hasOpeningDate: boolean = false;
  customOpeningDate: boolean = false;
  openingDate = new Date();
  canAccessLP: boolean = true;
  hasBgColor: boolean = false;
  bgColor: string = '';
  favorite = [] as Array<String>;
  tailleBande = 50;
  statVisibility = 0;
  periode = 0;
  sendOpeningEmail: boolean = true;
  highlightResources: boolean = true;
  highlightHubdoor: boolean = true;
  highlightIcon: boolean = true;
  highlightHubinar: boolean = true;
  highlightHubdoorColor: string = 'rgb(255,255,255)';
  highlightIconColor: string = 'rgb(255,255,255)';
  highlightHubinarColor: string = 'rgb(255,255,255)';
  canChangeBackgroundVideo: boolean = false;
  typeUserCanChangeBgVideo: string = 'all';
  virtualBackground = [] as Array<String>;
  positionAccessButton: string = '';
  buttonName: string = '';
  showFilter = [];
  groupOfPeople = [];
  isActive: Boolean = true;
  banList: string[] = [];
}

export class KonvaAnimation {
  _id: string;
  animation: Konva.Animation;
}
