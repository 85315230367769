import { Component, OnInit, HostListener } from '@angular/core';
import {User} from '../../../models/User.model';
import {AuthService} from '../../../services/auth.service';
import {ToastrService} from 'ngx-toastr';
import {TranslateService} from '@ngx-translate/core';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
  selector: 'app-passwordreset',
  templateUrl: './passwordreset.component.html',
  styleUrls: ['./passwordreset.component.scss']
})
export class PasswordresetComponent implements OnInit {
  loading = false;
  user = new User();
  password: string;
  constructor(private authService: AuthService,
    private toastr: ToastrService,
    private translate: TranslateService,
    private router: Router,
    private route: ActivatedRoute) { }

  ngOnInit(): void {
    var body = document.getElementsByTagName("body")[0];
    body.classList.add("register-page");
    var token = this.route.snapshot.params.token;
    this.authService.vefiryTokenPassword(token).subscribe((result) => {
        if(result.canModify) {
            console.log('resul =====> ', result);
            this.user = result.user;
        } else {
            this.router.navigate(['/']);
        }
    });
  }

  resetPassword() {
    this.loading = true;
    this.user.password = this.password;
    this.authService.setNewPassword(this.user).subscribe((result) => {
        console.log(result);
        if(result.success) {
            this.toastr.success("Mot de passe modifié avec succès ! vous pouvez maintenant vous conneter");
            localStorage.setItem('userPasswordReset', result.email);
            this.router.navigate(['/login']);
        } else {
            this.toastr.warning(result.msg);
        }
        this.loading = false;
    });
  }

  validPassword() {
      return this.password && this.password != '';
  }
}
