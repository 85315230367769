import { Component, OnInit, OnDestroy } from "@angular/core";
import {User} from '../../../models/User.model';
import {AuthService} from '../../../services/auth.service';
import {ToastrService} from 'ngx-toastr';
import {TranslateService} from '@ngx-translate/core';
import {Router} from '@angular/router';

@Component({
    selector: "app-loginpage",
    templateUrl: "loginpage.component.html",
    styleUrls: ['./loginpage.component.scss']
})
export class LoginpageComponent implements OnInit, OnDestroy {

    user = new User();
    confirmPassword: string;
    terms = false;
    register = false;
    registred = false;

    isCollapsed = true;
    focus;
    focus1;
    focus2;
    loading = false;
    redirectPage: string = null;
    constructor(private authService: AuthService,
                private toastr: ToastrService,
                private translate: TranslateService,
                private router: Router) {}
    // @HostListener("document:mousemove", ["$event"])
    // onMouseMove(e) {
    //     var squares1 = document.getElementById("square1");
    //     var squares2 = document.getElementById("square2");
    //     var squares3 = document.getElementById("square3");
    //     var squares4 = document.getElementById("square4");
    //     var squares5 = document.getElementById("square5");
    //     var squares6 = document.getElementById("square6");
    //     var squares7 = document.getElementById("square7");
    //     var squares8 = document.getElementById("square8");

    //     var posX = e.clientX - window.innerWidth / 2;
    //     var posY = e.clientY - window.innerWidth / 6;

    //     squares1.style.transform =
    //         "perspective(500px) rotateY(" +
    //         posX * 0.05 +
    //         "deg) rotateX(" +
    //         posY * -0.05 +
    //         "deg)";
    //     squares2.style.transform =
    //         "perspective(500px) rotateY(" +
    //         posX * 0.05 +
    //         "deg) rotateX(" +
    //         posY * -0.05 +
    //         "deg)";
    //     squares3.style.transform =
    //         "perspective(500px) rotateY(" +
    //         posX * 0.05 +
    //         "deg) rotateX(" +
    //         posY * -0.05 +
    //         "deg)";
    //     squares4.style.transform =
    //         "perspective(500px) rotateY(" +
    //         posX * 0.05 +
    //         "deg) rotateX(" +
    //         posY * -0.05 +
    //         "deg)";
    //     squares5.style.transform =
    //         "perspective(500px) rotateY(" +
    //         posX * 0.05 +
    //         "deg) rotateX(" +
    //         posY * -0.05 +
    //         "deg)";
    //     squares6.style.transform =
    //         "perspective(500px) rotateY(" +
    //         posX * 0.05 +
    //         "deg) rotateX(" +
    //         posY * -0.05 +
    //         "deg)";
    //     squares7.style.transform =
    //         "perspective(500px) rotateY(" +
    //         posX * 0.02 +
    //         "deg) rotateX(" +
    //         posY * -0.02 +
    //         "deg)";
    //     squares8.style.transform =
    //         "perspective(500px) rotateY(" +
    //         posX * 0.02 +
    //         "deg) rotateX(" +
    //         posY * -0.02 +
    //         "deg)";
    // }

    ngOnInit() {
        var body = document.getElementsByTagName("body")[0];
        body.classList.add("register-page");
        // this.onMouseMove(event);
    }

    ngOnDestroy() {
        var body = document.getElementsByTagName("body")[0];
        body.classList.remove("register-page");
    }

    // areAllFieldFill() {
    //     return this.user.password && (/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(this.user.email))
    // }


    // onLogin() {
    //     this.loading = true;
    //       this.authService.loginWithPassword(this.user.email, this.user.password).then((result) => {
    //         if (result.success) {
    //             this.toastr.success('CONNEXION REUSSIE');
    //             if(this.redirectPage != null) {
    //                 window.open('/#/public/event/' + this.redirectPage, '_self');
    //             } else {
    //                 if(result.firstConnexion) {
    //                     this.router.navigate(['member/welcome']);
    //                 } else {
    //                     this.router.navigate(['member']);
    //                 }
    //             }
    //             console.log('result ', result);
    //         } else {
    //             if(result.activation) {
    //                 this.toastr.warning(result.msg);
    //             } else {
    //                 console.log(result);
    //                 this.toastr.warning(result.msg);
    //             }
    //         }
    //         this.loading = false;
    //     }, () => {
    //         this.toastr.warning('Impossible de se connecter');
    //         this.loading = false;
    //     });
    // }

    // onLoginWithGoogle() {
    //     this.loading = true;
    //     this.authService.loginWithGoogle().then((result) => {
    //         if (!!result) {
    //             this.toastr.success('CONNEXION REUSSIE');
    //             this.router.navigate(['member']);
    //         } else {
    //             this.toastr.warning('Impossible de se connecter');
    //         }
    //         this.loading = false;
    //     }, () => {
    //         this.toastr.warning('Impossible de se connecter');
    //         this.loading = false;
    //     });
    // }
    // onLoginWithFacebook() {
    //     this.loading = true;
    //     this.authService.loginWithFacebook().then((result) => {
    //         if (!!result) {
    //             this.toastr.success('CONNEXION REUSSIE');
    //             this.router.navigate(['member']);
    //         } else {
    //             this.toastr.warning('Impossible de se connecter');
    //         }
    //         this.loading = false;
    //     }, () => {
    //         this.toastr.warning('Impossible de se connecter');
    //         this.loading = false;
    //     });
    // }
}
