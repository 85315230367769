import { Injectable } from '@angular/core';
import {environment} from '../../environments/environment';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Router} from '@angular/router';
import {Observable} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class UploadService {
    API_URL = environment.apiUrl;

    constructor(private http: HttpClient, private router: Router) { }

    public uploadFiles(files: any): Observable<any> {
        const formData: FormData = new FormData();
        for (const file of files) {
            formData.append('files', file, file.name);
        }
        return this.http.post<any>(this.API_URL + 'uploads/', formData);
    }

    public deleteFile(fileName: String, file): Observable<any> {
        return this.http.post<any>(this.API_URL + 'uploads/delete/' + fileName, file);
    }
}
