import { Injectable } from '@angular/core';
import { Camera } from '@mediapipe/camera_utils';
import { SelfieSegmentation } from '@mediapipe/selfie_segmentation';
import * as mediasoupClient from 'mediasoup-client';
import { Socket } from 'ngx-socket-io';
import { BehaviorSubject, Subject } from 'rxjs';
import { User } from 'src/app/models/User.model';
import { MultiStreamRecorder } from '../../../../node_modules/recordrtc/RecordRTC.js';
import { saveAs } from 'file-saver';
import { Space } from 'src/app/models/Event.model.js';

@Injectable({
  providedIn: 'root',
})
export class MediasoupService {
  public $newVideo: Subject<{
    user: User;
    type: string;
    src: MediaStream;
    id: string;
  }> = new Subject<{
    user: User;
    type: string;
    src: MediaStream;
    id: string;
  }>();
  public $newAudio: Subject<{ user: User; src: MediaStream; id: string }> =
    new Subject<{
      user: User;
      src: MediaStream;
      id: string;
    }>();
  public $removeVideo: Subject<string> = new Subject<string>();
  public $removeAudio: Subject<string> = new Subject<string>();
  public $inRoom: Subject<boolean> = new Subject<boolean>();
  public $isPresenting: Subject<boolean> = new Subject<boolean>();
  public $newUserInRoom: Subject<any> = new Subject<any>();
  public $cameraStatus: Subject<boolean> = new Subject<boolean>();
  public $userToMute: Subject<{ user: User; isMute: boolean }> = new Subject<{
    user: User;
    isMute: boolean;
  }>();
  public $currentSpeaker: BehaviorSubject<string> = new BehaviorSubject<string>(
    undefined
  );
  public $isRecording: Subject<boolean> = new Subject<boolean>();
  public $showCountdown: Subject<boolean> = new Subject<boolean>();
  public $timeleft: Subject<number> = new Subject<number>();
  public consumers;
  public producers;
  public selectedDevices;
  public socket: Socket;
  public producerTransport;
  public consumerTransport;
  public producerLabel;
  public mediasoupClient;
  public type: string;
  public mediaType = {
    audio: 'audioType',
    video: 'videoType',
    screen: 'screenType',
  };
  public virtualBackGround: String = '';
  public roomId: string;
  public recorder;
  public recordIsPaused: boolean = false;
  public recordIsStopped: boolean = false;
  public $audioStatus: Subject<boolean> = new Subject<boolean>();
  constructor() {
    this.mediasoupClient = mediasoupClient;
    this.producerTransport = null;
    this.consumerTransport = null;
    this.selectedDevices = null;
    this.roomId = null;

    this.consumers = new Map();
    this.producers = new Map();

    this.producerLabel = new Map();
  }

  async initTransports(device, user) {
    // init producerTransport
    await this.socket.emit(
      'createWebRtcTransport',
      {
        forceTcp: false,
        rtpCapabilities: device.rtpCapabilities,
      },
      async (data) => {
        if (data.error) {
          console.error(data.error);
          return;
        }
        this.producerTransport = device.createSendTransport(data);

        this.producerTransport.on(
          'connect',
          async ({ dtlsParameters }, callback, errback) => {
            this.socket.emit(
              'connectTransport',
              {
                dtlsParameters,
                transport_id: data.id,
              },
              (response) => {
                callback(response);
              }
            );
          }
        );

        this.producerTransport.on(
          'produce',
          async ({ kind, rtpParameters }, callback) => {
            try {
              this.socket.emit(
                'produce',
                {
                  producerTransportId: this.producerTransport.id,
                  kind,
                  rtpParameters,
                  type: this.type,
                  user: user,
                },
                (producerId) => {
                  callback({
                    id: producerId,
                  });
                }
              );
            } catch (err) {
              console.log(err);
            }
          }
        );

        this.producerTransport.on('connectionstatechange', (state) => {
          switch (state) {
            case 'connecting':
              break;

            case 'connected':
              // localVideo.srcObject = stream
              break;

            case 'failed':
              this.producerTransport.close();
              break;

            default:
              break;
          }
        });
      }
    );

    // init consumerTransport
    await this.socket.emit(
      'createWebRtcTransport',
      {
        forceTcp: false,
      },
      async (data) => {
        if (data.error) {
          console.error(data.error);
          return;
        }
        console.log('createWebRtcTransport');

        // only one needed
        this.consumerTransport = device.createRecvTransport(data);

        this.consumerTransport.on(
          'connect',
          ({ dtlsParameters }, callback, errback) => {
            this.socket.emit(
              'connectTransport',
              {
                transport_id: this.consumerTransport.id,
                dtlsParameters,
              },
              (resp) => {
                callback();
              }
            );
          }
        );
        this.consumerTransport.on('connectionstatechange', async (state) => {
          switch (state) {
            case 'connecting':
              break;

            case 'connected':
              break;

            case 'failed':
              this.consumerTransport.close();
              break;

            default:
              break;
          }
        });
        this.socket.emit('getProducers');
      }
    );
  }

  async initSockets() {
    await this.socket.connect();
    await this.socket.on('consumerClosed', ({ consumer_id }) => {
      console.log('closing consumer:', consumer_id);
      this.removeConsumer(consumer_id); // here source of error
    });

    /**
     * data: [ {
     *  producer_id:
     *  producer_socket_id:
     * }]
     */
    await this.socket.on('newProducers', async (data) => {
      console.log('new video arrived***', data);

      const producers = Array.from(this.producers).map(
        ([key, value]) => key.producer_id
      );
      const consummers = Array.from(this.consumers).map(
        ([key, value]) => value._producerId
      );
      for (const { producer_id, type, user } of data) {
        if (
          !consummers.includes(producer_id) &&
          !producers.includes(producer_id)
        ) {
          await this.consume(producer_id, type, user);
        }
      }
    });

    this.socket.on('peopleUpdate', (data) => {
      if (data.type === 'speaker') {
        this.$currentSpeaker.next(data.data.userId);
      } else if (data.type === 'peopleInRoom') {
        if (data.roomId === this.roomId)
          this.$newUserInRoom.next({ data: data.data, userId: data.userId });
      }
    });

    await this.socket.on('disconnect', () => {
      this.leaveRoom();
    });
  }

  async leaveRoom() {
    if (this.consumerTransport) {
      await this.consumerTransport.close();
    }
    if (this.producerTransport) {
      await this.producerTransport.close();
    }
    this.consumers = new Map();
    this.producers = new Map();
    this.producerLabel = new Map();
    this.selectedDevices = null;
    this.consumerTransport = null;
    this.producerTransport = null;
    this.$inRoom.next(false);
    if (this.socket) {
      await this.socket.removeListener('newProducers');
      await this.socket.removeListener('consumerClosed');
      await this.socket.removeListener('disconnect');
      await this.socket.emit('exitRoom');
    }
  }

  removeConsumer(consumerId) {
    this.$removeVideo.next(consumerId);
    this.$removeAudio.next(consumerId);
    this.consumers.delete(consumerId);
  }

  async joinRoom(
    name: string,
    roomId: string,
    socket: Socket,
    user: User,
    isPastille: boolean
  ) {
    await this.leaveRoom();
    console.log('name: ', name);
    this.roomId = roomId;
    // this.localMediaEl = document.getElementById('localMedia');
    return new Promise<void>((resolve, reject) => {
      this.socket = socket;
      if (!this.socket) return console.log('unable to get socket :/');
      this.socket.emit(
        'joinMeeting',
        {
          name,
          roomId,
        },
        async (e) => {
          console.log('callback: ', e);

          const device = await this.loadDevice(e);
          this.selectedDevices = device;
          console.log('selected device: ', this.selectedDevices);

          console.log('Initialisation of transports');
          await this.initTransports(device, user);
          console.log('Initialisation of sockets :)');
          await this.initSockets();
          if (!isPastille) this.$inRoom.next(true);
          resolve();
        }
      );
    });
  }

  async loadDevice(routerRtpCapabilities) {
    let device;
    try {
      device = new mediasoupClient.Device();
    } catch (error) {
      if (error.name === 'UnsupportedError') {
        console.error('browser not supported');
      }
      console.error(error);
    }
    await device.load({
      routerRtpCapabilities,
    });
    return device;
  }

  public consume = async (producerId, type, user) => {
    if (this.consumers.get(producerId)) {
      console.log(true);
      return;
    }
    this.getConsumeStream(producerId).then(({ consumer, stream, kind }) => {
      this.consumers.set(consumer.id, consumer);
      if (kind === 'video') {
        this.$newVideo.next({
          user: user,
          type: type,
          src: stream,
          id: consumer.id,
        });
      } else {
        this.$newAudio.next({ user: user, src: stream, id: consumer.id });
      }
      consumer.on('trackended', (e) => {
        const remove = {
          user: user,
          src: stream,
          type: type,
        };
        this.removeConsumer(consumer.id);
      });
      consumer.on('transportclose', () => {
        this.removeConsumer(consumer.id);
      });
    });
  };
  public getConsumeStream = async (producerId) => {
    console.log('\n\n\n getConsumeStream ', producerId, '\n\n\n');
    return new Promise(async (resolve, reject) => {
      const { rtpCapabilities } = this.selectedDevices;
      console.log('\n\n\n rtpCapabilities ', rtpCapabilities, '\n\n\n');
      while (!this.consumerTransport) {
        await this.delay(10);
      }
      await this.socket.emit(
        'consume',
        {
          rtpCapabilities,
          consumerTransportId: this.consumerTransport.id, // might be
          producerId,
        },
        async (callback) => {
          const { id, kind, rtpParameters } = callback;

          const codecOptions = {};
          const consumer = await this.consumerTransport.consume({
            id,
            producerId,
            kind,
            rtpParameters,
            codecOptions,
          });
          console.log('\n\n\n consumer ', consumer, '\n\n\n');
          const stream = new MediaStream();
          stream.addTrack(consumer.track);
          resolve({
            consumer,
            stream,
            kind,
          });
        }
      );
    });
  };

  async delay(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  async produce(type, deviceId = null, localMedia?, idSpace?, idUser?) {
    this.type = type;
    let mediaConstraints = {};
    let audio = false;
    let screen = false;
    switch (type) {
      case this.mediaType.audio:
        mediaConstraints = {
          audio: {
            deviceId,
          },
          video: false,
        };
        audio = true;
        break;
      case this.mediaType.video:
        mediaConstraints = {
          audio: false,
          video: {
            width: {
              ideal: 720,
            },
            height: {
              ideal: 480,
            },
            frameRate: { max: 15 },
            deviceId,
            aspectRatio: {
              ideal: 1.7777777778,
            },
          },
        };
        break;
      case this.mediaType.screen:
        mediaConstraints = false;
        screen = true;
        break;
      default:
        return;
        break;
    }
    if (
      this.selectedDevices &&
      !this.selectedDevices.canProduce('video') &&
      !audio
    ) {
      console.error('cannot produce video');
      return;
    }
    if (this.producerLabel.has(type)) {
      return;
    }
    let stream;
    try {
      if (screen) {
        // @ts-ignore
        stream = await navigator.mediaDevices.getDisplayMedia();
      } else {
        // @ts-ignore
        stream = await navigator.mediaDevices.getUserMedia(mediaConstraints);
      }
      //if animator share screen
      var canvas = document.getElementById(
        'canvasWithoutBg'
      ) as HTMLCanvasElement;
      if (canvas && !audio && !screen) {
        canvas.remove();
      }
      canvas = document.createElement('canvas');
      console.log('*** vgb *** ', this.virtualBackGround);
      const track =
        this.virtualBackGround &&
        this.virtualBackGround != '' &&
        type == this.mediaType.video
          ? (canvas as any).captureStream().getVideoTracks()[0]
          : audio
          ? stream.getAudioTracks()[0]
          : stream.getVideoTracks()[0];
      console.log(stream);
      track.onended = () => {
        canvas.remove();
        this.closeProducer(this.mediaType.screen);
      };

      const params = {
        track,
      };
      if (!audio && !screen) {
        // @ts-ignore
        params.encodings = [
          {
            rid: 'r0',
            maxBitrate: 100000,
            // scaleResolutionDownBy: 10.0,
            scalabilityMode: 'S1T3',
          },
          {
            rid: 'r1',
            maxBitrate: 300000,
            scalabilityMode: 'S1T3',
          },
          {
            rid: 'r2',
            maxBitrate: 900000,
            scalabilityMode: 'S1T3',
          },
        ];
        // @ts-ignore
        params.codecOptions = {
          videoGoogleStartBitrate: 1000,
        };

        this.socket.emit('removePeopleInRoom', {
          spaceId: idSpace,
          userId: idUser,
          roomId: this.roomId,
        });
      }
      const producer = await this.producerTransport.produce(params);

      this.producers.set(producer.id, producer);

      let elem;
      if (!audio) {
        elem = document.createElement('video');
        elem.srcObject = stream;
        elem.id = producer.id;
        elem.playsinline = false;
        elem.autoplay = true;
        elem.className = 'local-vid';
        elem.width = localMedia?.offsetWidth;
        if (screen) {
          this.$isPresenting.next(true);
          elem.setAttribute('id', 'shareScreen');
          elem.setAttribute(
            'style',
            'background: rgba(0, 0, 0, 0.8); border-radius: 6px;'
          );
        }

        console.log('elem: ', elem);

        localMedia?.appendChild(elem);
        if (this.virtualBackGround && this.virtualBackGround != '') {
          if (!screen) {
            elem.hidden = true;
            canvas.id = 'canvasWithoutBg';
            canvas.className = 'local-vid';
            canvas.width = localMedia?.offsetWidth;
            canvas['playsinline'] = false;
            canvas['autoplay'] = true;
            localMedia?.appendChild(canvas);
            this.removeBackgroundInVideo(elem, canvas, this.virtualBackGround);
          }
        }
        // }
      }

      producer.on('trackended', () => {
        this.closeProducer(type);
        canvas.remove();
      });

      producer.on('transportclose', () => {
        if (!audio) {
          elem.srcObject.getTracks().forEach((t) => {
            t.stop();
          });
          elem.parentNode.removeChild(elem);
        }
        this.producers.delete(producer.id);
        canvas.remove();
      });

      producer.on('close', () => {
        if (!audio) {
          elem.srcObject.getTracks().forEach((t) => {
            t.stop();
          });
          elem.parentNode.removeChild(elem);
        }
        this.producers.delete(producer.id);
        canvas.remove();
      });

      this.producerLabel.set(type, producer.id);
    } catch (err) {
      console.log(err);
      this.$isPresenting.next(false);
    }
  }

  closeProducer(type) {
    if (!this.producerLabel.has(type)) {
      return;
    }
    const producerId = this.producerLabel.get(type);
    this.socket.emit('producerClosed', {
      producer_id: producerId,
    });
    this.producers.get(producerId).close();
    this.producers.delete(producerId);
    this.producerLabel.delete(type);

    if (type !== this.mediaType.audio && type !== this.mediaType.screen) {
      const elem = document.getElementById(producerId);
      // @ts-ignore
      elem?.srcObject.getTracks().forEach((t) => {
        t.stop();
      });
      elem?.parentNode.removeChild(elem);
      var canvas = document.getElementById(
        'canvasWithoutBg'
      ) as HTMLCanvasElement;
      if (canvas) canvas.remove();
    }
    if (type === this.mediaType.screen) {
      this.$isPresenting.next(false);
      const shareSreenElement = document.getElementById('shareScreen');
      shareSreenElement.parentNode.removeChild(shareSreenElement);
    }
  }

  async removeBackgroundInVideo(
    videoElement: HTMLVideoElement,
    canvasElement: HTMLCanvasElement,
    virtualBackground
  ) {
    this.virtualBackGround = virtualBackground;
    const canvasCtx = canvasElement.getContext('2d');
    const canvasImage = document.createElement('canvas') as HTMLCanvasElement;
    canvasImage.width = canvasElement.width;
    canvasImage.height = canvasElement.height;
    const canvasImageCtx = canvasImage.getContext('2d');
    var imageToResize = new Image();
    var background = new Image();
    imageToResize.src = String(virtualBackground);
    imageToResize.crossOrigin = '*';
    imageToResize.onload = () => {
      canvasImageCtx.save();
      canvasImageCtx.clearRect(0, 0, canvasImage.width, canvasImage.height);
      background.crossOrigin = '*';
      canvasImageCtx.drawImage(
        imageToResize,
        0,
        0,
        canvasImage.width,
        canvasImage.height
      );
      canvasImageCtx.restore();
      background.src = canvasImage.toDataURL();
      background.onload = () => {
        var pt;
        pt = canvasCtx.createPattern(background, 'no-repeat');
        function onResults(results) {
          canvasCtx.save();
          canvasCtx.clearRect(0, 0, canvasElement.width, canvasElement.height);
          canvasCtx.drawImage(
            results.segmentationMask,
            0,
            0,
            canvasElement.width,
            canvasElement.height
          );
          canvasCtx.globalCompositeOperation = 'source-in';
          canvasCtx.drawImage(
            results.image,
            0,
            0,
            canvasElement.width,
            canvasElement.height
          );
          canvasCtx.globalCompositeOperation = 'destination-atop';
          canvasCtx.fillStyle = pt;
          canvasCtx.fillRect(0, 0, canvasElement.width, canvasElement.height);
          canvasCtx.restore();
        }
        const selfieSegmentation = new SelfieSegmentation({
          locateFile: (file) => {
            return `https://cdn.jsdelivr.net/npm/@mediapipe/selfie_segmentation/${file}`;
          },
        });
        selfieSegmentation.setOptions({
          modelSelection: 1,
        });
        selfieSegmentation.onResults(onResults);
        var camera = new Camera(videoElement, {
          onFrame: async () => {
            await selfieSegmentation.send({ image: videoElement });
          },
          facingMode: 'user',
        });
        camera.start();
        console.log(camera);
      };
    };
  }

  public onSpeaking(data) {
    this.socket.emit('speaking', data);
  }

  async startRecord(arrayOfStreams) {
    this.showTimer().then(async (canStart) => {
      if (canStart) {
        var options = {
          type: 'video',
          mimeType: 'video/mp4',
          video: {
            width: 1920,
            height: 1080,
          },
          recorderType: MultiStreamRecorder,
          canvas: {
            width: 1920,
            height: 1080,
          },
          timeSlice: 10,
          frameInterval: 90,
          frameRate: 30,
          bitrate: 128000,
          bitsPerSecond: 128000,
          aspectRatio: {
            ideal: 1.7777777778,
          },
          controls: true,
        };
        this.recorder = new MultiStreamRecorder(arrayOfStreams, options);
        this.recorder.record();
        this.$isRecording.next(true);
      }
    });
  }

  restVideoRecorder(arrayOfStreams) {
    this.recorder.resetVideoStreams(arrayOfStreams);
  }

  stopRecord(space: Space) {
    if (this.recorder) {
      if (this.recordIsPaused) {
        this.recorder.resume();
      }
      this.recordIsStopped = true;
      this.recordIsPaused = false;
      this.recorder.stop((blob) => {
        const fullVideoURL = URL.createObjectURL(blob);
        saveAs(fullVideoURL, 'recording' + Date.now() + '.mp4');
        this.$isRecording.next(false);
      });
    }
  }

  pauseRecord() {
    if (this.recorder) {
      this.recorder.pause();
      this.recordIsPaused = true;
      this.recordIsStopped = false;
    }
  }

  resumeRecord() {
    if (this.recorder) {
      this.recorder.resume();
      this.recordIsStopped = false;
      this.recordIsPaused = false;
    }
  }

  showTimer(): Promise<boolean> {
    let time: number = 5;
    return new Promise((resolve) => {
      //countdown 5 seconds
      this.$showCountdown.next(true);
      var downloadTimer = setInterval(() => {
        if (time <= 1) {
          clearInterval(downloadTimer);
          time = 6;
          this.$showCountdown.next(false);
          resolve(true);
        }
        time -= 1;
        this.$timeleft.next(time);
      }, 1000);
    });
  }
}
