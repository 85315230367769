import { Injectable } from '@angular/core';
import { Socket } from 'ngx-socket-io';
import { Subject } from 'rxjs';
import { SpaceElement } from 'src/app/models/Event.model';
import { User } from 'src/app/models/User.model';

@Injectable({
  providedIn: 'root',
})
export class CanvaService {
  $userJoined = new Subject<any>();
  $getAnotherUserConnected = new Subject<any>();
  $getAnotherUserConnectedInEvent = new Subject<any>();
  $socketListen = new Subject<any>();
  $userLeft = new Subject<any>();
  $userMoved = new Subject<any>();
  $startHubinar = new Subject<string>();
  $startRecord = new Subject<string>();
  $stopHubinar = new Subject<string>();
  $stopRecord = new Subject<string>();
  $leaveModerator = new Subject<string>();
  $addPeole = new Subject<any>();
  $removePeople = new Subject<any>();
  $joinHubinar = new Subject<any>();
  $changeRoleHubinar = new Subject<any>();
  $startMeeting = new Subject<boolean>();
  $statusMicrophone = new Subject<any>();
  $hubinarStatus = new Subject<any>();
  $leavingSeat = new Subject<any>();
  $seating = new Subject<any>();
  $isAnimation = new Subject<boolean>();
  public isMobile: boolean = false;
  public setCurrentMeeting$ = new Subject<string>();
  public enterHubdoorZone$: Subject<{ id: string; in: boolean }> = new Subject<{
    id: string;
    in: boolean;
  }>();

  public $enterPolygonZone: Subject<{ id: string; in: boolean }> = new Subject<{
    id: string;
    in: boolean;
  }>();

  public $increaseClicks: Subject<any> = new Subject();
  public $unlockDoor: Subject<boolean> = new Subject();
  public $openedDoor: Subject<any> = new Subject();
  public $deconnectUser: Subject<any> = new Subject();
  public $updating: Subject<any> = new Subject();
  public $multiSession: Subject<any> = new Subject();

  constructor(public socket: Socket) {
    this.initSocket();
  }

  private async initSocket() {
    await this.socket.connect();
    this.listenIoPeopleUpdate();
    this.listenIoHubinar();
  }

  userJoinSpace(params: any) {
    this.socket.emit('joinSpace', params, (callback) => {
      this.$getAnotherUserConnected.next(callback.usersInSpace);
      if (callback.usersInEvent) {
        this.$getAnotherUserConnectedInEvent.next(callback.usersInEvent);
      }
    });
  }

  listenIoPeopleUpdate() {
    this.socket.on('peopleUpdate', (content) => {
      switch (content.type) {
        case 'join':
          this.$userJoined.next(content);
          break;
        case 'move':
          this.$userMoved.next({ user: content.user, position: content.args });
          break;
        case 'leave':
          this.$userLeft.next(content.user);
          break;
        case 'peopleAdded':
          this.$addPeole.next(content.data);
          break;
        case 'peopleRemoved':
          this.$removePeople.next(content.data);
          break;
        case 'roleChanged':
          this.$changeRoleHubinar.next({
            data: content.data,
            hubinarId: content.hubinarId,
            userId: content.userId,
            isCamera: content.isCamera,
            isMicrophone: content.isMicrophone,
            isSharescreen: content.isSharescreen,
          });
          break;
        case 'microStatus':
          console.log('test *** ', content);
          this.$statusMicrophone.next({
            user: content.user,
            status: content.status,
          });
          break;
        case 'openedDoor':
          this.$openedDoor.next(content.data);
          break;
        case 'seating':
          this.$seating.next({
            idSeat: content.idSeat,
            status: true,
          });
          break;
        case 'leaveSeat':
          this.$leavingSeat.next({
            idSeat: content.idSeat,
            status: false,
          });
          break;
        case 'deconnect':
          this.$deconnectUser.next({
            userId: content.userId,
            eventId: content.eventId,
          });
          break;
        case 'updating':
          this.$updating.next();
          break;
        case 'logout':
          this.$multiSession.next(content);
          break;
      }
    });
  }

  listenIoHubinar() {
    this.socket.on('updateHubinar_f', async (response) => {
      console.log('response: ', response);

      switch (response.type) {
        case 'hubinarStarted':
          this.$startHubinar.next(response.id);
          this.$hubinarStatus.next({
            id: response.id,
            status: 'inProgress',
          });
          break;
        case 'hubinarStopped':
          this.$stopHubinar.next(response.id);
          this.$hubinarStatus.next({
            id: response.id,
            status: 'finished',
          });

          break;
        case 'moderatorLeaved':
          this.$leaveModerator.next(response.id);
          break;
        case 'startedRecord':
          this.$startRecord.next(response.id);
          break;
        case 'stoppedRecord':
          this.$stopRecord.next(response.id);
          break;
        case 'updateRole':
          break;
        case 'presentation':
          break;
        case 'endPresentation':
          break;
        default:
          break;
      }
    });
  }
  moveAvatar(idEvent, idSpace, args) {
    this.socket.emit('moveAvatar', {
      eventId: idEvent,
      spaceId: idSpace,
      args: args,
    });
  }

  addPeopleInRoom(idSpace, idUser, picture, userName, roomId) {
    this.socket.emit('addPeopleInRoom', {
      spaceId: idSpace,
      userId: idUser,
      profilePicture: picture,
      userName: userName,
      roomId: roomId,
    });
  }

  removePeopleInRoom(idSpace, idUser, roomId) {
    this.socket.emit('removePeopleInRoom', {
      spaceId: idSpace,
      userId: idUser,
      roomId: roomId,
    });
  }

  onSeating(idEvent, idSpace, idSeat, values) {
    this.socket.emit('onSeating', {
      idEvent: idEvent,
      idSpace: idSpace,
      idSeat: idSeat,
      values: values,
    });
  }

  leaveSeat(idSeat, idSpace, idZone) {
    this.socket.emit('leaveSeat', {
      values: [idZone, false],
      spaceId: idSpace,
      idSeat: idSeat,
    });
  }

  showAvatar(params: any) {
    this.socket.emit('showAvatar', params);
  }

  leaveSpace(idEvent, idSpace) {
    this.socket.emit('leaveSpace', {
      eventId: idEvent,
      spaceId: idSpace,
    });
    // this.$userMoved.unsubscribe();
    // this.$getAnotherUserConnected.unsubscribe();
    // this.$userJoined.unsubscribe();
  }

  startHubinar(hubinar) {
    this.socket.emit('hubinarStart', { hubinar: hubinar }, (cb) => {
      console.log('callback: ', cb);
      this.$startHubinar.next(cb.id);
      this.$hubinarStatus.next({
        id: cb.id,
        status: 'inProgress',
      });
    });
  }

  stopHubinar(hubinar) {
    this.socket.emit('hubinarStop', { hubinar: hubinar }, (cb) => {
      this.$stopHubinar.next(cb.id);
      this.$hubinarStatus.next({
        id: cb.id,
        status: 'finished',
      });
    });
  }

  addPeopleHubinar(idSpace, _id, user) {
    this.socket.emit(
      'addPeopleHubinar',
      {
        spaceId: idSpace,
        user: user,
        _id: _id,
      },
      (res) => {
        console.log('callcccc: ', res);

        this.$joinHubinar.next(res);
      }
    );
  }

  removePeopleHubinar(idSpace, user, _id) {
    console.log('remove');

    this.socket.emit('removePeopleHubinar', {
      spaceId: idSpace,
      user: user,
      _id: _id,
    });
  }

  changeRoleHubinar(userId, type, hubinarId, spaceId) {
    this.socket.emit(
      'changeRoleHubinar',
      {
        userId: userId,
        type: type,
        hubinarId: hubinarId,
        spaceId: spaceId,
      },
      (cb) => {
        this.$changeRoleHubinar.next({
          data: cb.data,
          hubinarId: cb.hubinarId,
          userId: cb.userId,
        });
      }
    );
  }

  leaveModerator(hubinar) {
    this.socket.emit('moderatorLeave', { hubinar: hubinar }, (cb) => {
      this.$leaveModerator.next(cb.id);
    });
  }

  disconnectSocket() {
    this.socket.disconnect();
  }

  updateMeeting(key, meeting, type, spaceId, eventId) {
    this.socket.emit('editSpace', {
      spaceId,
      key,
      value: this.formatSpace('ZONE', meeting),
      type,
      eventId,
    });
    this.socket.emit('saveEditSpace', {
      spaceId,
      key,
      value: this.formatSpace('ZONE', meeting),
      type,
      eventId,
    });
  }

  formatSpace(type, rect) {
    let element;
    switch (type) {
      case 'ZONE':
        element = new SpaceElement();
        element.type = 'ZONE';
        element._id = rect.attrs._id;
        if (rect.attrs.values) element.values = rect.attrs.values;
        element.intro = rect.attrs.intro;
        element.feedback = rect.attrs.feedback;
        element.mosaic = rect.attrs.mosaic;
        element.saveMeet = rect.attrs.saveMeet;
        element.moderatorMeet = rect.attrs.moderatorMeet;
        element.contentFeedback = rect.attrs.contentFeedback;
        element.linkMeet = rect.attrs.linkMeet;
        element.codeMeet = rect.attrs.codeMeet;
        element.args = [rect.x(), rect.y(), rect.scaleX(), rect.scaleY()];
        break;
      case 'SEAT':
        element = new SpaceElement();
        element.type = 'SEAT';
        element._id = rect.attrs._id;
        element.feedback = rect.attrs.feedback;
        element.saveMeet = rect.attrs.saveMeet;
        element.moderatorMeet = rect.attrs.moderatorMeet;
        element.contentFeedback = rect.attrs.contentFeedback;
        element.values = [
          rect.attrs.meetingZone,
          rect.attrs.occupied,
          rect.attrs.values[2],
        ];
        element.args = [rect.x(), rect.y(), rect.scaleX(), rect.scaleY()];
        break;
      default:
        break;
    }
    return element;
  }

  changestatusMicro(spaceId, status: Boolean) {
    this.socket.emit('changeStatusMicro', {
      spaceId,
      status,
    });
  }

  startRecord(idSpace, _id) {
    this.socket.emit('startRecord', {
      spaceId: idSpace,
      _id: _id,
    });
  }

  stopRecord(idSpace, _id) {
    this.socket.emit('stopRecord', {
      spaceId: idSpace,
      _id: _id,
    });
  }

  update(event, key, value: any, type) {
    this.socket.emit('editEvent', {
      eventId: event._id,
      key,
      value,
      type,
    });
    this.socket.emit('saveEditEvent', {
      eventId: event._id,
      key,
      value,
      type,
    });
  }
}
