import {Component, OnInit, HostListener, AfterViewInit} from '@angular/core';
import { OfferService } from 'src/app/services/offer.service';
import { StripeService, StripeCardComponent } from 'ngx-stripe';
import { PaymentMethod, PaymentMethodResult, StripeCardElementOptions, StripeElementsOptions } from '@stripe/stripe-js';
import { switchMap } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from 'src/app/services/user.service';
import { User } from 'src/app/models/User.model';
import { Offer } from 'src/app/models/Offer.model';
import { SubscriptionService } from 'src/app/services/subscription.service';
import { ToastrService } from 'ngx-toastr';
import { Address } from 'src/app/models/Address.model';
import { Subscription } from 'src/app/models/Subscription.model';

@Component({
  selector: 'app-subscription',
  templateUrl: './subscription.component.html',
  styleUrls: ['./subscription.component.scss']
})
export class SubscriptionComponent implements OnInit, AfterViewInit {
  card: any;
  name: string;
  user: User = new User();
  address: Address = new Address();
  offer: Offer = new Offer();
  loading: Boolean = false;
  paymentMethod: PaymentMethod;
  confirmation = false;
  vat = 0;
  today: Date = new Date();

  constructor(
    private offerService: OfferService, 
    private stripeService: StripeService,
    private route: ActivatedRoute,
    private userService: UserService,
    private subscriptionService: SubscriptionService,
    private router: Router,
    private toastr: ToastrService) {}
    @HostListener("document:mousemove", ["$event"])
    onMouseMove(e) {
        var squares1 = document.getElementById("square1");
        var squares2 = document.getElementById("square2");
        var squares3 = document.getElementById("square3");
        var squares4 = document.getElementById("square4");
        var squares5 = document.getElementById("square5");
        var squares6 = document.getElementById("square6");
        var squares7 = document.getElementById("square7");
        var squares8 = document.getElementById("square8");

        var posX = e.clientX - window.innerWidth / 2;
        var posY = e.clientY - window.innerWidth / 6;

        squares1.style.transform =
            "perspective(500px) rotateY(" +
            posX * 0.05 +
            "deg) rotateX(" +
            posY * -0.05 +
            "deg)";
        squares2.style.transform =
            "perspective(500px) rotateY(" +
            posX * 0.05 +
            "deg) rotateX(" +
            posY * -0.05 +
            "deg)";
        squares3.style.transform =
            "perspective(500px) rotateY(" +
            posX * 0.05 +
            "deg) rotateX(" +
            posY * -0.05 +
            "deg)";
        squares4.style.transform =
            "perspective(500px) rotateY(" +
            posX * 0.05 +
            "deg) rotateX(" +
            posY * -0.05 +
            "deg)";
        squares5.style.transform =
            "perspective(500px) rotateY(" +
            posX * 0.05 +
            "deg) rotateX(" +
            posY * -0.05 +
            "deg)";
        squares6.style.transform =
            "perspective(500px) rotateY(" +
            posX * 0.05 +
            "deg) rotateX(" +
            posY * -0.05 +
            "deg)";
        squares7.style.transform =
            "perspective(500px) rotateY(" +
            posX * 0.02 +
            "deg) rotateX(" +
            posY * -0.02 +
            "deg)";
        squares8.style.transform =
            "perspective(500px) rotateY(" +
            posX * 0.02 +
            "deg) rotateX(" +
            posY * -0.02 +
            "deg)";
    }

  ngOnInit() {
    this.userService.current().subscribe((user: User) => {
      if(user) {
        this.user = user;
        this.name = user.firstName + ' ' + user.lastName;
      } else {
          this.router.navigate(['register']);
      }
    }, () => {
        this.router.navigate(['register']);
    });
    const id = this.route.snapshot.params.id;
    this.onGetOffer(id);
    var body = document.getElementsByTagName("body")[0];
    body.classList.add("register-page");
    this.onMouseMove(event);
  }

  ngAfterViewInit() {
      this.onCreateCard();
  }

  onCreateCard() {
    let element =this.stripeService.elements();
    element.subscribe((res) => {
      this.card = res.create('card', {style:  {
                            base: {
                              color: "white",
                              "::placeholder": {
                                color: "white"
                              }
                            },
                            invalid: {
                              color: "#fa755a",
                              iconColor: "#fa755a"
                            }
                          }});
      this.card.mount('#card-element');
    })
  }

  subscription(): void {
    this.loading = true;
    this.stripeService.createPaymentMethod({
      type: 'card',
      card: this.card,
      billing_details: {
        email: this.user.email,
        name: this.name,
        address: this.address
      }
    }).subscribe((res: PaymentMethodResult) => {
      console.log(res);
      if(res.error) {
        this.toastr.warning(res.error.message);
      } else {
        this.paymentMethod = res.paymentMethod;
        this.address.postalCode = res.paymentMethod.billing_details.address.postal_code; 
        this.user.address = this.address;
        this.confirmation = true;
        if(String(this.address.country).toUpperCase() != 'FR' && this.user.vatIntraCommNumber) {
          this.vat = 0;
        } else {
          this.vat = 0.2;
        }
      }
      this.loading = false;
    });
  }

  onConfirm() {
    this.loading = true;
    let params = {
      paymentMethodId: this.paymentMethod.id,
      email: this.user.email,
      name: this.name,
      userId: this.user._id,
      priceId: this.offer.idPrice,
      offerId: this.offer._id,
      address: this.user.address,
      vatIntraCommNumber: this.user.vatIntraCommNumber
    }
    this.subscriptionService.subscription(params).subscribe((res) => {
      if(res) {
        this.on3dSecure(res.subscription);
      }
    });
  }

  on3dSecure(subscription) {
    const { latest_invoice } = subscription;
    const { payment_intent } = latest_invoice;
    var sbs = new Subscription();
    sbs.startDate = new Date(subscription.current_period_start * 1000);
    sbs.endDate = new Date(subscription.current_period_end * 1000);
    sbs.idSubscription = subscription.id;
    sbs.offer = this.offer;
    var params = {
      subscription: sbs,
      vatIntraCommNumber: this.user.vatIntraCommNumber,
      customerId: subscription.customer
    };
    if (payment_intent) {
      const { client_secret, status } = payment_intent;
      if (status === "requires_action" || status === "requires_payment_method") {
        this.stripeService.confirmCardPayment(client_secret).subscribe((result) => {
          console.log('result ', result);
          if(result.error) {
            this.loading = false;
            this.toastr.error(result.error.message);
            console.log('error ', result.error);
          } else {
            if(result.paymentIntent.status == 'succeeded') {
              this.onCreateSubscription(params);
            }
          }
        })
      } else if(status == 'succeeded'){
        this.onCreateSubscription(params);
      }
    } else {
      console.log('error ', payment_intent)
    }
  }

  onCreateSubscription(params) {
    this.subscriptionService.createSubscritpion(params).subscribe((res) => {
      if(res.success) {
        this.router.navigate(['/member/profil']);
      }
    });
  }

  onCancel() {
    window.location.reload();
  }

  onGetOffer(id) {
    this.offerService.getOneOffer(id).subscribe((offer: Offer) => {
      if(offer) {
        this.offer = offer;
      }
    });
  }
}
