import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { User } from '../models/User.model';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  public user: User;
  API_URL = environment.apiUrl;
  public currentClick$: BehaviorSubject<any> = new BehaviorSubject<any>({});
  public currentStatus$: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);

  public modifyUser$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );
  constructor(private http: HttpClient, private router: Router) {}

  public current(): Observable<User> {
    return this.http.get<User>(this.API_URL + 'users/current');
  }

  public getUserById(idUser): Observable<User> {
    if (!idUser) {
      return;
    }
    return this.http.get<User>(this.API_URL + 'users/get/' + idUser);
  }

  public changePicture(path: String): Observable<any> {
    return this.http.patch<any>(this.API_URL + 'users/edit/profile-picture', {
      profilePicture: path,
    });
  }

  public searchUser(query: String): Observable<User[]> {
    return this.http.get<User[]>(this.API_URL + 'users/search-user/' + query);
  }

  public deleteUser(id: String): Observable<any> {
    return this.http.delete<any>(this.API_URL + 'users/' + id);
  }

  public updateUser(user: User): Observable<any> {
    return this.http.patch<any>(this.API_URL + 'users/' + user._id, user);
  }

  public getPaymentMethod(): Observable<any> {
    return this.http.get<any>(this.API_URL + 'users/get-paymentMethod');
  }

  public getConnected(eventId: string): Observable<any> {
    return this.http.get<any>(this.API_URL + 'users/userConnected/' + eventId);
  }

  public getUserInMeets(spaceId: string): Observable<any> {
    return this.http.get<any>(this.API_URL + 'users/user-inMeets/' + spaceId);
  }

  public getSpeaker(): Observable<any> {
    return this.http.get<any>(this.API_URL + 'users/user-speaker');
  }

  public changePaymentMethod(data: any): Observable<any> {
    return this.http.post<any>(
      this.API_URL + 'users/change-paymentMethod',
      data
    );
  }

  public changeUser(user: User, x: number, y: number) {
    const data = {
      user: user,
      x: x,
      y: y,
    };
    this.currentClick$.next(data);
  }

  public changeCurrentStatus(val: boolean) {
    this.currentStatus$.next(val);
  }

  public modifyUser(mo: boolean) {
    this.modifyUser$.next(mo);
  }

  public changeVBg(id, vbg): Observable<any> {
    return this.http.post<any>(this.API_URL + 'users/change-vbg/' + id, {
      virtualBackground: vbg,
    });
  }
}
