import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { EventService } from 'src/app/services/event.service';
import { OfferService } from 'src/app/services/offer.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-index',
  templateUrl: 'index.component.html',
  styleUrls: ['index.component.scss'],
})
export class IndexComponent implements OnInit {
  registredEvents = [] as Array<Event>;

  constructor(private offerService: OfferService, private router: Router, private userService: UserService, private eventService: EventService, private loadingBar: LoadingBarService) {}

  ngOnInit(): void {
    this.eventService.getAllPublicEvent().subscribe((events: any) => {
      if (events) {
        this.loadingBar.complete();
        this.registredEvents = events;
        this.registredEvents.forEach((e: any) => {
          e.startDate = new Date(e.startDate);
          e.endDate = new Date(e.endDate);
        });
        console.log('registred ==> ', this.registredEvents);
      }
    });
    const slider = document.querySelector('.hs') as any;
    let isDown = false;
    let startX;
    let scrollLeft;
    var redirect = localStorage.getItem('redirect');
    if (redirect) {
      localStorage.removeItem('redirect');
    }
    slider.addEventListener('mousedown', (e: any) => {
      isDown = true;
      slider.classList.add('active');
      startX = e.pageX - slider.offsetLeft;
      scrollLeft = slider.scrollLeft;
    });
    slider.addEventListener('mouseleave', () => {
      isDown = false;
      slider.classList.remove('active');
    });
    slider.addEventListener('mouseup', () => {
      isDown = false;
      slider.classList.remove('active');
    });
    slider.addEventListener('mousemove', (e: any) => {
      if (!isDown) return;
      e.preventDefault();
      const x = e.pageX - slider.offsetLeft;
      const walk = x - startX; //scroll-fast
      slider.scrollLeft = scrollLeft - walk;
    });
    // this.userService.current().subscribe((user: User) => {
    //     this.currentUser = user;
    // })
    // this.onGetPlan();
  }

  // onGetPlan() {
  //     this.offerService.getOffers().subscribe((offer: Offer[]) => {
  //         if(offer) {
  //             this.lstOffer = offer;
  //         }
  //     });
  // }

  // onCreateSubscription(offerId) {
  //     if(this.currentUser.idCustomerStripe) {
  //         this.router.navigate(['member/select-pricing']);
  //     } else {
  //         this.router.navigate(['subscription/' + offerId]);
  //     }
  // }
}
