import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Camera } from '@mediapipe/camera_utils';
import { SelfieSegmentation } from '@mediapipe/selfie_segmentation';
import * as mediasoupClient from 'mediasoup-client';
import { Socket } from 'ngx-socket-io';
import { BehaviorSubject } from 'rxjs';
import { environment } from '../../environments/environment';
import { User } from '../models/User.model';
import { EventService } from './event.service';
import { HubinarService } from './hubinar.service';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root',
})
export class MediasoupService {
  mediaType = {
    audio: 'audioType',
    video: 'videoType',
    screen: 'screenType',
  };

  apiUrl = environment.apiUrl;

  connected = false;
  type: string = '';
  hubinar: boolean = false;
  localMediaEl;
  remoteVideos = [];
  audioStreams = [];
  remoteCamera = [];
  remoteAudioEl;
  mediasoupClient;
  currentRect: any;
  currentUser: any;
  currentCamera: any;
  currentMail: any;
  virtualBackGround: String;
  roomId;
  public clickCamActive$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public toMosaic$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  consumers;
  producers;
  device;
  producerTransport;
  consumerTransport;

  producerLabel;

  _isOpen = false;

  socket: Socket;

  changes: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  newAudio$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  toggleCam$: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  toggleMic$: BehaviorSubject<string> = new BehaviorSubject<string>(null);

  deviceStatus = {
    microphone: true,
    video: true,
    screen: false,
  };

  isPresenting: boolean = false;
  isRecordingHubinar: boolean = false;
  constructor(private http: HttpClient, private hubinarService: HubinarService, private eventService: EventService, private userService: UserService) {
    this.mediasoupClient = mediasoupClient;

    this.producerTransport = null;
    this.consumerTransport = null;
    this.device = null;
    this.roomId = null;

    this.consumers = new Map();
    this.producers = new Map();

    this.producerLabel = new Map();
    this.eventService.currentRect$.subscribe((rect) => {
      if (rect) {
        this.currentRect = rect;
      }
    });

    this.clickCamActive$.subscribe((active) => {
      this.currentCamera = active;
    });
    console.log('user media ato');
    this.userService.current().subscribe(
      (res: User) => {
        this.currentUser = res;
      },
      () => {
        if (localStorage.getItem('guest')) {
          this.currentUser = JSON.parse(localStorage.getItem('guest'));
        }
      }
    );
  }

  async delay(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  async join(name, roomId, socket) {
    await this.exit();
    this.hubinar = false;
    // this.localMediaEl = document.getElementById('localMedia');
    this.remoteAudioEl = document.getElementById('remoteAudios');
    return new Promise<void>((resolve, reject) => {
      this.socket = socket;
      if (!socket) return console.log('unable to get socket :/');
      this.socket.emit(
        'joinMeeting',
        {
          name,
          roomId,
        },
        async (e) => {
          const device = await this.loadDevice(e);
          this.device = device;
          console.log('Initialisation of transports');
          await this.initTransports(device);
          console.log('Initialisation of sockets :)');
          await this.initSockets();
          this.connected = true;
          resolve();
        }
      );
    });
  }

  async joinHubinar(name, roomId, socket) {
    if (this.connected) {
      await this.exit();
    }
    this.hubinar = true;
    // this.localMediaEl = document.getElementById('localMedia');
    this.remoteAudioEl = document.getElementById('remoteAudios');
    return new Promise<void>((resolve, reject) => {
      this.socket = socket;
      if (!socket) return console.log('unable to get socket :/');
      this.socket.emit(
        'joinHubinar',
        {
          name,
          roomId,
        },
        async (e) => {
          const device = await this.loadDevice(e);
          this.device = device;
          console.log('Initialisation of transports');
          await this.initTransports(device);
          console.log('Initialisation of sockets :)');
          await this.initSockets();
          this.connected = true;
          resolve();
        }
      );
    });
  }

  async loadDevice(routerRtpCapabilities) {
    let device;
    try {
      device = new mediasoupClient.Device();
    } catch (error) {
      if (error.name === 'UnsupportedError') {
        console.error('browser not supported');
      }
      console.error(error);
    }
    await device.load({
      routerRtpCapabilities,
    });
    return device;
  }

  async initTransports(device) {
    console.log('device ', device);
    console.log('***device infos***', device.rtpCapabilities);

    // init producerTransport
    await this.socket.emit(
      'createWebRtcTransport',
      {
        forceTcp: false,
        rtpCapabilities: device.rtpCapabilities,
      },
      async (data) => {
        if (data.error) {
          console.error(data.error);
          return;
        }
        console.log('callback createWebRtcTransport ', data);
        this.producerTransport = device.createSendTransport(data);

        this.producerTransport.on('connect', async ({ dtlsParameters }, callback, errback) => {
          this.socket.emit(
            'connectTransport',
            {
              dtlsParameters,
              transport_id: data.id,
            },
            (response) => {
              callback(response);
            }
          );
        });

        this.producerTransport.on('produce', async ({ kind, rtpParameters }, callback) => {
          console.log('we produce now a: ', kind, 'with parameters', rtpParameters);
          console.log('**producerTransportId**', this.producerTransport);

          try {
            console.log('procude emit ========> ', this.currentUser);
            this.socket.emit(
              'produce',
              {
                producerTransportId: this.producerTransport.id,
                kind,
                rtpParameters,
                type: this.type,
                hubinar: this.hubinar,
                rect: this.currentRect,
                userId: this.currentUser._id,
                email: this.currentUser.email,
                camera: this.currentCamera,
                userName: this.currentUser.firstName + ' ' + this.currentUser.lastName,
              },
              (producerId) => {
                callback({
                  id: producerId,
                });
              }
            );
          } catch (err) {
            console.log(err);
          }
        });

        this.producerTransport.on(
          'connectionstatechange',
          function (state) {
            switch (state) {
              case 'connecting':
                break;

              case 'connected':
                // localVideo.srcObject = stream
                break;

              case 'failed':
                this.producerTransport.close();
                break;

              default:
                break;
            }
          }.bind(this)
        );
      }
    );

    // init consumerTransport
    await this.socket.emit(
      'createWebRtcTransport',
      {
        forceTcp: false,
      },
      async (data) => {
        if (data.error) {
          console.error(data.error);
          return;
        }
        console.log('createWebRtcTransport');

        // only one needed
        this.consumerTransport = device.createRecvTransport(data);

        this.consumerTransport.on('connect', ({ dtlsParameters }, callback, errback) => {
          this.socket.emit(
            'connectTransport',
            {
              transport_id: this.consumerTransport.id,
              dtlsParameters,
            },
            (resp) => {
              callback();
            }
          );
        });
        this.consumerTransport.on(
          'connectionstatechange',
          async function (state) {
            switch (state) {
              case 'connecting':
                break;

              case 'connected':
                break;

              case 'failed':
                this.consumerTransport.close();
                break;

              default:
                break;
            }
          }.bind(this)
        );

        this.socket.emit('getProducers');
      }
    );
  }

  async initSockets() {
    await this.socket.on('consumerClosed', ({ consumer_id }) => {
      console.log('closing consumer:', consumer_id);
      this.removeConsumer(consumer_id); // here source of error
    });

    /**
     * data: [ {
     *  producer_id:
     *  producer_socket_id:
     * }]
     */
    await this.socket.on('newProducers', async (data) => {
      console.log('new video arrived***', data);

      const producers = Array.from(this.producers).map(([key, value]) => key.producer_id);
      const consummers = Array.from(this.consumers).map(([key, value]) => value._producerId);
      for (const { producer_id, type, hubinar, rect, userId, email, camera, userName } of data) {
        if (!consummers.includes(producer_id) && !producers.includes(producer_id)) {
          await this.consume(producer_id, type, hubinar, rect, userId, email, camera, userName);
        }
      }
    });

    await this.socket.on('disconnect', () => {
      this.exit();
    });
  }

  //////// MAIN FUNCTIONS /////////////

  async produce(type, deviceId = null) {
    this.type = type;
    let mediaConstraints = {};
    let audio = false;
    let screen = false;
    switch (type) {
      case this.mediaType.audio:
        mediaConstraints = {
          audio: {
            deviceId,
          },
          video: false,
        };
        audio = true;
        break;
      case this.mediaType.video:
        mediaConstraints = {
          audio: false,
          video: {
            width: {
              ideal: 720,
            },
            height: {
              ideal: 480,
            },
            frameRate: { max: 15 },
            deviceId,
            aspectRatio: {
              ideal: 1.7777777778,
            },
          },
        };
        break;
      case this.mediaType.screen:
        mediaConstraints = false;
        screen = true;
        break;
      default:
        return;
        break;
    }
    if (!this.device.canProduce('video') && !audio) {
      console.error('cannot produce video');
      return;
    }
    if (this.producerLabel.has(type)) {
      return;
    }
    let stream;
    try {
      // @ts-ignore
      stream = screen ? await navigator.mediaDevices.getDisplayMedia() : await navigator.mediaDevices.getUserMedia(mediaConstraints);
      //if animator share screen
      this.hubinarService.newStream$.next({ stream: stream, isPresentation: true, email: this.currentMail });
      var canvas = document.getElementById('canvasWithoutBg') as HTMLCanvasElement;
      if (canvas) canvas.remove();
      canvas = document.createElement('canvas');
      console.log('*** vgb *** ', this.virtualBackGround);
      const track =
        this.virtualBackGround && this.virtualBackGround != '' && type == this.mediaType.video
          ? (canvas as any).captureStream().getVideoTracks()[0]
          : audio
          ? stream.getAudioTracks()[0]
          : stream.getVideoTracks()[0];
      console.log(stream);
      track.onended = () => {
        canvas.remove();
        this.closeProducer(this.mediaType.screen);
      };

      const params = {
        track,
      };
      if (!audio && !screen) {
        // @ts-ignore
        params.encodings = [
          {
            rid: 'r0',
            maxBitrate: 100000,
            // scaleResolutionDownBy: 10.0,
            scalabilityMode: 'S1T3',
          },
          {
            rid: 'r1',
            maxBitrate: 300000,
            scalabilityMode: 'S1T3',
          },
          {
            rid: 'r2',
            maxBitrate: 900000,
            scalabilityMode: 'S1T3',
          },
        ];
        // @ts-ignore
        params.codecOptions = {
          videoGoogleStartBitrate: 1000,
        };
      }
      const producer = await this.producerTransport.produce(params);

      this.producers.set(producer.id, producer);

      let elem;
      if (!audio) {
        this.localMediaEl = undefined;
        this.localMediaEl = document.getElementById('localMedia');
        elem = document.createElement('video');
        elem.srcObject = stream;
        elem.id = producer.id;
        elem.playsinline = false;
        elem.autoplay = true;
        elem.className = 'local-vid';
        elem.width = this.localMediaEl.offsetWidth;
        // elem.height = this.localMediaEl.offsetHeight;
        // if (!this.localMediaEl.childNodes[1]) {
        if (screen) {
          console.log('***presentation detected***');
          this.isPresenting = true;
          elem.setAttribute('id', 'shareScreen');
          elem.setAttribute('style', 'background: rgba(0, 0, 0, 0.8); border-radius: 6px;');
        }
        this.localMediaEl.appendChild(elem);
        if (this.virtualBackGround && this.virtualBackGround != '') {
          if (!screen) {
            console.log('virtualbg ******** ', this.currentUser);
            elem.hidden = true;
            canvas.id = 'canvasWithoutBg';
            canvas.className = 'local-vid';
            canvas.width = this.localMediaEl.offsetWidth;
            canvas['playsinline'] = false;
            canvas['autoplay'] = true;
            this.localMediaEl.appendChild(canvas);
            this.removeBg(elem, canvas, this.virtualBackGround);
          }
        }
        // }
      }

      producer.on('trackended', () => {
        this.closeProducer(type);
        canvas.remove();
      });

      producer.on('transportclose', () => {
        if (!audio) {
          elem.srcObject.getTracks().forEach((t) => {
            t.stop();
          });
          elem.parentNode.removeChild(elem);
        }
        this.producers.delete(producer.id);
        canvas.remove();
      });

      producer.on('close', () => {
        if (!audio) {
          elem.srcObject.getTracks().forEach((t) => {
            t.stop();
          });
          elem.parentNode.removeChild(elem);
        }
        this.producers.delete(producer.id);
        canvas.remove();
      });

      this.producerLabel.set(type, producer.id);
    } catch (err) {
      console.log(err);
    }
  }

  async consume(producerId, type, hubinar, rect, userId, email, camera, userName) {
    if (this.consumers.get(producerId)) {
      console.log(true);
      return;
    }
    const $bakThis = this;
    this.getConsumeStream(producerId).then(
      function ({ consumer, stream, kind }) {
        this.consumers.set(consumer.id, consumer);

        let elem;
        console.log(kind);
        if (kind === 'video') {
          //remove duplicates
          // const idx = this.remoteVideos.findIndex((v) => v.id === consumer.id);
          const idxUser = this.remoteVideos.findIndex((v) => v.userId === userId && v.type === type);
          // if (idx !== -1) {
          //   this.remoteVideos.splice(idx, 1);
          // }

          if (idxUser !== -1) {
            this.remoteVideos[idxUser] = { src: stream, id: consumer.id, type: type, hubinar: hubinar, rect: rect, userId: userId, email: email, camera: camera, userName: userName }
          } else {
          this.remoteVideos.push({ src: stream, id: consumer.id, type: type, hubinar: hubinar, rect: rect, userId: userId, email: email, camera: camera, userName: userName });
          }
          //we should catch all remotes videos here
          if (type === 'screenType') {
            this.hubinarService.newStream$.next({ stream: stream, isPresentation: true, email: email });
            this.isRecordingHubinar = true;
          } else this.hubinarService.newStream$.next({ stream: stream, isPresentation: false, email: email });
          // this.hubinarService.newStream$.next({ stream: stream, isPresentation: false });
        } else {
          elem = document.createElement('audio');
          elem.srcObject = stream;
          elem.id = consumer.id;
          elem.playsinline = false;
          elem.autoplay = true;
          this.remoteAudioEl.appendChild(elem); // error here
          $bakThis.audioStreams.push({ stream: stream, isPresentation: false, isAudio: true });
          // $bakThis.newAudio$.next({ stream: stream, isPresentation: false, isAudio: true });
        }

        this.changes.next(true);

        consumer.on(
          'trackended',
          function () {
            this.removeConsumer(consumer.id);
          }.bind(this)
        );
        consumer.on(
          'transportclose',
          function () {
            this.removeConsumer(consumer.id);
          }.bind(this)
        );
      }.bind(this)
    );
  }
  async getConsumeStream(producerId) {
    console.log('\n\n\n getConsumeStream ', producerId, '\n\n\n');
    return new Promise(async (resolve, reject) => {
      const { rtpCapabilities } = this.device;
      console.log('\n\n\n rtpCapabilities ', rtpCapabilities, '\n\n\n');
      while (!this.consumerTransport) {
        await this.delay(10);
      }
      await this.socket.emit(
        'consume',
        {
          rtpCapabilities,
          consumerTransportId: this.consumerTransport.id, // might be
          producerId,
        },
        async (callback) => {
          const { id, kind, rtpParameters } = callback;

          const codecOptions = {};
          const consumer = await this.consumerTransport.consume({
            id,
            producerId,
            kind,
            rtpParameters,
            codecOptions,
          });
          console.log('\n\n\n consumer ', consumer, '\n\n\n');
          const stream = new MediaStream();
          stream.addTrack(consumer.track);
          resolve({
            consumer,
            stream,
            kind,
          });
        }
      );
    });
  }

  closeProducer(type) {
    if (!this.producerLabel.has(type)) {
      console.log('there is no producer for this type ' + type);
      return;
    }
    const producerId = this.producerLabel.get(type);
    this.socket.emit('producerClosed', {
      producer_id: producerId,
    });
    this.producers.get(producerId).close();
    this.producers.delete(producerId);
    this.producerLabel.delete(type);

    if (type !== this.mediaType.audio && type !== this.mediaType.screen) {
      const elem = document.getElementById(producerId);
      // @ts-ignore
      elem.srcObject.getTracks().forEach((t) => {
        t.stop();
      });
      elem.parentNode.removeChild(elem);
    }
    if (type === this.mediaType.screen) {
      console.log('***end presentation***');
      this.isPresenting = false;
      const shareSreenElement = document.getElementById('shareScreen');
      shareSreenElement.parentNode.removeChild(shareSreenElement);
      this.hubinarService.closePresentation$.next(true);
    }
  }

  pauseProducer(type) {
    if (!this.producerLabel.has(type)) {
      console.log('there is no producer for this type ' + type);
      return;
    }
    const producerId = this.producerLabel.get(type);
    this.producers.get(producerId).pause();
  }

  resumeProducer(type) {
    if (!this.producerLabel.has(type)) {
      console.log('there is no producer for this type ' + type);
      return;
    }
    const producerId = this.producerLabel.get(type);
    this.producers.get(producerId).resume();
  }

  removeConsumer(consumerId) {
    if (this.isRecordingHubinar) {
      this.isRecordingHubinar = false;
      this.hubinarService.closePresentation$.next(true);
    }
    console.log('\n\n\n\nconsumerId', consumerId);
    // If video :
    const index = this.remoteVideos.findIndex((e) => e.id === consumerId);
    console.log(index);
    if (index > -1) {
      this.hubinarService.leaveStream$.next(this.remoteVideos[index]);
      this.remoteVideos.splice(index, 1);
    }

    // if audio
    const elem = document.getElementById(consumerId);
    if (elem) {
      // @ts-ignore
      elem.srcObject.getTracks().forEach((t) => {
        //here error
        t.stop();
      });
      elem.parentNode.removeChild(elem);
    }

    this.changes.next(true);

    this.consumers.delete(consumerId);
  }

  async exit() {
    if (this.consumerTransport) {
      await this.consumerTransport.close();
    }
    if (this.producerTransport) {
      await this.producerTransport.close();
    }
    this.remoteVideos = [];
    this.consumers = new Map();
    this.producers = new Map();
    this.producerLabel = new Map();
    this.device = null;
    this.roomId = null;
    this.consumerTransport = null;
    this.producerTransport = null;
    this.connected = false;
    this.localMediaEl = undefined;
    if (this.socket) {
      await this.socket.removeListener('newProducers');
      await this.socket.removeListener('consumerClosed');
      await this.socket.removeListener('disconnect');
      await this.socket.emit('exitRoom');
    }
  }

  getRoomInfo() {
    this.socket.emit('getMyRoomInfo', {}, (response) => {
      console.log(response);
    });
  }

  async removeBg(videoElement: HTMLVideoElement, canvasElement: HTMLCanvasElement, virtualBackground) {
    console.log('***************remove bg*************');
    // const videoElement = document.getElementById('configVideo') as HTMLVideoElement;
    // const canvasElement = document.getElementById('withoutBg') as HTMLCanvasElement;
    this.virtualBackGround = virtualBackground;
    const canvasCtx = canvasElement.getContext('2d');
    const canvasImage = document.createElement('canvas') as HTMLCanvasElement;
    canvasImage.width = canvasElement.width;
    canvasImage.height = canvasElement.height;
    const canvasImageCtx = canvasImage.getContext('2d');
    var imageToResize = new Image();
    var background = new Image();
    imageToResize.src = String(virtualBackground);
    imageToResize.crossOrigin = '*';
    imageToResize.onload = () => {
      canvasImageCtx.save();
      canvasImageCtx.clearRect(0, 0, canvasImage.width, canvasImage.height);
      background.crossOrigin = '*';
      canvasImageCtx.drawImage(imageToResize, 0, 0, canvasImage.width, canvasImage.height);
      canvasImageCtx.restore();
      background.src = canvasImage.toDataURL();
      background.onload = () => {
        var pt;
        pt = canvasCtx.createPattern(background, 'no-repeat');
        function onResults(results) {
          canvasCtx.save();
          canvasCtx.clearRect(0, 0, canvasElement.width, canvasElement.height);
          canvasCtx.drawImage(results.segmentationMask, 0, 0, canvasElement.width, canvasElement.height);
          canvasCtx.globalCompositeOperation = 'source-in';
          canvasCtx.drawImage(results.image, 0, 0, canvasElement.width, canvasElement.height);
          canvasCtx.globalCompositeOperation = 'destination-atop';
          canvasCtx.fillStyle = pt;
          canvasCtx.fillRect(0, 0, canvasElement.width, canvasElement.height);
          canvasCtx.restore();
        }
        const selfieSegmentation = new SelfieSegmentation({
          locateFile: (file) => {
            return `https://cdn.jsdelivr.net/npm/@mediapipe/selfie_segmentation/${file}`;
          },
        });
        selfieSegmentation.setOptions({
          modelSelection: 1,
        });
        selfieSegmentation.onResults(onResults);
        // this.sendSelfieSeg(selfieSegmentation, videoElement);
        var camera = new Camera(videoElement, {
          onFrame: async () => {
            await selfieSegmentation.send({ image: videoElement });
            console.log('mande fona');
          },
          facingMode: 'user',
        });
        camera.start();
        console.log(camera);
      };
    };
    // if (this.stopBoucle.length > 0 && this.stopBoucle[videoElement.id]) {
    //   this.stopBoucle[videoElement.id] = false;
    // }

    // pour le test
    // setTimeout(() => {
    //   let videotest = document.createElement('video') as HTMLVideoElement;
    //   let body = document.getElementsByClassName('modal-body')[0];
    //   videotest.srcObject = (canvasElement as any).captureStream();
    //   videotest.autoplay = true;
    //   body.appendChild(videotest)

    // }, 1000);
  }

  // sendSelfieSeg(selfieSegmentation: SelfieSegmentation, videoElement: HTMLVideoElement) {
  //   selfieSegmentation.send({ image: videoElement }).then(() => {
  //     if (this.stopBoucle.length > 0 && !this.stopBoucle[videoElement.id]) {
  //       setTimeout(() => {
  //         console.log('mande fona');
  //         this.sendSelfieSeg(selfieSegmentation, videoElement);
  //       }, 100);
  //     }
  //   });
  // }

  webglSupport() {
    try {
      var canvas = document.createElement('canvas');
      return !!window.WebGLRenderingContext && (canvas.getContext('webgl') || canvas.getContext('experimental-webgl'));
    } catch (e) {
      return false;
    }
  }

  async testingMicrophone(tmpMicroPhone, selectedMicrophone?, $this?, bool?) {
    console.log('stream ', tmpMicroPhone);
    var micro = await navigator.mediaDevices.getUserMedia({ audio: { deviceId: selectedMicrophone } });
    // var micro = await navigator.mediaDevices.getUserMedia({ audio: true });
    var audioContext = new AudioContext();
    var analyser = audioContext.createAnalyser();
    var microphone = audioContext.createMediaStreamSource(micro);
    var javascriptNode = audioContext.createScriptProcessor(2048, 1, 1);

    analyser.smoothingTimeConstant = 0.8;
    analyser.fftSize = 1024;

    if (tmpMicroPhone.micro && tmpMicroPhone.analyser && tmpMicroPhone.javascriptNode) {
      tmpMicroPhone.micro.disconnect();
      tmpMicroPhone.analyser.disconnect();
      tmpMicroPhone.javascriptNode.disconnect();
    }
    tmpMicroPhone.micro = microphone;
    tmpMicroPhone.analyser = analyser;
    tmpMicroPhone.javascriptNode = javascriptNode;
    microphone.connect(analyser);
    analyser.connect(javascriptNode);
    javascriptNode.connect(audioContext.destination);
    javascriptNode.onaudioprocess = () => {
      var array = new Uint8Array(analyser.frequencyBinCount);
      analyser.getByteFrequencyData(array);
      var values = 0;

      var length = array.length;
      for (var i = 0; i < length; i++) {
        values += array[i];
      }

      var average = values / length;
      if ($this) {
        if (bool && $this.stopTalking) {
          $this.socket.emit('speaking', {
            eventId: $this.event._id,
            userId: $this.user._id,
            spaceId: $this.currentSpace._id,
            volume: Math.round(average),
          });
        }
      }
      this.colorPids(Math.round(average));
    };
  }

  colorPids(vol) {
    // document.querySelector
    let all_pids = document.querySelectorAll('.pid');
    let amout_of_pids = Math.round(vol / 10);
    all_pids.forEach((elem, i) => {
      if (i < amout_of_pids) {
        (elem as HTMLElement).style.backgroundColor = 'greenyellow';
      } else {
        (elem as HTMLElement).style.backgroundColor = 'black';
      }
    });
  }

  statusCamera(value: boolean) {
    this.clickCamActive$.next(value);
  }

  changingStatusMosaic(value: boolean) {
    this.toMosaic$.next(value);
  }
}
