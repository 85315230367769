import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';
import { NgxLinkifyOptions } from 'ngx-linkifyjs';
import { ToastrService } from 'ngx-toastr';
import { Event, Space } from 'src/app/models/Event.model';
import { Message } from 'src/app/models/Message.model';
import { User } from 'src/app/models/User.model';
import { ChatService } from 'src/app/services/event-core/chat.service';
import { UserService } from 'src/app/services/event-core/user.service';

const options: NgxLinkifyOptions = {
  attributes: null,
  className: 'linkified',
  defaultProtocol: 'https',
  events: null,
  format: function (value, type) {
    return value;
  },
  formatHref: function (href, type) {
    return href;
  },
  ignoreTags: [],
  nl2br: false,
  tagName: 'a',
  target: {
    url: '_blank',
  },
  validate: true,
};
@Component({
  selector: 'app-chat-manager',
  templateUrl: './chat-manager.component.html',
  styleUrls: ['./chat-manager.component.scss'],
})
export class ChatManagerComponent implements OnInit {
  // @Input() space: Space;
  @ViewChild('inputMessage') inputMessage: ElementRef;
  @Input() event: Event;
  @Input() user: User;
  @Input() isGuest;
  @Input('peopleInEvent') set _setPeople(value: any[]) {
    this.peopleInEvent = value;
    this.filteredPeople = value;
    // this.numberMessageUnreadTotal();
    // this.numberMessageUnreadPerUser();
  }
  @Input('space') set _setSspace(value: Space) {
    this.space = value;
    this.conversations = [];
    this.currentMessages = [];
    this.currentReceiver = undefined;
    this.ngOnInit();
  }
  public space: Space;
  public peopleInEvent: any[];
  public filteredPeople: any[] = [];
  public conversations: Message[] = [];
  public currentMessages: Message[] = [];
  public filteredMessages: Message[] = [];
  public isPrivate: boolean = false;
  public currentReceiver: User | string = undefined;
  public numberMessage: number = 0;
  public numberUnreadMessage: number = 0;
  public nbMessageUnreadPerUser: any[] = [];
  public autoMention: boolean = false;
  public isList: boolean = false;
  public keyword: string = '';
  public filteredUsers: any[] = [];
  public messageContent: string = '';
  public searchName: string = '';
  public isEmojiPickerVisible = false;
  public isAudio = false;
  public currentHover = '';
  constructor(
    private chatService: ChatService,
    private toastr: ToastrService,
    private translate: TranslateService,
    private userService: UserService
  ) {
    this.initSubscribers();
  }
  async ngOnInit() {
    await this.chatService
      .getAllConversations(this.event._id, this.space._id, this.user._id)
      .toPromise()
      .then((message) => {
        this.conversations = message;

        // this.numberMessageUnreadTotal();
        // setTimeout(() => {
        //   this.numberMessageUnreadPerUser();
        // }, 1000);
      })
      .catch((e) => {
        console.log('error: ', e);
      });
    this.chatService.numberUnreadMessage$.subscribe((data) => {
      switch (data.type) {
        case 'init':
          this.numberUnreadMessage = data.nb;
          break;
        case 'add':
          this.numberUnreadMessage += data.nb;
          break;
        case 'remove':
          this.numberUnreadMessage -= data.nb;
          break;
        default:
          break;
      }
    });
  }

  public async onSendMessage(receiverId?: string, content?: string) {
    if (this.isGuest) {
      this.toastr.error(this.translate.instant('NO_CHAT'));
    } else {
      if (this.messageContent) {
        if (!content) {
          content = this.messageContent;
        }
        let userReceiver;
        try {
          userReceiver = await this.userService
            .getUserById(receiverId)
            .toPromise();
        } catch (error) {}
        if (!userReceiver && this.isPrivate) {
          userReceiver = this.currentReceiver;
          // this.toastr.error(this.translate.instant('GUEST_CHAT'));
          // return;
        }
        const data = {
          createdAt: new Date(),
          updatedAt: new Date(),
          content: content,
          from: this.user,
          to: userReceiver ? userReceiver : null,
          spaceId: this.space._id,
          isNewMessage: true,
          isPrivate: this.isPrivate,
          eventId: this.event._id,
        };
        if (
          this.currentReceiver == receiverId ||
          (this.currentReceiver as User)?._id == receiverId
        ) {
          this.currentMessages.push(data);
        }
        this.formatMessages();
        this.conversations.push(data);
        this.chatService.sendMessage(data);
        this.messageContent = '';
        setTimeout(() => {
          this.scrollToBottom();
        }, 500);
      }
    }
  }

  public initSubscribers() {
    this.chatService.newMessage$.subscribe((newMessage: Message) => {
      //message for me
      if (newMessage.to && newMessage.to._id === this.user._id) {
        this.isAudio = true;
        if (
          this.currentReceiver &&
          (this.currentReceiver as User)._id === newMessage.from._id
        ) {
          this.currentMessages.push(newMessage);
        }
        setTimeout(() => {
          this.isAudio = false;
        }, 1000);
      } else if (!newMessage.to && newMessage.spaceId === this.space._id) {
        //message for space
        if (
          this.currentReceiver &&
          (this.currentReceiver as string) === newMessage.spaceId
        ) {
          this.currentMessages.push(newMessage);
        }
      }
      if (newMessage.spaceId === this.space._id) {
        this.conversations.push(newMessage);
      }

      this.formatMessages();
      this.numberMessageUnreadTotal();
      this.numberMessageUnreadPerUser();
      setTimeout(() => {
        this.scrollToBottom();
      }, 500);
    });
  }

  private numberMessageUnreadTotal() {
    this.numberMessage = 0;
    this.conversations.map((message) => {
      if (message.to && message.isPrivate) {
        //private message
        if (
          message.isNewMessage &&
          message.from?._id !== this.user._id &&
          message.to._id === this.user._id &&
          this.peopleInEvent.findIndex((p) => p._id === message.from?._id) > -1
        ) {
          this.numberMessage += 1;
        }
      } else {
        //space message
        if (
          message.from &&
          message.from._id !== this.user._id &&
          !message.seenBy?.includes(this.user._id)
        ) {
          this.numberMessage += 1;
        }
      }
    });
    this.chatService.numberUnreadMessage$.next({
      type: 'init',
      nb: this.numberMessage,
    });
    this.numberUnreadMessage = this.numberMessage;
  }

  private numberMessageUnreadPerUser() {
    //space
    this.nbMessageUnreadPerUser[this.space?._id] = 0;
    this.conversations.map((message) => {
      if (
        !message.to &&
        message.from &&
        message.from._id !== this.user._id &&
        !message.seenBy?.includes(this.user._id)
      ) {
        this.nbMessageUnreadPerUser[this.space._id] += 1;
        // this.currentMessages = [];
      }
    });
    //users
    for (const usr of this.peopleInEvent) {
      this.nbMessageUnreadPerUser[usr._id] = 0;
      if (usr._id !== this.user._id) {
        this.conversations.map((message) => {
          if (
            message.to &&
            message.from &&
            message.from._id === usr._id &&
            message.isNewMessage
          ) {
            this.nbMessageUnreadPerUser[usr._id] += 1;
          }
        });
      }
    }
  }

  public removeAuto(_e) {
    this.autoMention = false;
  }

  public checkMention(event) {
    this.keyword += event.data;
    const val = this.keyword.split('@');
    this.filteredUsers = this.peopleInEvent.filter((str) => {
      return (
        str.lastName.includes(val[val.length - 1]) ||
        str.firstName.includes(val[val.length - 1])
      );
    });
    if (this.filteredUsers.length != 0) {
      this.autoMention = true;
    }
  }

  public setCurrentReceiver(user?: User) {
    this.currentMessages = [];
    this.filteredMessages = [];
    if (user) {
      this.currentReceiver = user;
      for (const message of this.conversations) {
        if (
          (message.from?._id === this.currentReceiver._id &&
            message.to?._id === this.user._id) ||
          (message.from?._id === this.user._id &&
            message.to?._id === this.currentReceiver._id &&
            message.isPrivate)
        ) {
          this.currentMessages.push(message);
        }
      }
      console.log('current message: ', this.currentMessages);

      if (this.currentMessages.length > 0) {
        this.currentMessages = _.sortBy(this.currentMessages, (message) => {
          return new Date(message.createdAt);
        });
      }
      this.chatService
        .markAsRead(
          this.space._id,
          this.event._id,
          user._id,
          this.user._id,
          true,
          this.user._id
        )
        .toPromise();
      this.chatService.numberUnreadMessage$.next({
        type: 'remove',
        nb: this.nbMessageUnreadPerUser[user._id],
      });
      this.nbMessageUnreadPerUser[user._id] = 0;
      this.markAsRead(user._id, true);
    } else {
      this.currentReceiver = this.space._id;
      for (const message of this.conversations) {
        if (!message.to && !message.isPrivate) {
          this.currentMessages.push(message);
        }
      }
      if (this.currentMessages.length > 0) {
        this.currentMessages = _.sortBy(this.currentMessages, (message) => {
          return new Date(message.createdAt);
        });
      }
      this.chatService
        .markAsRead(
          this.space._id,
          this.event._id,
          null,
          null,
          false,
          this.user._id
        )
        .toPromise();
      this.numberMessage =
        this.numberMessage - this.nbMessageUnreadPerUser[this.space._id];
      this.chatService.numberUnreadMessage$.next({
        type: 'remove',
        nb: this.nbMessageUnreadPerUser[this.space._id],
      });
      this.nbMessageUnreadPerUser[this.space._id] = 0;
      this.markAsRead(null, false);
    }
    this.formatMessages();

    setTimeout(() => {
      this.scrollToBottom();
      this.inputMessage.nativeElement.focus();
    }, 100);
  }

  private scrollToBottom(elementId: string = 'chat_body_item') {
    const htmlElement = document.getElementById(elementId);
    if (htmlElement) {
      htmlElement.scrollTo(0, htmlElement.getBoundingClientRect().height * 100);
    }
  }

  private markAsRead(receiverId: string, isPrivate: boolean) {
    this.conversations.map((message) => {
      if (isPrivate) {
        if (
          message.from &&
          message.from._id === receiverId &&
          message.to &&
          message.to._id === this.user._id
        ) {
          message.isNewMessage = false;
        }
      } else if (!message.to) {
        message.isNewMessage = false;
        if (message.seenBy) {
          if (message.seenBy.findIndex((m) => m === this.user._id) == -1) {
            message.seenBy.push(this.user._id);
          }
        } else {
          message.seenBy = [this.user._id];
        }
      }
    });
  }

  public addEmoji(event: any) {
    this.messageContent += event.emoji.native;
    this.isEmojiPickerVisible = false;
  }

  public onSearchSomeone(event) {
    if (event.target.value != '') {
      this.filteredPeople = this.peopleInEvent.filter((people) => {
        if (
          (people.firstName + ' ' + people.lastName)
            .trim()
            .toLowerCase()
            .includes(this.searchName.trim().toLowerCase())
        ) {
          return people;
        }
      });
    } else {
      this.filteredPeople = this.peopleInEvent;
    }
  }

  formatMessages() {
    let tmpMessages = [];
    for (const message of this.currentMessages) {
      if (message.from) {
        if (
          tmpMessages.length > 0 &&
          tmpMessages[tmpMessages.length - 1].user._id === message.from._id
        ) {
          const date1 =
            new Date(tmpMessages[tmpMessages.length - 1].date).getDate() +
            '/' +
            new Date(tmpMessages[tmpMessages.length - 1].date).getMonth() +
            '/' +
            new Date(tmpMessages[tmpMessages.length - 1].date).getFullYear();
          const date2 =
            new Date(message.updatedAt).getDate() +
            '/' +
            new Date(message.updatedAt).getMonth() +
            '/' +
            new Date(message.updatedAt).getFullYear();
          if (date1 == date2) {
            tmpMessages[tmpMessages.length - 1].data.push(message);
          } else {
            const data = {
              date: message.updatedAt,
              user: message.from,
              data: [message],
            };
            tmpMessages.push(data);
          }
        } else {
          const data = {
            date: message.updatedAt,
            user: message.from,
            data: [message],
          };
          tmpMessages.push(data);
        }
      }
      this.filteredMessages = tmpMessages;
    }
  }

  onFocus() {
    this.chatService
      .markAsRead(
        this.space._id,
        this.event._id,
        null,
        null,
        false,
        this.user._id
      )
      .toPromise();
    this.numberMessage =
      this.numberMessage - this.nbMessageUnreadPerUser[this.space._id];
    this.chatService.numberUnreadMessage$.next({
      type: 'remove',
      nb: this.nbMessageUnreadPerUser[this.space._id],
    });
    this.nbMessageUnreadPerUser[this.space._id] = 0;
    if ((this.currentReceiver as User)._id) {
      this.markAsRead(null, false);
      this.chatService
        .markAsRead(
          this.space._id,
          this.event._id,
          (this.currentReceiver as User)._id,
          this.user._id,
          true,
          this.user._id
        )
        .toPromise();

      this.markAsRead((this.currentReceiver as User)._id, true);
      this.chatService.numberUnreadMessage$.next({
        type: 'remove',
        nb: this.nbMessageUnreadPerUser[(this.currentReceiver as User)._id],
      });
      this.nbMessageUnreadPerUser[(this.currentReceiver as User)._id] = 0;
      this.markAsRead((this.currentReceiver as User)._id, true);
    }
  }

  showCardUser($event, user) {
    this.userService.currentUserClicked$.next({
      user: user,
      coords: {
        x: $event.clientX,
        y: $event.clientY,
      },
    });
    this.userService.currentStatus$.next(true);
  }
}
