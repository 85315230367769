import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Offer } from '../models/Offer.model';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { PaymentIntent } from '@stripe/stripe-js';

@Injectable({
  providedIn: 'root',
})
export class OfferService {
  API_URL = environment.apiUrl;

  constructor(private http: HttpClient) {}

  getOffers(): Observable<Offer[]> {
    return this.http.get<Offer[]>(this.API_URL + 'offers');
  }

  createSubscritpion(data: any): Observable<any> {
    return this.http.post<any>(
      this.API_URL + 'offers/create-subscription',
      data
    );
  }

  createOffer(offer: Offer): Observable<any> {
    return this.http.post<any>(this.API_URL + 'offers', offer);
  }

  getOneOffer(id: String): Observable<Offer> {
    return this.http.get<Offer>(this.API_URL + 'offers/get-one/' + id);
  }

  getAllOffer(): Observable<Offer[]> {
    return this.http.get<Offer[]>(
      this.API_URL + 'offers/get-all-available-offer'
    );
  }

  getAllOfferForAdmin(): Observable<Offer[]> {
    return this.http.get<Offer[]>(this.API_URL + 'offers/get-all-for-admin');
  }

  updateOffer(offer: Offer): Observable<any> {
    return this.http.put<any>(this.API_URL + 'offers/' + offer._id, offer);
  }
  deleteOffer(_id): Observable<any> {
    return this.http.delete<any>(this.API_URL + 'offers/' + _id);
  }
}
