import { Injectable } from '@angular/core';
import { Socket } from 'ngx-socket-io';
import { Subject } from 'rxjs';
import { Announcement } from 'src/app/models/announcement.model';
import { Event } from 'src/app/models/Event.model';

@Injectable({
  providedIn: 'root',
})
export class AnnouncementService {
  public $updateAnnouncement: Subject<Announcement> =
    new Subject<Announcement>();
  public $deleteAnnouncement: Subject<Announcement> =
    new Subject<Announcement>();
  public $createAnnouncement: Subject<Announcement> =
    new Subject<Announcement>();
  public $updateAutoscroll: Subject<boolean> = new Subject<boolean>();
  public $playSingleAnnouncement: Subject<Announcement> =
    new Subject<Announcement>();
  public $playMultipleAnnouncement: Subject<Announcement[]> = new Subject<
    Announcement[]
  >();

  constructor(private socket: Socket) {
    this.initSocket();
  }

  private async initSocket() {
    await this.socket.connect();

    this.socket.on('announcement', (data) => {
      switch (data.type) {
        case 'singlePlayed':
          this.$playSingleAnnouncement.next(data.announcement);
          break;
        case 'multiplePlayed':
          this.$playMultipleAnnouncement.next(data.announcement);
          break;

        case 'created':
          this.$createAnnouncement.next(data.announcement);
          break;

        case 'updated':
          this.$updateAnnouncement.next(data.announcement as Announcement);
          console.log('service: ', data.announcement);

          break;

        case 'deleted':
          this.$deleteAnnouncement.next(data.announcement);
          break;

        case 'updatedScroll':
          this.$updateAutoscroll.next(data.autoscroll);
          break;

        default:
          break;
      }
    });
  }

  public creatAnnouncement(event: Event, announcement: Announcement) {
    const key = 'annonce';
    const type = 'input';
    const value = event.annonce;
    this.onChangeValue(event, key, value, type);
    this.$createAnnouncement.next(announcement);
    this.socket.emit('createAnnouncement', {
      eventId: event._id,
      announcement: announcement,
    });
  }

  public updateAnnouncement(event: Event, announcement: Announcement) {
    const key = 'annonce';
    const type = 'input';
    const value = event.annonce;
    this.onChangeValue(event, key, value, type);
    console.log('aann: ', announcement);

    this.$updateAnnouncement.next(announcement);
    this.socket.emit('updateAnnouncement', {
      eventId: event._id,
      announcement: announcement,
    });
  }

  public deleteAnnouncement(event: Event, announcement: Announcement) {
    const key = 'annonce';
    const type = 'input';
    const value = event.annonce;
    this.onChangeValue(event, key, value, type);
    this.$deleteAnnouncement.next(announcement);
    this.socket.emit('deleteAnnouncement', {
      eventId: event._id,
      announcement: announcement,
    });
  }

  public updateAutoscroll(event: Event, scroll: boolean) {
    const key = 'autoScroll';
    const type = 'input';
    const value = event.autoScroll;
    this.onChangeValue(event, key, value, type);
    this.$updateAutoscroll.next(scroll);
    this.socket.emit('updateAutoscroll', {
      eventId: event._id,
      autoscroll: scroll,
    });
  }

  public playSingleAnnouncement(event: Event, announcement: Announcement) {
    this.socket.emit('playingSingleAnnouncement', {
      eventId: event._id,
      announcement: announcement,
    });
    this.$playSingleAnnouncement.next(announcement);
    const key = 'annonce';
    const type = 'input';
    const value = event.annonce;
    this.onChangeValue(event, key, value, type);
  }

  public playMultipleAnnouncement(event: Event, announcement: Announcement[]) {
    this.socket.emit('playingMutipleAnnouncement', {
      eventId: event._id,
      announcement: announcement,
    });
    this.$playMultipleAnnouncement.next(announcement);
    announcement.forEach((element) => {
      element.passageNumber = element.passageNumber + 1;
      const idx = event.annonce.findIndex(
        (announcement: Announcement) => announcement._id === element._id
      );
      event.annonce[idx] = element;
      const key = 'annonce';
      const type = 'input';
      const value = event.annonce;
      this.onChangeValue(event, key, value, type);
    });
  }

  updateBandSize(event: Event) {
    const key = 'tailleBande';
    const type = 'input';
    const value = event.tailleBande;
    this.onChangeValue(event, key, value, type);
  }

  private onChangeValue(event: Event, key: string, value: any, type: string) {
    this.socket.emit('editEvent', {
      eventId: event._id,
      key,
      value,
      type,
    });
    this.socket.emit('saveEditEvent', {
      eventId: event._id,
      key,
      value,
      type,
    });
  }
}
