import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LibraryManagerService {

  API_URL = environment.apiUrl;

  constructor(private http: HttpClient, private router: Router) { }

  public uploadFiles(files: any): Observable<any> {
    const formData: FormData = new FormData();
    for (const file of files) {
      formData.append('files', file, file.name);
    }
    return this.http.post<any>(`${this.API_URL}uploads/`, formData);
  }

  public uploadFilesWithId(files: any, id: string): Observable<any> {
    const formData: FormData = new FormData();
    for (const file of files) {
      formData.append('files', file, file.name);
    }
    return this.http.post<any>(`${this.API_URL}uploads/${id}`, formData);
  }

  public getMyLib() {
    return this.http.get<any>(this.API_URL + 'library/mylibraries');
  }

  public getLibById(id: string) {
    return this.http.get<any>(`${this.API_URL}library/by-id/${id}`);
  }

  public addNewLib(name: string) {
    const param = {
      title: name
    }
    return this.http.post<any>(`${this.API_URL}library/`, param);
  }

  public updateLib(id: string, body: string) {
    const param = {
      title: body
    };

    return this.http.patch<any>(`${this.API_URL}library/${id}`, param);
  }

  public deleteLib(id: string) {

    return this.http.delete<any>(`${this.API_URL}library/${id}`);
  }

  public deleteFile(body): Observable<any> {
    return this.http.post<any>(`${this.API_URL}library/delete-file`, body);
  }
 
}
