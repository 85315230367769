import { ImgComponent } from './library-manager/filetype/img/img.component';
import { PdfComponent } from './library-manager/filetype/pdf/pdf.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UploadsComponent } from './library-manager/uploads/uploads.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LibraryManagerComponent } from './library-manager/library-manager.component';
import { TranslateModule } from '@ngx-translate/core';
import { ProgressComponent } from './library-manager/progress/progress.component';
import { DndDirective } from './dnd.directive';
import { ModalModule, ProgressbarModule } from 'ngx-bootstrap';
import { MessagesComponent } from './messages/messages.component';
import { PickerModule } from "@ctrl/ngx-emoji-mart";
import { LoginFormComponent } from './login-form/login-form.component';
import { VideoComponent } from './library-manager/filetype/video/video.component';
import { OtherComponent } from './library-manager/filetype/other/other.component';
import { AnnonceComponent } from './annonce/annonce.component';
import { SongComponent } from './library-manager/filetype/song/song.component';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { NgxLinkifyjsModule } from 'ngx-linkifyjs';
@NgModule({
  declarations: [
    LibraryManagerComponent,
    ProgressComponent,
    DndDirective,
    UploadsComponent,
    PdfComponent,
    ImgComponent,
    MessagesComponent,
    LoginFormComponent,
    VideoComponent,
    OtherComponent,
    AnnonceComponent,
    SongComponent
  ],
  imports: [
    CommonModule,
    TranslateModule,
    FormsModule,
    ReactiveFormsModule,
    PickerModule,
    ModalModule.forRoot(),
    ProgressbarModule.forRoot(),
    AngularEditorModule,
    NgxLinkifyjsModule.forRoot()
  ],
  exports: [
    LibraryManagerComponent,
    MessagesComponent,
    LoginFormComponent,
    AnnonceComponent
  ]
})
export class SharedComponentsModule { }
