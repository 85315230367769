import { Component, Input } from '@angular/core';
import { File } from 'src/app/interfaces/library-manager';

@Component({
    selector: 'app-pdf',
    templateUrl: './pdf.component.html',
    styleUrls: ['./pdf.component.scss']
})
export class PdfComponent {
    @Input() file: File | null;

    formatName(title: string) {
        return title.length > 15 && typeof title !== 'undefined' ? title?.substr(0, 15) + '...' : title;
    }
}