import { LibraryManagerService } from './../../../services/library-manager.service';
import { File, LibraryManager } from './../../../interfaces/library-manager';
import { Component, OnInit, Output, TemplateRef, ViewChild, EventEmitter } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-library-manager',
  templateUrl: './library-manager.component.html',
  styleUrls: ['./library-manager.component.scss']
})
export class LibraryManagerComponent implements OnInit {

  @ViewChild('showLibraryModalManager') showModal: TemplateRef<any>;
  @ViewChild('addNewLibrary') showAddNewLibraryModal: TemplateRef<any>;

  @Output() eventEmiter = new EventEmitter();
  @Output() imagePath = new EventEmitter();
  @Output() imageSelect = new EventEmitter();

  modalRef: BsModalRef;
  addModalRef: BsModalRef;

  libraries: LibraryManager[] = [];

  activeLib!: LibraryManager | null;
  activeListFiles!: File[] | null;
  activeTab = 0;
  loadData = false;

  activeView = 0;

  form!: FormGroup;
  formRename!: FormGroup;

  hasData = false;

  constructor(
    private fb: FormBuilder,
    private toastr: ToastrService,
    private libraryManagerService: LibraryManagerService,
    private modalService: BsModalService) { }

  ngOnInit(): void {

    this.form = this.fb.group({
      name: ['', Validators.required]
    });

    this.formRename = this.fb.group({
      nom:['', Validators.required]
    });

    this.libraryManagerService.getMyLib()
      // tslint:disable-next-line: deprecation
      .subscribe((data) => {
        console.log(data);
        for(let d of data) {
          for(let ar of d.files) {
            ar.type = String(ar.type).toLowerCase();
          }
        }
        this.libraries = data;
        this.loadData = true;
        this.activeLib = this.libraries[0];
        this.activeListFiles = this.activeLib.files;
        console.log(this.activeLib);
        this.hasData = true;
        // this.modalRef = this.modalService.show(
        //   this.showModal,
        //   Object.assign({}, { class: 'modal-lg' })
        // );
      }, err => {
        console.log(err);
      });
  }

  get formControls() {
    return this.form.controls;
  }

  openModalWithClass(template: TemplateRef<any>, className: string) {
    this.modalRef = this.modalService.show(
      template,
      Object.assign({}, { class: className })
    );
  }

  openModalWithClassAdd(template: TemplateRef<any>, className: string) {
    this.addModalRef = this.modalService.show(
      template,
      Object.assign({}, { class: className })
    );
  }

  activeLibrary(index: number) {
    this.activeTab = index;

    this.activeLib = this.libraries[index];
    this.activeListFiles = this.activeLib.files;
  }

  activeViews(index: number) {
    this.activeView = index;
  }

  addNewLib() {

    if (this.form.invalid) {
      this.toastr.warning('Veuillez ajouter le nom de la librairie!');
      return;
    }

    this.libraryManagerService.addNewLib(this.form.value.name)
      // tslint:disable-next-line: deprecation
      .subscribe((data: any) => {
        this.libraries.push(data);
        this.addModalRef.hide();
      }, err => {
        console.log(err);
      })
  }

  deleteLib() {
    if (this.activeLib._id === this.libraries[0]._id) {
      return;
    }

    this.libraryManagerService.deleteLib(this.activeLib._id)
      // tslint:disable-next-line: deprecation
      .subscribe((data: any) => {
        console.log(data);

        this.libraries = this.libraries.filter(obj => {
          return obj._id !== this.activeLib._id;
        });
      }, err => {
        console.log(err);
      });
  }

  sortLibByType() {
    this.activeLib.files.sort((a,b) => (a.title > b.title) ? 1 : ((b.title > a.title) ? -1 : 0));
  }

  randString() {
    return Math.random().toString(36).substring(7);
  }

  closeModalManager() {
    const modal = {
      status: 'close'
    };
    this.eventEmiter.emit(modal);
  }

  pathImage(event: any) {
    console.log(event);
    if (event.type !== '.pdf') {
      this.imagePath.emit(event.url);
    }
  }

  chooseImage(event: any) {
    console.log('choose image')
    // if (event.type !== '.pdf') {
      this.imageSelect.emit(event.url);
      const modal = {
        status: 'close'
      };
      this.eventEmiter.emit(modal);
    // }
  }

  rename() {
    if (this.form.invalid) {
      this.toastr.warning('Veuillez ajouter le nom de la librairie!');
      return;
    }
    this.addModalRef.hide();
    console.log(this.form.value.name);
    this.activeLib.title = this.form.value.name;
    this.form.controls['name'].setValue('');
    this.libraryManagerService.updateLib(this.activeLib._id, this.activeLib.title).subscribe((res) => {
      console.log(res);
      this.toastr.success('Vous venez de renomer votre librairie');
    })
  }
}
