import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {
  FacebookLoginProvider,
  GoogleLoginProvider,
  SocialAuthService,
} from 'angularx-social-login';
import { Observable, Subject } from 'rxjs';
import { environment } from '../../environments/environment';
import { User } from '../models/User.model';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  API_URL = environment.apiUrl;
  user = new Subject<User>();

  constructor(
    private http: HttpClient,
    private router: Router,
    private userService: UserService,
    private socialAuthService: SocialAuthService
  ) {
    this.userService.current().subscribe((user) => {
      this.user.next(user);
    });
  }

  public getLanguage() {
    function convertLocale(language) {
      const equivalence = {
        fr: 'fr-FR',
        en: 'en-US',
      };
      return language
        ? equivalence[language]
          ? equivalence[language]
          : 'fr'
        : 'fr';
    }
    this.userService.current().subscribe((user) => {
      if (user) {
        return convertLocale(user.language);
      }
    });
  }

  private delay(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  public isAuthenticated(): boolean {
    const token = localStorage.getItem('token');
    return !!token;
  }

  public registerWithPassword(data): Observable<any> {
    return this.http.post(this.API_URL + 'users/', data);
  }

  async loginWithPassword(email: string, password: string): Promise<any> {
    const login = await this.http
      .post<any>(this.API_URL + 'auth/login', { email, password })
      .toPromise();
    if (login.token) {
      localStorage.setItem('token', login.token);
      delete login.token;
      await this.delay(200);
      const user = await this.userService.current().toPromise();
      if (user) {
        this.user.next(user);
        return { success: true, firstConnexion: login.firstConnexion };
      }
    }
    return login;
  }

  public logout() {
    localStorage.removeItem('token');
    this.user.next(undefined);
    if (!this.router.url.includes('landing')) {
      this.router.navigate(['']).then(() => {
        window.location.reload();
      });
    }
  }

  public getCurrentUser(): Observable<User> {
    return this.user.asObservable();
  }

  async loginWithGoogle(): Promise<any> {
    const result = await this.socialAuthService.signIn(
      GoogleLoginProvider.PROVIDER_ID
    );
    if (!!result) {
      // tslint:disable-next-line:max-line-length
      const finalResponse: any = await this.http
        .post(this.API_URL + 'auth/oauth/google', {
          access_token: result.authToken,
        })
        .toPromise();
      if (finalResponse.token) {
        localStorage.setItem('token', finalResponse.token);
        await this.delay(200);
        const user = await this.userService.current().toPromise();
        this.user.next(user);
        return true;
      }
    }
    return false;
  }

  async loginWithFacebook(): Promise<any> {
    const result = await this.socialAuthService.signIn(
      FacebookLoginProvider.PROVIDER_ID
    );
    if (!!result) {
      const profile = {
        email: result.email,
        firstName: result.firstName,
        lastName: result.lastName,
        profilePicture: result.photoUrl,
      };
      // tslint:disable-next-line:max-line-length
      const finalResponse: any = await this.http
        .post(this.API_URL + 'auth/social-login', { profile })
        .toPromise();
      if (finalResponse.token) {
        localStorage.setItem('token', finalResponse.token);
        const user = await this.userService.current().toPromise();
        this.user.next(user);
        return true;
      }
    }
    return false;
  }

  public resetPassword(email: String, link: string): Observable<any> {
    return this.http.post(this.API_URL + 'auth/request-password-reset', {
      email: email,
      link: link,
    });
  }

  public changePassword(objPassword: any): Observable<any> {
    return this.http.patch(this.API_URL + 'auth/change-password', objPassword);
  }

  public confirmation(objCredential: any): Observable<any> {
    return this.http.post<any>(
      this.API_URL + 'auth/confirmation',
      objCredential
    );
  }

  public vefiryTokenPassword(token: string): Observable<any> {
    return this.http.get(this.API_URL + 'auth/verify-token-password/' + token);
  }

  public setNewPassword(user: User): Observable<any> {
    return this.http.patch<any>(this.API_URL + 'auth/reset-password', user);
  }
}
