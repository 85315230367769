import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { ResizeEvent } from 'angular-resizable-element';
import { NgxResizeHandleType } from 'ngx-drag-resize';
import { Space } from 'src/app/models/Event.model';
import { User } from 'src/app/models/User.model';
import { MediasoupService } from 'src/app/services/event-core/mediasoup.service';
import { UserService } from 'src/app/services/event-core/user.service';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap';
import { ToastrService } from 'ngx-toastr';

enum ViewMode {
  'FREE' = 'FREE',
  'MOSAIC' = 'MOSAIC',
}
@Component({
  selector: 'app-webcam-manager',
  templateUrl: './webcam-manager.component.html',
  styleUrls: ['./webcam-manager.component.scss'],
})
export class WebcamManagerComponent implements OnInit {
  @ViewChild('virtualBackgroundConfig') virtualBackgroundConfig: ElementRef;
  @Input() space: Space;
  @Input() user: User;
  @Input() selectedWebcam;
  @Input() listImageVirtualBackground;
  public remoteVideos: {
    user: User;
    type: string;
    src: MediaStream;
    id: string;
  }[] = [];
  public localWebcam;
  public localScreen;
  public viewMode: ViewMode = ViewMode.FREE;
  public focusOnSomeone: any;
  public shareScreen: { user: User; type: string; src: MediaStream };
  public userSpeaker: string;
  public isShareScreen: boolean;
  public connected: boolean = false;
  public deviceStatus = {
    video: false,
    screen: false,
    isMicrophone: false,
  };
  public virtualBackground: String;
  public arrayOfStreams = [];
  public isRecording: boolean = false;
  public stream;
  public widthResize: number = 220;
  public heightResize: number = 150;
  public widthResizeScreen: number = 220;
  public heightResizeScreen: number = 150;
  public huge;
  public usersMute: User[] = [];
  showCountdown: boolean = false;
  timeleft: number = 5;
  readonly handleType = NgxResizeHandleType;
  modalRef: BsModalRef;
  cantClickOtherVbg: boolean = false;
  public remoteAudios: { user: User; src: MediaStream; id: string }[] = [];
  constructor(
    private mediasoupService: MediasoupService,
    private userService: UserService,
    private modalService: BsModalService,
    private toastr: ToastrService
  ) {}

  ngOnInit(): void {
    this.initSubcribers();
  }

  initSubcribers() {
    this.mediasoupService.$inRoom.subscribe(async (inRoom) => {
      if (inRoom) {
        this.connected = true;
        await this.delay(400);
        if (this.connected && this.deviceStatus.video) {
          this.addLocalWebcam();
        }
        this.userService
          .getUserInRoom(this.mediasoupService.roomId)
          .subscribe((users) => {
            if (users.data) {
              for (const user of users.data) {
                this.replaceVideoToProfil(user);
              }
            }
          });
      } else {
        this.connected = false;
        this.deviceStatus.screen = false;
        this.remoteVideos = [];
      }
    });

    this.mediasoupService.$removeVideo.subscribe(async (consumerId) => {
      const video = this.remoteVideos.find((video) => video.id === consumerId);
      if (this.huge && video.id === this.huge.id) this.huge = undefined;
      if (video && video.type === this.mediasoupService.mediaType.screen) {
        this.shareScreen = undefined;
      }
      this.removeRemote(consumerId);
      if (this.isRecording) {
        this.arrayOfStreams = [];
        for (const vid of this.remoteVideos) {
          if (!(vid as any).isPicture) this.arrayOfStreams.push(vid.src);
        }
        for (const audio of this.remoteAudios) {
          this.arrayOfStreams.push(audio.src);
        }
        let stream = await navigator.mediaDevices.getUserMedia({
          video: { width: 1920, height: 1080 },
          audio: true,
        });
        this.stream = stream;
        this.arrayOfStreams.push(this.stream);
        this.mediasoupService.restVideoRecorder(this.arrayOfStreams);
      }
    });

    this.mediasoupService.$newVideo.subscribe((video) => {
      this.addRemoteVideo(video);
      if (video.type === this.mediasoupService.mediaType.screen) {
        this.shareScreen = video;
        if (this.isRecording) {
          this.arrayOfStreams = [];
          this.arrayOfStreams.push(video.src);
          for (const audio of this.remoteAudios) {
            this.arrayOfStreams.push(audio.src);
          }
          this.mediasoupService.restVideoRecorder(this.arrayOfStreams);
        }
      } else {
        if (this.isRecording && !(video as any).isPicture) {
          this.arrayOfStreams.push(video.src);
          this.mediasoupService.restVideoRecorder(this.arrayOfStreams);
        }
      }
    });

    this.mediasoupService.$currentSpeaker.subscribe((userId: string) => {
      if (userId) this.userSpeaker = userId;
    });

    this.mediasoupService.$newUserInRoom.subscribe((data) => {
      if (data.userId) {
        const index = this.remoteVideos.findIndex(
          (video) =>
            video.user._id === data.userId &&
            video.type !== this.mediasoupService.mediaType.screen
        );

        // if (index !== -1 && (this.remoteVideos[index] as any).isPicture) {
        //   this.remoteVideos.splice(index, 1);
        // }
      }
      for (const user of data.data) {
        this.replaceVideoToProfil(user);
      }
    });

    this.mediasoupService.$isPresenting.subscribe((share) => {
      this.isShareScreen = share;
      if (!share) this.removeLocalScreen();
    });

    this.mediasoupService.$isRecording.subscribe((record) => {
      this.isRecording = record;
    });

    this.mediasoupService.$audioStatus.subscribe((status) => {
      this.deviceStatus.isMicrophone = status;
    });

    this.mediasoupService.$showCountdown.subscribe((count) => {
      this.showCountdown = count;
    });

    this.mediasoupService.$timeleft.subscribe((time) => {
      this.timeleft = time;
    });

    this.mediasoupService.$newAudio.subscribe((audio) => {
      this.remoteAudios.push(audio);
      if (this.isRecording) {
        this.arrayOfStreams.push(audio.src);
        this.mediasoupService.restVideoRecorder(this.arrayOfStreams);
      }
    });

    this.mediasoupService.$removeAudio.subscribe((id) => {
      this.remoteAudios = this.remoteAudios.filter((audio) => audio.id != id);
    });
  }

  addRemoteVideo(video: {
    user: User;
    type: string;
    src: MediaStream;
    id: string;
  }) {
    const idx = this.remoteVideos.findIndex(
      (remote) =>
        remote.user._id === video.user._id && remote.type === video.type
    );
    idx === -1
      ? this.remoteVideos.push(video)
      : (this.remoteVideos[idx] = video);
  }

  removeRemote(consumerId: string) {
    this.remoteVideos = this.remoteVideos.filter(
      (remoteVideo) => remoteVideo.id !== consumerId
    );
  }

  async addLocalWebcam(deviceId = null) {
    this.localWebcam = document.getElementById('localWebcam');
    this.deviceStatus.video = true;
    await this.mediasoupService.produce(
      this.mediasoupService.mediaType.video,
      deviceId,
      this.localWebcam,
      this.space._id,
      this.user._id
    );
  }

  removeLocalWebcam() {
    this.localWebcam = undefined;
    this.mediasoupService.closeProducer(this.mediasoupService.mediaType.video);
    this.deviceStatus.video = false;
  }

  async addLocalScreen(deviceId = null) {
    this.deviceStatus.screen = true;
    setTimeout(async () => {
      this.localScreen = document.getElementById('localScreen');

      await this.mediasoupService.produce(
        this.mediasoupService.mediaType.screen,
        deviceId,
        this.localScreen
      );
    }, 500);
  }

  removeLocalScreen() {
    this.localScreen = undefined;
    this.deviceStatus.screen = false;
    this.mediasoupService.closeProducer(this.mediasoupService.mediaType.screen);
  }

  clickFocus(video: { user: User; type: string; src: MediaStream }) {
    this.focusOnSomeone = video;
  }

  changeViewMode() {
    this.viewMode === ViewMode.FREE
      ? (this.viewMode = ViewMode.MOSAIC)
      : (this.viewMode = ViewMode.FREE);
  }

  replaceVideoToProfil(element) {
    if (element.userId != this.user?._id) {
      const idx = this.remoteVideos.findIndex(
        (video) =>
          video.user._id === element.userId &&
          video.type != this.mediasoupService.mediaType.screen
      );
      idx === -1
        ? (this.remoteVideos as any[]).push({
            ...element,
            isPicture: true,
            type: this.mediasoupService.mediaType.video,
            user: {
              _id: element.userId,
              userName: element.userName,
            },
          })
        : ((this.remoteVideos as any[])[idx] = {
            ...element,
            isPicture: true,
            type: this.mediasoupService.mediaType.video,
            user: {
              _id: element.userId,
              userName: element.userName,
            },
          });
    }
  }

  async startRecord() {
    let stream = await navigator.mediaDevices.getUserMedia({
      video: { width: 1920, height: 1080 },
      audio: true,
    });
    this.stream = stream;
    this.arrayOfStreams = [];
    if (this.remoteVideos.length > 0) {
      for (const video of this.remoteVideos) {
        if (!(video as any).isPicture) {
          this.arrayOfStreams.push(video.src);
        }
      }
    }
    if (this.remoteAudios.length > 0) {
      for (const audio of this.remoteAudios) {
        this.arrayOfStreams.push(audio.src);
      }
    }
    this.arrayOfStreams.push(stream);
    console.log('stream: ', this.arrayOfStreams);

    this.mediasoupService.startRecord(this.arrayOfStreams);
  }

  stopRecord() {
    console.log('stop record');

    this.mediasoupService.stopRecord(this.space);
    if (!this.deviceStatus.video) {
      this.stream.getTracks().forEach(function (track) {
        track.stop();
      });
    }
  }

  pauseRecord() {
    this.mediasoupService.pauseRecord();
  }

  resumeRecord() {
    this.mediasoupService.resumeRecord();
  }

  onResizeLocalVideo(event: ResizeEvent) {
    this.widthResize = event.rectangle.width;
    this.heightResize = event.rectangle.height;
  }

  onResizeLocalScreen(event: ResizeEvent) {
    this.widthResizeScreen = event.rectangle.width;
    this.heightResizeScreen = event.rectangle.height;
  }

  async delay(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  onHuge(huge) {
    this.huge = huge;
  }

  userToMute(user: User) {
    let isMute;
    if (this.usersMute.find((userMute) => userMute._id === user._id)) {
      this.usersMute = this.usersMute.filter((mute) => mute._id != user._id);
      isMute = false;
    } else {
      this.usersMute.push(user);
      isMute = true;
    }
    this.mediasoupService.$userToMute.next({ user, isMute });
  }

  // virtual background actions

  async selectVirtualBg(value: any) {
    if (!this.cantClickOtherVbg) {
      this.virtualBackground = value;
      await this.removeLocalWebcam();
      if (!this.connected) {
        var stream = await navigator.mediaDevices.getUserMedia({
          video: { deviceId: this.selectedWebcam },
        });
        const videoElement = document.getElementById(
          'configVideo'
        ) as HTMLVideoElement;
        (videoElement.srcObject as any).getTracks().forEach((t) => {
          t.stop();
        });
        const canvasElement = document.getElementById(
          'withoutBg'
        ) as HTMLCanvasElement;
        var streamCanvas = (canvasElement as any).captureStream();
        streamCanvas.getTracks().forEach((t) => {
          t.stop();
        });
        videoElement.srcObject = stream;
        this.mediasoupService.removeBackgroundInVideo(
          videoElement,
          canvasElement,
          this.virtualBackground
        );
      } else {
        console.log('virtual bg ******* ', this.virtualBackground);
        this.mediasoupService.virtualBackGround = this.virtualBackground;
        await this.addLocalWebcam(this.selectedWebcam);
      }
    }
  }

  async removeVbg() {
    this.virtualBackground = '';
    this.mediasoupService.virtualBackGround = '';
    await this.removeLocalWebcam();
    if (!this.connected) {
      const canvasElement = document.getElementById(
        'withoutBg'
      ) as HTMLCanvasElement;
      var streamCanvas = (canvasElement as any).captureStream();
      streamCanvas.getTracks().forEach((t) => {
        t.stop();
      });
      this.cantClickOtherVbg = true;
      var stream = await navigator.mediaDevices.getUserMedia({
        video: { deviceId: this.selectedWebcam },
      });
      var videoElement = document.getElementById(
        'configVideo'
      ) as HTMLVideoElement;
      videoElement['hidden'] = false;
      videoElement.srcObject = stream;
      this.cantClickOtherVbg = false;
    } else {
      await this.addLocalWebcam(this.selectedWebcam);
    }
  }

  async openVbgSettings() {
    if (navigator.userAgent.indexOf('Firefox') != -1) {
      this.toastr.error('Ce navigateur ne support pas les fonds virtuels');
      return;
    }
    this.modalRef = this.modalService.show(this.virtualBackgroundConfig, {
      ignoreBackdropClick: true,
      class: 'modal-sm with-perso',
    });
    // await this.delay(1500);
    const hasBackground: boolean = this.virtualBackground ? true : false;
    if (!this.connected) {
      var stream = await navigator.mediaDevices.getUserMedia({
        video: { deviceId: this.selectedWebcam },
      });
      if (hasBackground) {
        const videoElement = document.getElementById(
          'configVideo'
        ) as HTMLVideoElement;
        const canvasElement = document.getElementById(
          'withoutBg'
        ) as HTMLCanvasElement;
        videoElement.srcObject = stream;
        this.mediasoupService.removeBackgroundInVideo(
          videoElement,
          canvasElement,
          this.virtualBackground
        );
      } else {
        let video = document.getElementById('configVideo') as HTMLVideoElement;
        video.srcObject = stream;
      }
    }
  }

  async closeVbgSetings() {
    const canvasElement = document.getElementById(
      'withoutBg'
    ) as HTMLCanvasElement;
    if (canvasElement) {
      var streamCanvas = (canvasElement as any).captureStream();
      streamCanvas.getTracks().forEach((t) => {
        t.stop();
      });
    }
    const configVideo = document.getElementById(
      'configVideo'
    ) as HTMLVideoElement;
    if (configVideo) {
      (configVideo.srcObject as any).getTracks().forEach((t) => {
        t.stop();
      });
    }
    this.modalRef.hide();
  }

  changeCameraStatus(status: boolean) {
    this.mediasoupService.$cameraStatus.next(status);
  }
}
