export class Offer {
  _id: string;
  title: string;
  description: string;
  content: any;
  price: number;
  idPrice: string;
  idProduct: string;
  isSubscription: boolean;
  reduction: number;
  canCreateEvents: boolean;
  eventNumberLimit: number;
  eventMaxDuration: number;
  payPerEvent: boolean;
  eventMaxParticipants: number;
  isDefault: boolean;
  isActive: boolean;
  stockage: Number;
  packMarketingData: Boolean;
}
