import { Address } from './Address.model';
import { Subscription } from './Subscription.model';

enum UserPlan {
  'FREE' = 'FREE',
  'SILVER' = 'SILVER',
  'GOLD' = 'GOLD',
}

export enum KeyStatus {
  'PRIVATE' = 'PRIVATE',
  'PUBLIC' = 'PUBLIC',
}

export class User {
  _id: string;
  firstName: string;
  lastName: string;
  email: string;
  description: String;
  password: string;
  isAdmin: boolean;
  profilePicture: string;
  currentPlan: UserPlan;
  subscribedUntil: Date;
  language: string;
  idCustomerStripe: string;
  pendingSubscription: Subscription;
  subscription: Subscription;
  address: Address;
  vatIntraCommNumber: string;
  privateKey: string[] = [];
  micActive: boolean = false;
  camActive: boolean = false;
  screenActive: boolean = false;
  // virtualBackground: string = '';
}
