import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  API_URL = environment.apiUrl;
  constructor(private http: HttpClient) { }

  getAllNotification(userId: String, limit): Observable<any> {
    return this.http.get<any>(this.API_URL + 'notifications/get-notification/' + userId + '/' + limit);
  }

  deleteNotification(_id): Observable<any> {
    return this.http.delete<any>(this.API_URL + 'notifications/delete-notification/' + _id);
  }

  notifySpace(content, socket) {
    console.log('notification service ************');
    socket.emit('notifySpace', {text: content.text, spaceId: content.spaceId, type: 'notifySpace'});
  }
}
