import { Component, OnInit } from '@angular/core';
import { trigger, animate, transition, style } from '@angular/animations';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { MediasoupService } from 'src/app/services/mediasoup.service';

@Component({
  selector: 'app-first-connexion-page',
  templateUrl: './first-connexion-page.component.html',
  styleUrls: ['./first-connexion-page.component.scss'],
  animations: [
    trigger('fadeInAnimation', [
      transition(':enter', [
          style({ opacity: 0 }),
          animate('.9s', style({ opacity: 1 }))
      ]),
    ])
  ]
})
export class FirstConnexionPageComponent implements OnInit {
  start = false;
  // configMicro = false;
  // configCame = false;
  // startConfig = false;
  // createNew = false;
  // tmpMicroPhone = {
  //   micro: null,
  //   analyser: null,
  //   javascriptNode: null,
  // };
  // deviceStatus = {
  //   microphone: true,
  //   video: true,
  //   screen: false,
  // };
  // microphones = [];
  // webcams = [];
  // selectedMicrophone = '';
  // selectedWebcam = '';
  constructor(private sanitizer: DomSanitizer,
              private router: Router,
              private translate: TranslateService,
              private mediasoupService: MediasoupService) { }

   ngOnInit(): void {
    // this.startConfig = true;
    // this.initDevice();
  }

  // initDevice() {
  //   navigator.mediaDevices.enumerateDevices().then((devices) => {
  //     this.microphones = [];
  //     this.webcams = [];
  //     for (const device of devices) {
  //       if (device.kind === 'audioinput') this.microphones.push(device);
  //       if (device.kind === 'videoinput') this.webcams.push(device);
  //     }
  //     if (this.microphones.length > 0) {
  //       if (localStorage.getItem('microphone')) {
  //         const index = this.microphones.findIndex((e) => e.deviceId === localStorage.getItem('microphone'));
  //         if (index > -1) this.selectedMicrophone = this.microphones[index].deviceId;
  //       } else {
  //         this.selectedMicrophone = this.microphones[0].deviceId;
  //       }
  //     }
  //     if (this.webcams.length > 0) {
  //       if (localStorage.getItem('webcam')) {
  //         const index = this.webcams.findIndex((e) => e.deviceId === localStorage.getItem('webcam'));
  //         if (index > -1) this.selectedWebcam = this.webcams[index].deviceId;
  //       } else {
  //         this.selectedWebcam = this.webcams[0].deviceId;
  //       }
  //     }
  //   })
  // }

  yes() {
    // this.createNew = false;
    this.start = true;
  }

  no() {
    this.router.navigate(['member']);
  }

  go() {
    this.router.navigate(['member/event/add-edit-event/new']);
  }

  // async onChangeMicrophone() {
  //   this.startConfig = false;
  //   this.configMicro = true;
  //   localStorage.setItem('microphone', this.selectedMicrophone);
  //   this.mediasoupService.closeProducer(this.mediasoupService.mediaType.audio);
  //   await this.delay(300);
  //   this.mediasoupService.testingMicrophone(this.tmpMicroPhone, this.selectedMicrophone);
  // }

  // async onChangeWebcam() {
  //   this.configMicro = false;
  //   this.configCame = true;
  //   localStorage.setItem('webcam', this.selectedWebcam);
  //   await this.delay(300);
  //   var video = document.getElementById('configVideo') as HTMLVideoElement;
  //   this.mediasoupService.closeProducer(this.mediasoupService.mediaType.video);
  //   await this.delay(300);
  //   var stream = await navigator.mediaDevices.getUserMedia({video: {deviceId: this.selectedWebcam}});
  //   video.srcObject = stream;
  // }

  // async delay(ms: number) {
  //   return new Promise((resolve) => setTimeout(resolve, ms));
  // }
}
