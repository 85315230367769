import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
@Injectable({
  providedIn: 'root',
})
export class HubinarService {
  arrayOfStreams = [];
  videos = [];
  recorder;
  videosOrder;

  public newHubinar$: BehaviorSubject<any> = new BehaviorSubject(null);
  public deleteHubinar$: BehaviorSubject<any> = new BehaviorSubject(null);
  public updateHubinar$: BehaviorSubject<any> = new BehaviorSubject(null);
  // public newStream$: BehaviorSubject<{ src: any; id: any; vbg: any; type: any; hubinar: any; rect: any; userId: any; email: any }> = new BehaviorSubject(null);
  public newStream$: BehaviorSubject<any> = new BehaviorSubject(null);
  public leaveStream$: BehaviorSubject<any> = new BehaviorSubject(null);
  public closePresentation$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public changeBackground$: BehaviorSubject<string> = new BehaviorSubject(null);
  public hubinarStatus$: BehaviorSubject<any> = new BehaviorSubject(null);
  public increaseClicks$: BehaviorSubject<any> = new BehaviorSubject(null);
  public changeVideo$: BehaviorSubject<any> = new BehaviorSubject(null);

  constructor() {}

  increment(peerid) {
    if (peerid) {
      let indx = this.videos.indexOf(peerid);
      if (indx == -1) {
        this.videosOrder++;
        this.videos.push(peerid);
        return this.videosOrder;
      } else {
        return indx;
      }
    }
  }
}
