import {
  AfterViewInit,
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import ObjectID from 'bson-objectid';
import 'gifler';
import Konva from 'konva';
import { Animation } from 'konva/types/Animation';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { Subject, Subscription } from 'rxjs';
import {
  Event,
  KonvaAnimation,
  Space,
  SpaceElement,
} from 'src/app/models/Event.model';
import { Feedback } from 'src/app/models/Feedback.model';
import { User } from 'src/app/models/User.model';
import { Webinar } from 'src/app/models/Webinar';
import { AuthService } from 'src/app/services/auth.service';
import { CanvaService } from 'src/app/services/event-core/canva.service';
import { MediasoupService } from 'src/app/services/event-core/mediasoup.service';
import { UserService } from 'src/app/services/event-core/user.service';
import { EventService } from 'src/app/services/event.service';
import { FeedbackService } from 'src/app/services/feedback.service';
import Rect = Konva.Rect;
import Group = Konva.Group;
import Img = Konva.Image;

@Component({
  selector: 'app-canva-manager',
  templateUrl: './canva-manager.component.html',
  styleUrls: ['./canva-manager.component.scss'],
})
export class CanvaManagerComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('container') container;
  @ViewChild('openingSpace') openingSpace: TemplateRef<any>;
  @ViewChild('leaveMeetingModal') leaveMeetingModal;
  @ViewChild('carouselModal') carouselModal: TemplateRef<any>;
  @ViewChild('leaveModal') leaveModal: TemplateRef<any>;
  @ViewChild('introductionModal') introductionModal: TemplateRef<any>;
  @ViewChild('deconnexion') deconnexion;
  @ViewChild('multiSession') multiSession;

  @Input() space: Space;
  @Input() user: User;
  @Input() event: Event;
  @Input() micStatus: Boolean;
  @Input('remoteVideos') set _setRemoteVideo(
    value: {
      user: User;
      type: string;
      src: MediaStream;
      id: string;
    }[]
  ) {
    this.remoteVideos = value;
  }
  remoteVideos: {
    user: User;
    type: string;
    src: MediaStream;
    id: string;
  }[] = [];
  // $changeSpace = new Subject<String>();
  @Output() changeSpace = new EventEmitter<string>();
  @Output() produceVideoHubinar = new EventEmitter<boolean>();
  @Output() produceAudioHubinar = new EventEmitter<boolean>();
  @Output() produceScreenHubinar = new EventEmitter<boolean>();
  @Output() startingRecord = new EventEmitter<boolean>();
  @Output() pauseRecord = new EventEmitter<boolean>();
  @Output() openModal = new EventEmitter<{ type: string; value: any }>();
  public setAvatarPosition$ = new Subject<any>();
  public resetAvatarPosition$ = new Subject<string>();
  layer: Konva.Layer;
  tooltipLayer: Konva.Layer;
  config = {
    stageWidth: 1920,
    stageHeight: 1080,
    fontSize: 24,
    scale: 0,
    clickableZoneColor: '#ed6a33',
    meetingZoneColor: '#1d9017',
    chairColor: '#8720e2',
    hubinarZoneColor: '#ce008e',
    cameraZoneColor: '#FFFF00',
    screenColor: '#0f4fb3',
  };
  loaded = false;
  stage: Konva.Stage;
  backgroundLayer: Konva.Layer;
  tooltip: Konva.Label;
  userOnSpace = [] as Array<Group>;
  clickableZones = [] as Array<any>;
  meetingZones = [] as Array<Rect>;
  currentMeeting: Rect;
  hasCurrentMeeting = false;
  chairs = [] as Array<Rect>;
  icons = [] as Array<any>;
  hubinarZones = [] as Array<any>;
  availableForJoin = [] as Array<Rect | Img>;
  hugeWidth = window.innerWidth * 0.95;
  hugeHeight = Math.min(this.hugeWidth * 0.5625, window.innerHeight * 0.95);
  currentUserAvatar: Group;
  currentElementId: string;
  inHubinar: string;
  currentPossitionOnMeet = false;
  nextLinkHub = '';
  joinIn: boolean = false;
  canTo;
  hasChair = {
    status: false,
    idSeat: undefined,
    parent: undefined,
  };
  out: number = 0;
  allSubscriptions = new Subscription();
  opening: BsModalRef;
  spaceOnModal: Space;
  modalRef: BsModalRef;
  carousel: any[] = [];
  isPaused = true;
  leaving: BsModalRef;
  avatarPosition: {
    x: number;
    y: number;
  };
  hubinarData = {
    isHubinarMode: false,
    sidebar: false,
    isModerator: false,
    isContributor: false,
    isStarted: false,
    record: {
      play: false,
      paused: false,
    },
  };
  public canToggle = {
    camera: false,
    microphone: false,
    screen: false,
  };
  hubinar: Webinar;
  peopleInHubinars: any[] = [];
  animation: Konva.Animation;
  animations: KonvaAnimation[] = [];
  streamVideoAvatar;
  videoAvatar;
  public currentFeedbacksSpace: Feedback[] = [];
  public feedback: Feedback = {
    name: '',
    question: [],
    spaceId: '',
    response: false,
    user: [],
    proprietaire: '',
    date: new Date(),
  };
  public feedbackType: string = '';
  public questionIndex;
  allIndex = [];
  public currentQuestion: string = '';
  feedbackToSend: any[] = [];
  public introMeeting: any[] = [];
  public shareZones = [] as Array<Rect>;
  public interval;
  public timer: number = 0;
  public counterInterval;
  public anim;
  public statusUser = {
    inDisconnect: false,
    disconnected: false,
    counter: 10,
    noResponse: true,
  };
  public welcomeSong;
  public connected: boolean = false;
  public cameraStatus: boolean = false;
  public isInvite: boolean = false;
  public hugeTmp;
  public huge;
  public containerPointer;
  @HostListener('window:beforeunload', ['$event']) sendDataBeforePageLeave() {
    this.ngOnDestroy();
  }
  constructor(
    public canvaService: CanvaService,
    private userService: UserService,
    public mediasoupService: MediasoupService,
    private toastr: ToastrService,
    private eventService: EventService,
    private _snackBar: MatSnackBar,
    private router: Router,
    private modalService: BsModalService,
    private feedbackService: FeedbackService,
    private translate: TranslateService,
    private authService: AuthService
  ) {}

  async ngOnInit() {
    await this.canvaService.socket.connect();
    this.initSubscribes();
    console.log('this.micStatus *** ', this.micStatus);
    this.canvaService.userJoinSpace({
      eventId: this.event._id,
      spaceId: this.space._id,
      camera: false,
      mic: this.micStatus,
    });
    this.canvaService.socket.emit('changeStatusUser', {
      ...this.user,
      type: 'join',
      eventId: this.event._id,
      status: 'connected',
      start: new Date().getTime(),
      space: this.space.title,
      spaceId: this.space._id,
    });
    if (this.space.activeCameraPastille) {
      this.mediasoupService.joinRoom(
        this.user.firstName + this.user.lastName,
        this.space._id + this.event._id + this.space._id,
        this.canvaService.socket,
        this.user,
        true
      );
    }
    this.sessionManager();
  }

  ngAfterViewInit() {
    this.initializeCanva();
    this.initElements();
    this.addAvatar(this.user).then((avatar: Group) => {
      this.currentUserAvatar = avatar;
      if (this.space.activeCameraPastille && !this.connected) {
        if (this.event.camActive) this.addVideoInAvatar(this.user);
      }
      this.currentUserAvatar.moveToTop();
      this.calculateAvailableForJoin();
    });
    setTimeout(() => {
      if (this.currentUserAvatar) this.currentUserAvatar.moveToTop();
    }, 2000);
  }

  initElements() {
    for (const element of this.space.elements) {
      switch (element.type) {
        case 'ZONE':
          const hasSeat = this.space.elements.find(
            (el) => el.values[0] === element._id
          );
          this.addMeetingZone(element, hasSeat);
          break;
        case 'ICON':
          this.addResource(element);
          break;
        case 'SEAT':
          this.addChair(element);
          break;
        case 'HUBINAR':
          console.log('hubinar element: ', element);

          this.addHubinarZone(element);
          break;
        case 'REDIRECT-POLYGON':
          this.addRedirectPolygon(element);
          break;
        case 'REDIRECT':
          this.addRectangleDoor(element);
          break;
      }
    }

    this.calculateAvailableForJoin();
  }

  initSubscribes() {
    this.allSubscriptions.add(
      this.canvaService.$userJoined.subscribe((content: any) => {
        console.log('content join ****** ', content);
        const avatarExist = this.userOnSpace.find(
          (e) => e?.getChildren()[0].attrs?._id === content.user._id
        );
        if (!avatarExist) {
          if (
            this.userOnSpace.length <=
              (this.space.nbInvite ? this.space.nbInvite : 20) ||
            this.hasBadge(content.user.email)
          ) {
            this.addAvatar(content.user).then((avatar: Group) => {
              avatar.setAttr('microphone', content.mic);
              avatar.moveToTop();
              this.currentUserAvatar.moveToTop();
              if (this.space.activeCameraPastille && !this.connected) {
                this.addVideoInAvatar(content.user);
              }
              this.layer.draw();
            });
          } else {
            this.toastr.info(
              "Un utilisateur vient d'arriver mais le nombre maximum de pastille à afficher est déjà atteint"
            );
          }
        } else {
        }
      })
    );

    this.mediasoupService.$newVideo.subscribe((video) => {
      if (
        this.space.activeCameraPastille &&
        video.type === this.mediasoupService.mediaType.video &&
        !this.connected
      ) {
        this.addVideoInAvatar(video.user);
      }
      if (
        this.hubinarData.isHubinarMode &&
        video.type === this.mediasoupService.mediaType.screen
      ) {
        const shareElement = this.space.elements.find(
          (elem) => elem.type === 'SHARE'
        );
        if (shareElement) this.addSharescreenZone(shareElement);
        setTimeout(() => {
          this.addVideoInSharescreenZone(video.src);
        }, 1000);
      }
    });

    this.mediasoupService.$inRoom.subscribe((join) => {
      this.connected = join;
      if (join) this.removeVideoInAvatar(this.user);
    });

    this.canvaService.$multiSession.subscribe((content) => {
      if (this.user._id === content.idUser) {
        this.router.navigate(['']);

        // this.authService.logout();
      }
    });

    this.mediasoupService.$isRecording.subscribe((record) => {
      if (record)
        this.canvaService.startRecord(this.space._id, this.hubinar._id);
    });
    this.mediasoupService.$removeVideo.subscribe((idConsumer) => {
      const video = this.remoteVideos.find(
        (userVideo) => userVideo.id === idConsumer
      );
      if (video) {
        if (
          this.space.activeCameraPastille &&
          video.type === this.mediasoupService.mediaType.video &&
          !this.connected
        )
          this.removeVideoInAvatar(video.user);
        if (
          this.hubinarData.isHubinarMode &&
          video.type === this.mediasoupService.mediaType.screen
        ) {
          const share = this.shareZones[0];
          share.setAttr('fillPatternImage', null);
          const animToStart = this.animations.find((e) => e._id === 'share');
          if (animToStart) animToStart.animation.stop();
          share.destroy();
          this.layer.draw();
        }
      }
    });

    this.mediasoupService.$cameraStatus.subscribe((status) => {
      this.cameraStatus = status;
      if (status) {
        if (this.space.activeCameraPastille && !this.connected) {
          this.addVideoInAvatar(this.user);
        }
      } else {
        if (this.space.activeCameraPastille && !this.connected) {
          this.removeVideoInAvatar(this.user);
        }
      }
    });

    this.canvaService.$seating.subscribe((seat) => {
      const idx = this.availableForJoin.findIndex(
        (rect) => rect.attrs._id === seat.idSeat
      );

      this.availableForJoin[idx].attrs.occupied = seat.status;
      console.log('status: ', seat.status);
      console.log('index: ', idx);
      console.log('current status: ', this.availableForJoin[idx].attrs);
    });
    this.canvaService.$leavingSeat.subscribe((seat) => {
      const idx = this.availableForJoin.findIndex(
        (rect) => rect.attrs._id === seat.idSeat
      );
      this.availableForJoin[idx].attrs.occupied = seat.status;
      console.log('status 2: ', seat.status);
      console.log('index 2: ', idx);
      console.log('current status 2: ', this.availableForJoin[idx].attrs);
    });

    this.canvaService.$deconnectUser.subscribe((data) => {
      if (data.userId === this.user._id) {
        this.canvaService.socket.emit('changeStatusUser', {
          ...this.user,
          type: 'disconnected',
          eventId: this.event._id,
        });
        this.router.navigate(['public', 'landing', this.event._id]);
        this.toastr.warning('Vous avez été déconnecté du hub', 'Information');
      }
    });

    this.allSubscriptions.add(
      this.canvaService.$getAnotherUserConnected.subscribe(
        async (callback: any) => {
          console.log('call back******* ', callback);
          this.canvaService.socket.emit('multiSession', {
            idUser: this.user._id,
            idEvent: this.event._id,
          });
          // if (callback?.isExist) {
          await this.delay(1500);
          const avatar = this.userOnSpace.find(
            (e) => e.getChildren()[0].attrs._id === this.user._id
          );
          if (!avatar) {
            this.addAvatar(this.user).then((avatar: Group) => {
              this.currentUserAvatar = avatar;
              if (this.space.activeCameraPastille && !this.connected) {
                if (this.event.camActive) this.addVideoInAvatar(this.user);
              }
              this.currentUserAvatar.moveToTop();
              this.calculateAvailableForJoin();
            });
          }
          // }
          if (callback) {
            for (const content of callback?.users) {
              if (
                this.userOnSpace.length <=
                  (this.space.nbInvite ? this.space.nbInvite : 20) ||
                this.hasBadge(content.user.email)
              ) {
                const avatar = this.userOnSpace.find(
                  (e) => e.getChildren()[0].attrs._id === content.user._id
                );
                // const userIsInConnectedUsers = this.connectedUsers.find((e) => e._id === content.user._id);
                // if (!userIsInConnectedUsers) this.connectedUsers.push(content.user);
                if (!avatar) {
                  this.addAvatar(
                    content.user,
                    content.args[0],
                    content.args[1]
                  ).then((group) => {
                    if (group) {
                      const av = group;
                      av.setAttr('scaleX', content.args[2]);
                      av.setAttr('scaleY', content.args[3]);
                      av.setAttr('microphone', content.mic);
                      if (this.space.activeCameraPastille && !this.connected) {
                        this.addVideoInAvatar(content.user);
                      }
                      // group.moveToBottom();
                      // this.myAvatar.moveToTop();
                      // if (content.args.length > 4) {
                      //   const rect = content.args[4].attrs;
                      //   switch (rect.id) {
                      //     case 'ZONE':
                      //       const foundedZone = this.meetingZones.find((e) => e.attrs._id === rect._id);
                      //       if (foundedZone) {
                      //         foundedZone.setAttr('nPeople', foundedZone.attrs.nPeople + 1);
                      //       }
                      //       break;
                      //     case 'SEAT':
                      //       const foundedChair = this.chairs.find((e) => e.attrs._id === rect._id);
                      //       if (foundedChair) {
                      //         foundedChair.setAttr('occupied', true);
                      //       }
                      //       break;
                      //   }
                      // }
                      // this.calculateAvailableForJoin();
                      // this.removeDuplicates();
                    }
                  });
                }
                // else alert('exit');
                console.log('user join ************* ', callback.users);
                // this.notificationService.notifySpace({ text: String(content.user.firstName + ' ' + this.translate.instant('JUST_CAME_AROUND')), spaceId: this.space._id }, this.socket);
              } else {
                console.log('nombre maximum de pastille atteint');
              }
            }
          }
        }
      )
    );
    this.allSubscriptions.add(
      this.canvaService.$userMoved.subscribe((result: any) => {
        const avatarToMove = this.userOnSpace.find(
          (e) => e && e.attrs._id === result.user._id
        );
        if (avatarToMove) {
          avatarToMove.setAttr('x', result.position[0]);
          avatarToMove.setAttr('y', result.position[1]);
          avatarToMove.setAttr('scaleX', result.position[2]);
          avatarToMove.setAttr('scaleY', result.position[3]);
        } else {
          // if (!this.isInit) {
          //   // if (this.people.length <= (this.space.nbInvite ? this.space.nbInvite : 20) || this.hasBadge(content.user.email)) {
          //   this.addAvatar(result.user).then((e) => {
          //     if (e) {
          //       return e;
          //     }
          //   });
          //   await this.delay(2000);
          //   this.moveAllAvatarToTop();
          //   // }
          // }
        }
        this.currentUserAvatar.moveToTop();
        this.layer.batchDraw();
      })
    );
    this.allSubscriptions.add(
      this.canvaService.$userLeft.subscribe((user: User) => {
        var avatarLeave = this.userOnSpace.find(
          (e) => e.getChildren()[0].attrs._id === user._id
        );
        // this.messageService.removeUser(content.user);
        // const userInConnectedUsersIndex = this.connectedUsers.findIndex((e) => e._id === content.user._id);
        // if (userInConnectedUsersIndex > -1) this.connectedUsers.splice(userInConnectedUsersIndex, 1);
        if (avatarLeave) {
          this.userOnSpace.splice(this.userOnSpace.indexOf(avatarLeave), 1);
          avatarLeave.destroy();
        }

        this.layer.draw();
      })
    );

    this.allSubscriptions.add(
      this.canvaService.$statusMicrophone.subscribe((content) => {
        console.log('status *************** ', content);
        if (content.status) {
          this.removeMicrophoneOnAvatar(content.user, false);
        } else {
          this.addMicrophoneOnAvatar(content.user, null, false);
        }
      })
    );

    this.canvaService.$startHubinar.subscribe(async (start) => {
      const idx = this.availableForJoin.findIndex(
        (av) => av.attrs._id === start
      );
      this.availableForJoin[idx].attrs.webinar.status = 'inProgress';
      if (this.hubinar && this.hubinar._id === start) {
        this.hubinarData.isStarted = true;
        this.mediasoupService.$inRoom.next(true);
        this.toastr.info('La session est lancée');
        if (this.hubinarData.isContributor || this.hubinarData.isModerator) {
          this.canvaService.addPeopleInRoom(
            this.space._id,
            this.user._id,
            this.user.profilePicture,
            this.user.firstName + ' ' + this.user.lastName,
            this.space._id + this.hubinar._id + this.event._id
          );
        }
      }
    });

    this.canvaService.$stopHubinar.subscribe((stop) => {
      const idx = this.availableForJoin.findIndex(
        (av) => av.attrs._id === stop
      );
      this.availableForJoin[idx].attrs.webinar.status = 'finished';
      if (this.hubinar && this.hubinar._id === stop) {
        this.toastr.warning('La session est terminée');
        this.hubinarData.isStarted = false;
        this.leaveHubinar();
      }
    });
    this.canvaService.$leaveModerator.subscribe((stop) => {
      if (this.hubinar && this.hubinar._id === stop) {
        this.toastr.warning(
          "L'admin a quitté la session donc vous allez être viré du hubinar dans 5s"
        );
        setTimeout(() => {
          this.hubinarData.isStarted = false;
          this.leaveHubinar();
        }, 5000);
      }
    });
    this.canvaService.$addPeole.subscribe((data) => {
      const idx = this.peopleInHubinars.findIndex(
        (people) => people._id === data._id
      );
      if (idx === -1) {
        this.peopleInHubinars.push(data);
      } else {
        this.peopleInHubinars[idx] = data;
      }
    });

    this.canvaService.$removePeople.subscribe((data) => {
      this.peopleInHubinars = this.peopleInHubinars.filter(
        (user) => user._id !== data._id
      );
    });

    this.canvaService.$joinHubinar.subscribe((data) => {
      this.peopleInHubinars = data;
      this.changeCanToggle();
      console.log('people 2: ', this.peopleInHubinars);
    });
    this.canvaService.$changeRoleHubinar.subscribe((data) => {
      if (data.hubinarId === this.hubinar._id) {
        if (this.user._id === data.userId) {
          if (data.isCamera !== this.canToggle.camera) {
            this.peopleInHubinars = data.data;
            this.changeCanToggle();
            if (data.isCamera) {
              this.produceVideoHubinar.emit(true);
              this.toastr.info("L'administrateur a allumé votre caméra");
            } else {
              this.produceVideoHubinar.emit(false);
              this.toastr.info("L'administrateur a éteint votre caméra");
            }
          }

          if (data.isMicrophone !== this.canToggle.microphone) {
            this.peopleInHubinars = data.data;
            this.changeCanToggle();
            if (data.isMicrophone) {
              this.produceAudioHubinar.emit(true);
              this.toastr.info("L'administrateur a allumé votre microphone");
            } else {
              this.produceAudioHubinar.emit(false);
              this.toastr.info("L'administrateur a éteint votre microphone");
            }
          }

          if (data.isSharescreen !== this.canToggle.screen) {
            this.peopleInHubinars = data.data;
            this.changeCanToggle();
            if (data.isSharescreen) {
              // this.produceScreenHubinar.emit(true);
              this.toastr.info(
                "L'administrateur a allumé votre partage d'écran"
              );
            } else {
              this.produceScreenHubinar.emit(false);
              this.toastr.info(
                "L'administrateur a éteint votre partage d'écran"
              );
            }
          }
        } else {
          this.peopleInHubinars = data.data;
          this.changeCanToggle();
        }
      }
    });

    this.canvaService.$startRecord.subscribe((hubinarId) => {
      if (this.hubinar && this.hubinar._id === hubinarId)
        this.toastr.warning('Un enregistrement est lancé');
    });

    this.canvaService.$stopRecord.subscribe((hubinarId) => {
      if (this.hubinar && this.hubinar._id === hubinarId)
        this.toastr.warning('Un enregistrement est stoppé');
    });
    this.canvaService.$openedDoor.subscribe((door) => {
      const idx = this.event.spaces.findIndex(
        (space) => space._id === door.spaceId
      );
      this.event.spaces[idx].close = false;
      this.toastr.success(
        door.name +
          ' as ouvert la porte ' +
          door.spaceName +
          '. Vous pouvez y entrer'
      );
    });
  }

  initializeCanva() {
    this.stage = new Konva.Stage({
      container: 'container',
      width: this.config.stageWidth,
      height: this.config.stageHeight,
    });

    this.layer = new Konva.Layer();
    this.layer.zIndex(1);
    this.backgroundLayer = new Konva.Layer({ draggable: false });
    this.tooltipLayer = new Konva.Layer();

    this.tooltip = new Konva.Label({
      text: '',
      fontFamily: 'Poppins',
      fontSize: this.config.fontSize,
      padding: 5,
      textFill: 'white',
      fill: 'black',
      alpha: 0.75,
      visible: false,
    });
    this.tooltip.add(
      new Konva.Tag({
        fill: 'black',
        pointerDirection: 'down',
        pointerWidth: 10,
        pointerHeight: 10,
        lineJoin: 'round',
        shadowColor: 'black',
        shadowBlur: 10,
        shadowOffsetX: 10,
        shadowOffsetY: 10,
        shadowOpacity: 0.5,
      })
    );
    this.tooltip.add(
      new Konva.Text({
        text: 'Tooltip pointing down',
        fontFamily: 'Poppins',
        fontSize: this.config.fontSize,
        padding: 10,
        fill: 'white',
      })
    );
    this.tooltipLayer.add(this.tooltip);

    this.stage.add(this.backgroundLayer);
    this.stage.add(this.layer);
    this.stage.add(this.tooltipLayer);
    // Resize the stage to the window
    this.fitStageIntoParentContainer();
    this.loadBackground();
  }

  fitStageIntoParentContainer() {
    // then we apply it
    this.config.scale = Math.min(
      this.container.nativeElement.offsetWidth / 1920,
      this.container.nativeElement.offsetHeight / 1080
    );
    this.stage.width(1920 * this.config.scale);
    this.stage.height(1080 * this.config.scale);
    this.stage.scale({ x: this.config.scale, y: this.config.scale });
    // finally we redraw the stage
    this.stage.draw();
  }

  loadBackground() {
    if (this.space && this.space.backgroundImage.length > 1) {
      const img = new Image();
      img.onload = () => {
        const background = new Konva.Rect({
          x: 0,
          y: 0,
          width: this.config.stageWidth,
          height: this.config.stageHeight,
          fillPatternImage: img,
          fillPatternScaleX: this.config.stageWidth / img.width,
          fillPatternScaleY: this.config.stageHeight / img.height,
          listening: false,
        });
        this.backgroundLayer.add(background);
        this.backgroundLayer.draw();
        this.loaded = true;
      };
      img.src = this.space.backgroundImage;
    } else {
      this.backgroundLayer.removeChildren();
      this.backgroundLayer.draw();
      this.loaded = true;
    }
  }

  addAvatar(user: User, x?, y?, r?) {
    if (user.firstName || user.lastName) {
      return new Promise<Konva.Group>((resolve, reject) => {
        const img = new Image();
        img.onload = async () => {
          const avatar = new Konva.Group({
            draggable: user._id === this.user._id ? true : false,
            x: x ? x : Math.floor(Math.random() * 401) + 100, //between 100 & 500
            y: y ? y : Math.floor(Math.random() * 199) + 50, //between 50 & 250
            listening: true,
            _id: user._id,
            isAvatar: true,
            dragBoundFunc: (pos) => {
              return this.avatarDragBound(avatar, pos);
            },
          });
          const circle = new Konva.Circle({
            radius: r ? r : 40,
            drawBorder: true,
            fillPatternImage: img,
            fillPatternRepeat: 'no-repeat',
            fillPatternOffset: { x: r ? r : 50, y: r ? r : 50 },
            fillPatternScale: {
              x: (r ? 2 * r : 100) / img.width,
              y: (r ? 2 * r : 100) / img.height,
            },
            listening: true,
            user: user,
            stroke: 'green',
            strokeWidth: 3,
            name: 'avatar',
            _id: user._id,
          });
          const dx = avatar.getAbsolutePosition().x;
          const dy = avatar.getAbsolutePosition().y;
          if (
            (!this.space.isLandingVideo ||
              (this.space.isLandingVideo &&
                !localStorage.getItem(this.space.landingVideo))) &&
            localStorage.getItem('firstConnexion') &&
            user._id === this.user._id &&
            !localStorage.getItem('newDirecLinkMee')
          ) {
            // run animation
            this.setAnimation(x, y, r);
            setTimeout(() => {
              this.anim.stop();
              const rm = this.stage.find((e) => e.attrs._id === 'animation');
              rm[0].destroy();
              localStorage.removeItem('firstConnexion');
              this.layer.draw();
            }, 6000);
            this.userService.$showInformation.next({
              coords: {
                x: dx,
                y: dy,
              },
            });
          }
          this.canvaService.$isAnimation.subscribe((res) => {
            if (
              localStorage.getItem('firstConnexion') &&
              user._id === this.user._id &&
              !localStorage.getItem('newDirecLinkMee')
            ) {
              // run animation
              this.setAnimation(x, y, r);
              setTimeout(() => {
                this.anim.stop();
                const rm = this.stage.find((e) => e.attrs._id === 'animation');
                rm[0].destroy();
                localStorage.removeItem('firstConnexion');
                this.layer.draw();
              }, 6000);
              this.userService.$showInformation.next({
                coords: {
                  x: dx,
                  y: dy,
                },
              });
            }
          });
          avatar.add(circle);
          this.setBadge(user, avatar);
          this.resetAvatarPosition$.subscribe((reset) => {
            if (
              reset &&
              reset == avatar.attrs._id &&
              this.avatarPosition?.x &&
              this.avatarPosition?.y
            ) {
              avatar.setPosition({
                x: this.avatarPosition.x,
                y: this.avatarPosition.y,
              });
              this.layer.batchDraw();
            }
          });
          this.setAvatarPosition$.subscribe((position) => {
            if (avatar.attrs._id == position.avatarId) {
              const targetMeeting = this.availableForJoin.find(
                (elem) => elem.attrs._id === position.meetingId
              );
              const zoneVerte = {
                x1: targetMeeting.x(),
                x2:
                  targetMeeting.x() +
                  targetMeeting.width() * targetMeeting.scaleX() -
                  25,
                y1: targetMeeting.y(),
                y2:
                  targetMeeting.y() +
                  targetMeeting.height() * targetMeeting.scaleY() -
                  25,
              };
              avatar.setPosition({
                x: this.randomIntFromInterval(zoneVerte.x1, zoneVerte.x2),
                y: this.randomIntFromInterval(zoneVerte.y1, zoneVerte.y2),
              });
              this.layer.batchDraw();
            }
          });
          avatar.on('mousemove', (e) => {
            if (e.target.attrs.user) {
              const mousePos = this.stage.getPointerPosition();
              this.setToolTip(
                mousePos,
                e.target.attrs.user.firstName +
                  ' ' +
                  e.target.attrs.user.lastName
              );
              this.tooltip.show();
              this.tooltipLayer.batchDraw();
            }
          });

          avatar.on('click', (event) => {
            if (event.target.attrs.user) {
              const mousePos = this.stage.getPointerPosition();
              const targetStyle = window.getComputedStyle(
                document.getElementsByClassName('konvajs-content')[0]
              );
              const marginLeft = targetStyle.marginLeft.substring(
                0,
                targetStyle.marginLeft.length - 2
              );
              this.userService.currentUserClicked$.next({
                user: event.target.attrs.user,
                coords: {
                  x: mousePos.x + 5 + parseInt(marginLeft),
                  y: mousePos.y + 5,
                },
              });
              this.userService.currentStatus$.next(true);
            }
          });

          avatar.on('mouseout', () => {
            this.tooltip.hide();
            this.tooltipLayer.draw();
          });

          avatar.on('touchmove dragmove', (event) => {
            if (this.statusUser.disconnected || this.statusUser.inDisconnect) {
              this.canvaService.socket.emit('changeStatusUser', {
                ...this.user,
                type: 'changing',
                eventId: this.event._id,
                status: 'connected',
                start: new Date(),
              });
              this.statusUser.disconnected = false;
              this.statusUser.inDisconnect = false;
              this.timer = 0;
            }
            const positon = { x: event.target.x(), y: event.target.y() };
            this.moveAvatar(event.target);
            // console.log('available: ', this.availableForJoin);
            for (const element of this.availableForJoin) {
              var el = element;
              if (element.attrs.id == 'REDIRECT-POLYGON') {
                var lineClientRect = element.getSelfRect();
                el = new Konva.Rect({
                  x: lineClientRect.x,
                  y: lineClientRect.y,
                  width: lineClientRect.width,
                  height: lineClientRect.height,
                  id: 'REDIRECT-POLYGON',
                  _id: element.attrs._id,
                  linkTitle: element.attrs.linkTitle,
                });
              }
              // const elementPosition = {
              //   x1: el.x(),
              //   x2: el.x() + el.width() * el.scaleX(),
              //   y1: el.y(),
              //   y2: el.y() + el.height() * el.scaleY(),
              // };
              // if (
              //   (Math.round(positon.x) >= Math.round(elementPosition.x1) &&
              //     Math.round(positon.x) <=
              //       Math.round(elementPosition.x1 + 20)) ||
              //   (Math.round(positon.x) <= Math.round(elementPosition.x2) &&
              //     Math.round(positon.x) >=
              //       Math.round(elementPosition.x2 - 20)) ||
              //   (Math.round(positon.y) >= Math.round(elementPosition.y1) &&
              //     Math.round(positon.y) <=
              //       Math.round(elementPosition.y1 + 20)) ||
              //   (Math.round(positon.y) <= Math.round(elementPosition.y2) &&
              //     Math.round(positon.y) >= Math.round(elementPosition.y2 - 20))
              // ) {
              //   // this.currentElement = element;
              //   // this.currentAvatar = avatar;
              //   // this.stop = true;
              // }
              if (!el.attrs.hasSeat) el.show();

              const elemPosition = {
                x1: el.x(),
                x2: el.x() + el.width() * el.scaleX(),
                y1: el.y(),
                y2: el.y() + el.height() * el.scaleY(),
              };
              if (
                positon.x > elemPosition.x1 &&
                positon.x < elemPosition.x2 &&
                positon.y > elemPosition.y1 &&
                positon.y < elemPosition.y2
              ) {
                if (el.attrs.id === 'REDIRECT') {
                  this.canvaService.enterHubdoorZone$.next({
                    id: el.attrs._id,
                    in: true,
                  });
                  // this.eventService.enterHubdoorZone$.next({ id: element.attrs._id, in: true });
                }

                if (el.attrs.id === 'REDIRECT-POLYGON') {
                  this.canvaService.$enterPolygonZone.next({
                    id: el.attrs._id,
                    in: true,
                  });
                  this.toastr.info('Porte vers: ' + el.attrs.linkTitle, null, {
                    positionClass: 'toast-top-center',
                    disableTimeOut: true,
                    easeTime: 0,
                  });
                  // this.eventService.enterHubdoorZone$.next({ id: element.attrs._id, in: true });
                }
              } else {
                this.canvaService.enterHubdoorZone$.next({
                  id: el.attrs._id,
                  in: false,
                });
                this.canvaService.$enterPolygonZone.next({
                  id: el.attrs._id,
                  in: false,
                });
              }
              // else this.eventService.enterHubdoorZone$.next({ id: element.attrs._id, in: false });
              if (
                positon.x > elemPosition.x1 &&
                positon.x < elemPosition.x2 &&
                positon.y > elemPosition.y1 &&
                positon.y < elemPosition.y2
              ) {
                this.out = 0;
                switch (el.attrs.id) {
                  case 'ZONE':
                    if (
                      this.currentElementId !== el.attrs._id &&
                      !el.attrs.hasSeat
                    ) {
                      this.currentElementId = el.attrs._id;
                      if (this.canvaService.isMobile) {
                        this._snackBar.open('Zone de meeting');
                      } else {
                        this.toastr.info('Zone de meeting', null, {
                          positionClass: 'toast-top-center',
                          disableTimeOut: true,
                          easeTime: 0,
                        });
                      }
                    }
                    break;
                  case 'HUBINAR':
                    if (this.currentElementId !== el.attrs._id) {
                      this.currentElementId = el.attrs._id;
                      if (this.canvaService.isMobile) {
                        this._snackBar.open(
                          'Hubinar: ' + element.attrs.values[0]
                        );
                      } else {
                        this.toastr.info(
                          'Hubinar: ' + el.attrs.values[0],
                          null,
                          {
                            positionClass: 'toast-top-center',
                            disableTimeOut: true,
                            easeTime: 0,
                          }
                        );
                      }
                    }
                    break;
                  case 'SEAT':
                    if (this.currentElementId !== el.attrs._id) {
                      this.currentElementId = el.attrs._id;
                      if (this.canvaService.isMobile) {
                        this._snackBar.open('Chaise');
                      } else {
                        this.toastr.info('Chaise', null, {
                          positionClass: 'toast-top-center',
                          disableTimeOut: true,
                          easeTime: 0,
                        });
                      }
                    }
                    break;
                  case 'REDIRECT':
                    if (this.currentElementId !== el.attrs._id) {
                      this.currentElementId = el.attrs._id;
                      if (this.eventService.isMobile) {
                        this._snackBar.open(
                          'Porte vers: ' + element.attrs.linkTitle
                        );
                      } else {
                        this.toastr.info(
                          'Porte vers: ' + el.attrs.linkTitle,
                          null,
                          {
                            positionClass: 'toast-top-center',
                            disableTimeOut: true,
                            easeTime: 0,
                          }
                        );
                        this.canvaService.enterHubdoorZone$.next({
                          id: el.attrs._id,
                          in: false,
                        });
                      }
                      // this.eventService.enterHubdoorZone$.next({ id: element.attrs._id, in: false });
                    }
                    break;
                  default:
                    break;
                }
              } else {
                this.out += 1;
                if (this.out > this.availableForJoin.length) {
                  this._snackBar.dismiss();
                  this.toastr.clear();
                  this.currentElementId = undefined;
                  // this.eventService.enterHubdoorZone$.next({ id: element.attrs._id, in: false });
                }
              }
            }
            this.tooltip.hide();
            this.tooltipLayer.batchDraw();
          });
          avatar.on('dragstart', (event) => {
            this.avatarPosition = { x: event.target.x(), y: event.target.y() };
          });
          avatar.on('touchend dragend', async (event) => {
            this._snackBar.dismiss();
            this.toastr.clear();
            this.currentElementId = undefined;

            const positon = { x: event.target.x(), y: event.target.y() };
            let canJoinElement = undefined;
            for (const element of this.availableForJoin) {
              var elem = element;
              if (element.attrs.id == 'REDIRECT-POLYGON') {
                var lineClientRect = element.getSelfRect();
                elem = new Konva.Rect({
                  x: lineClientRect.x,
                  y: lineClientRect.y,
                  width: lineClientRect.width,
                  height: lineClientRect.height,
                });
              }
              const elementPosition = {
                x1: elem.x(),
                x2: elem.x() + elem.width() * elem.scaleX(),
                y1: elem.y(),
                y2: elem.y() + elem.height() * elem.scaleY(),
              };
              if (
                positon.x > elementPosition.x1 &&
                positon.x < elementPosition.x2 &&
                positon.y > elementPosition.y1 &&
                positon.y < elementPosition.y2
              ) {
                console.log('element: ', element);
                if (!element.attrs.hasSeat) canJoinElement = element;
              }
              if (
                element.attrs.id != 'REDIRECT' &&
                element.attrs.id != 'HUBINAR' &&
                element.attrs.id != 'REDIRECT-POLYGON'
              ) {
                element.hide();
              }
            }
            this.inHubinar = canJoinElement
              ? canJoinElement.attrs.id
              : undefined;
            console.log(' **canJoinElement** ', canJoinElement);
            if (canJoinElement) this.joinIn = true;
            if (
              this.currentMeeting &&
              canJoinElement &&
              this.currentMeeting.attrs._id == canJoinElement.attrs._id
            ) {
              console.log('***same***');
              return;
            }
            if (
              canJoinElement &&
              canJoinElement.attrs.id == 'ZONE' &&
              !canJoinElement.attrs.hasSeat
            ) {
              if (canJoinElement.attrs.codeMeet) {
                localStorage.setItem(
                  'directLinkData',
                  JSON.stringify({
                    codeMeet: canJoinElement.attrs.codeMeet,
                    avatarId: this.user._id,
                  })
                );
                this.openModal.emit({ type: 'askCodeMeet', value: '' });
                this.canvaService.$startMeeting.subscribe((start) => {
                  if (start) {
                    this.canvaService.addPeopleInRoom(
                      this.space._id,
                      this.user._id,
                      this.user.profilePicture,
                      this.user.firstName + ' ' + this.user.lastName,
                      this.space._id + canJoinElement.attrs._id
                    );
                    this.currentMeeting = canJoinElement;
                    this.hasCurrentMeeting = true;
                    this.joinRoom(this.space._id + canJoinElement.attrs._id);
                    if (
                      !this.currentPossitionOnMeet &&
                      canJoinElement.attrs.isActiveWelcomeSound &&
                      canJoinElement.attrs.welcomeSound
                    ) {
                      this.welcomeSong = document.getElementById(
                        'welcome'
                      ) as HTMLAudioElement;
                      this.welcomeSong.src = canJoinElement.attrs.welcomeSound;
                      this.welcomeSong.play();
                    }
                  }
                });
              } else {
                console.log('gegege: ', canJoinElement.attrs);

                if (canJoinElement.attrs.intro.length <= 0) {
                  this.currentMeeting = canJoinElement;
                  this.hasCurrentMeeting = true;
                  this.canvaService.addPeopleInRoom(
                    this.space._id,
                    this.user._id,
                    this.user.profilePicture,
                    this.user.firstName + ' ' + this.user.lastName,
                    this.space._id + canJoinElement.attrs._id
                  );
                  this.joinRoom(this.space._id + canJoinElement.attrs._id);

                  if (
                    !this.currentPossitionOnMeet &&
                    canJoinElement.attrs.isActiveWelcomeSound &&
                    canJoinElement.attrs.welcomeSound
                  ) {
                    this.welcomeSong = document.getElementById(
                      'welcome'
                    ) as HTMLAudioElement;
                    this.welcomeSong.src = canJoinElement.attrs.welcomeSound;
                    this.welcomeSong.play();
                  }
                } else {
                  this.currentMeeting = canJoinElement;
                  this.introMeeting = canJoinElement.attrs.intro;
                  this.modalRef = this.modalService.show(
                    this.introductionModal,
                    {
                      ignoreBackdropClick: true,
                      class: 'modal-lg',
                    }
                  );
                }
                // this.eventService.currentMeeting$.next(canJoinElement);
                // if (canJoinElement.attrs.moderatorMeet.findIndex((element) => element.email === this.user.email) > -1) {
                //   this.eventService.isModeratorMeet$.next(true);
                // }
              }
            }
            if (canJoinElement && canJoinElement.attrs.id == 'SEAT') {
              if (canJoinElement.attrs.occupied) {
                this.toastr.error(this.translate.instant('SEAT_OCCUPIED'));
              } else {
                this.leaveSeat();
                await this.delay(1000);
                this.canTo = canJoinElement;
                this.currentMeeting = canJoinElement;
                this.getFeedback();
                this.joinRoom(
                  this.space._id + canJoinElement.attrs.meetingZone
                );
                this.canvaService.addPeopleInRoom(
                  this.space._id,
                  this.user._id,
                  this.user.profilePicture,
                  this.user.firstName + ' ' + this.user.lastName,
                  this.space._id + canJoinElement.attrs.meetingZone
                );
                console.log('starting');

                this.canvaService.onSeating(
                  this.event._id,
                  this.space._id,
                  canJoinElement.attrs._id,
                  [canJoinElement.attrs.meetingZone, true]
                );
                this.hasChair = {
                  status: true,
                  idSeat: canJoinElement.attrs._id,
                  parent: canJoinElement.attrs.meetingZone,
                };
              }
            }
            if (
              canJoinElement &&
              canJoinElement.attrs.id == 'HUBINAR' &&
              !canJoinElement.attrs.webinar.isExtern
            ) {
              this.currentMeeting = canJoinElement;
              this.hasCurrentMeeting = true;
              this.hubinarData.isHubinarMode = true;
              this.hubinar = canJoinElement.attrs.webinar as Webinar;
              this.hubinarData.isModerator = (
                canJoinElement.attrs.webinar as Webinar
              ).guestList.find(
                (canJoin) =>
                  canJoin.user.email === this.user.email &&
                  canJoin.role === 'MODERATOR'
              )
                ? true
                : false;

              this.hubinarData.isContributor = (
                canJoinElement.attrs.webinar as Webinar
              ).guestList.find(
                (canJoin) =>
                  canJoin.user.email === this.user.email &&
                  canJoin.role === 'CONTRIBUTOR'
              )
                ? true
                : false;
              if (
                !this.hubinarData.isContributor &&
                !this.hubinarData.isModerator
              )
                this.isInvite = true;
              // console.log('status: ', (this.hubinar as Webinar).isStarted);

              if (this.hubinarData.isModerator) this.hubinarData.sidebar = true;
              //@ts-ignore
              if ((this.hubinar as Webinar).status === 'inProgress') {
                this.hubinarData.isStarted = true;
                const idx = this.availableForJoin.findIndex(
                  (av) => av.attrs._id === this.hubinar._id
                );
                this.availableForJoin[idx].attrs.webinar.status = 'inProgress';
                this.joinRoom(
                  this.space._id + this.hubinar._id + this.event._id
                );
                if (
                  this.hubinarData.isContributor ||
                  this.hubinarData.isModerator
                ) {
                  this.canvaService.addPeopleInRoom(
                    this.space._id,
                    this.user._id,
                    this.user.profilePicture,
                    this.user.firstName + ' ' + this.user.lastName,
                    this.space._id + this.hubinar._id + this.event._id
                  );
                }
              }
              this.canvaService.addPeopleHubinar(
                this.space._id,
                (canJoinElement.attrs.webinar as Webinar)._id,
                {
                  ...this.user,
                  isModerator: this.hubinarData.isModerator,
                  isContributor: this.hubinarData.isContributor,
                  isCamera: this.hubinarData.isModerator ? true : false,
                  isMicrophone: this.hubinarData.isModerator ? true : false,
                  isSharescreen: this.hubinarData.isModerator ? true : false,
                }
              );
              this.joinRoomHubinar(
                this.space._id + this.hubinar._id + this.event._id
              );
              console.log('calculate position..');
              const targetAvatar = <Konva.Group>avatar;
              const pos = targetAvatar.getPosition();
              const abspos = targetAvatar.getAbsolutePosition();
              //adala be
              const zoneRouge = {
                x1: canJoinElement.x(),
                x2:
                  canJoinElement.x() +
                  canJoinElement.width() * canJoinElement.scaleX(),
                y1: canJoinElement.y(),
                y2: canJoinElement.y() + 50 * canJoinElement.scaleY(),
              };

              if (
                positon.x > zoneRouge.x1 &&
                positon.x < zoneRouge.x2 &&
                positon.y > zoneRouge.y1 &&
                positon.y < zoneRouge.y2
              ) {
                const zoneVerteY = {
                  y1: canJoinElement.y() + 55 * canJoinElement.scaleY(),
                  y2:
                    canJoinElement.y() +
                    canJoinElement.height() * canJoinElement.scaleY() -
                    25,
                };
                const randomY = this.randomIntFromInterval(
                  zoneVerteY.y1,
                  zoneVerteY.y2
                );
                targetAvatar.setPosition({
                  x: targetAvatar.position().x,
                  y: randomY,
                });
              }
            }
            if (
              (this.joinIn && !canJoinElement) ||
              (canJoinElement && canJoinElement.attrs.hasSeat && this.joinIn)
            ) {
              if (this.welcomeSong) this.welcomeSong.pause();
              if (this.hubinarData.isHubinarMode) {
                this.exitHubinar();
              }
              if (this.space.activeCameraPastille) {
                this.mediasoupService.joinRoom(
                  this.user.firstName + this.user.lastName,
                  this.space._id + this.event._id + this.space._id,
                  this.canvaService.socket,
                  this.user,
                  true
                );
                setTimeout(() => {
                  if (this.cameraStatus) {
                    this.addVideoInAvatar(this.user);
                  }
                }, 500);
              }
              this.joinIn = false;
              if (this.currentMeeting && this.currentMeeting.attrs.feedback) {
                // this.eventService.changeCurrentRect(undefined);
                this.getFeedback();

                this.leaving = this.modalService.show(this.leaveModal, {
                  ignoreBackdropClick: true,
                  class: 'to-b',
                });
              } else {
                if (
                  this.currentMeeting &&
                  this.currentMeeting.attrs.id == 'SEAT'
                ) {
                  this.leaveSeat();
                  this.canvaService.removePeopleInRoom(
                    this.space._id,
                    this.user._id,
                    this.mediasoupService.roomId
                  );
                  this.hasChair = {
                    status: false,
                    idSeat: undefined,
                    parent: undefined,
                  };
                }
                this.leaveRoom();
              }
            }
            this.currentPossitionOnMeet = false;
            if (
              this.currentMeeting &&
              this.currentMeeting.attrs._id == canJoinElement?.attrs._id
            ) {
              this.currentPossitionOnMeet = true;
              console.log('same');
            } else if (
              canJoinElement &&
              (canJoinElement.attrs.id == 'REDIRECT' ||
                canJoinElement.attrs.id == 'REDIRECT-POLYGON')
            ) {
              // this.$changeSpace.next(canJoinElement.attrs.link);
              this.changeSpace.emit(canJoinElement.attrs.link);
            }
            // else if (
            //   canJoinElement &&
            //   event.target.getChildren()[0].attrs.connectedOn !==
            //     canJoinElement.attrs._id &&
            //   !this.hubinarZones.find(
            //     (e) =>
            //       e.attrs._id ===
            //       event.target.getChildren()[0].attrs.connectedOn
            //   )
            // ) {
            //   this.currentPossitionOnMeet = true;
            //   if (canJoinElement.attrs.id == 'SEAT') {
            //     if (canJoinElement.attrs.occupied) {
            //       this.toastr.error(
            //         'Vous ne pouvez pas vous asseoir ici!',
            //         'Chaise occupée'
            //       );
            //     } else {
            //       this.canTo = canJoinElement;
            //       this.getFeedback();
            //       this.joinRoom(this.space._id + canJoinElement.attrs._id);
            //       this.canvaService.onSeating(
            //         this.event._id,
            //         this.space._id,
            //         canJoinElement.attrs._id,
            //         [canJoinElement.attrs.meetingZone, true]
            //       );
            //       this.hasChair = {
            //         status: true,
            //         idSeat: canJoinElement.attrs._id,
            //         parent: canJoinElement.attrs.meetingZone,
            //       };
            //     }
            //   } else {
            //     this.canTo = canJoinElement;
            //   }
            // }
            this.tooltip.hide();
            this.tooltipLayer.draw();
          });
          this.addMicrophoneOnAvatar(this.user, avatar);
          avatar.zIndex(7);
          avatar.setZIndex(7);
          avatar.moveToTop();
          this.layer.add(avatar);
          const i = this.userOnSpace.findIndex(
            (p) => p.attrs._id === avatar.attrs._id
          );
          if (i === -1) this.userOnSpace.push(avatar);
          this.layer.draw();
          resolve(avatar);
        };
        img.onerror = () => {
          img.referrerPolicy = 'no-referrer';
          img.src = `https://eu.ui-avatars.com/api/?name=${user.firstName}+${user.lastName}`;
        };
        img.referrerPolicy = 'no-referrer';
        img.src = user.profilePicture
          ? user.profilePicture
          : `https://eu.ui-avatars.com/api/?name=${user.firstName}+${user.lastName}`;
      });
    }
  }

  addRectangleDoor(element: SpaceElement) {
    console.log('element on clickable zone ********* ', element);
    let values = element.values;
    console.log('values on clickable zone ************ ', values);
    const hideImage: boolean =
      Boolean(element.hideImage) === false ? false : true;
    let space = this.event.spaces.find((s) => s._id === values[0]);
    let imgName = '';
    if (!space) {
      space = this.event.spaces[0];
    }
    const timestamp = new Date(space.OpeningDate).getTime() / 1000;
    const now = new Date().getTime() / 1000;
    if (timestamp < now && space.close) {
      const idx = this.event.spaces.findIndex(
        (space) => space._id === space._id
      );
      this.event.spaces[idx].close = false;
      space.close = false;
      this.canvaService.update(this.event, 'close', space, 'space');
    }
    if (space.isPrivate && space.close && timestamp > now) {
      imgName = 'assets/img/icons8-lock-128.png';
    } else if (
      (space.isPrivate && !space.close) ||
      (space.isPrivate && space.close && timestamp < now)
    ) {
      imgName = 'assets/img/unlock.ico';
    }
    const imgLocked = new Image();
    imgLocked.onload = () => {
      var locked = new Konva.Image({
        x: element.args[0] ? element.args[0] : 60,
        y: element.args[1] ? element.args[1] : 60,
        width: 40,
        height: 40,
        image: imgLocked,
        fillPatternRepeat: 'no-repeat',
        fillPatternOffset: { x: 0, y: 0 },
        fillPatternScale: { x: 100 / img.naturalWidth, y: 100 / img.height },
        scaleX: 1,
        scaleY: 1,
      });

      if (
        space.moderator.includes(this.user._id as string & User) ||
        this.event.owner === this.user._id
      ) {
        locked.setAttr('opacity', 1);
      } else {
        locked.setAttr('opacity', 0);
      }
      locked.on('click', (e) => {
        if (
          (space.moderator.includes(this.user._id as string & User) ||
            this.event.owner === this.user._id) &&
          space.isPrivate &&
          space.close &&
          timestamp > now
        ) {
          this.spaceOnModal = space;
          this.opening = this.modalService.show(this.openingSpace, {
            ignoreBackdropClick: true,
            class: 'to-center',
          });

          this.canvaService.$unlockDoor.subscribe((unlock) => {
            console.log('unlock: ', unlock);
            imgName = 'assets/img/unlock.ico';
            imgLocked.src = imgName;
            this.toastr.info(
              'Vous avez ouvert la porte ' + this.spaceOnModal.title,
              'Ouverture porte'
            );
          });
        }
      });
      locked.setZIndex(2);
      this.layer.add(locked);
    };
    imgLocked.referrerPolicy = 'no-referrer';
    imgLocked.src = imgName;
    const img = new Image();
    img.referrerPolicy = 'no-referrer';
    img.src = typeof values[2] != 'undefined' ? values[2] : '';
    let rect;
    if (values && hideImage === false) {
      if (
        typeof values[2] != 'undefined' &&
        values[2] != '' &&
        element.imageOrColor === 'Mettre une image'
      ) {
        rect = new Konva.Image({
          x: element.args[0] ? element.args[0] : 60,
          y: element.args[1] ? element.args[1] : 60,
          width: 100,
          height: 100,
          image: img,
          fillPatternRepeat: 'no-repeat',
          fillPatternOffset: { x: 0, y: 0 },
          fillPatternScale: { x: 100 / img.naturalWidth, y: 100 / img.height },
          scaleX: element.args[2] ? element.args[2] : 1,
          scaleY: element.args[3] ? element.args[3] : 1,
          opacity: 0.08, // this.eventService.isMobile ? 0.7 : 0.08,
          name: 'rect',
          draggable: false,
          id: 'REDIRECT',
          _id: element._id ? element._id : new ObjectID().toString(),
          link: values[0] ? values[0] : this.space._id,
          linkTitle: values[1] ? values[1] : this.space.title,
          hideImage: hideImage === false ? hideImage : true,
        });
      } else {
        rect = new Konva.Rect({
          x: element.args[0] ? element.args[0] : 60,
          y: element.args[1] ? element.args[1] : 60,
          width: 100,
          height: 100,
          scaleX: element.args[2] ? element.args[2] : 1,
          scaleY: element.args[3] ? element.args[3] : 1,
          fill: element.bgColor
            ? element.bgColor
            : this.config.clickableZoneColor,
          opacity: this.canvaService.isMobile ? 0.7 : 0.08,
          name: 'rect',
          draggable: false,
          id: 'REDIRECT',
          _id: element._id ? element._id : new ObjectID().toString(),
          link: values[0] ? values[0] : this.space._id,
          linkTitle: values[1] ? values[1] : this.space.title,
          hideImage: hideImage === false ? hideImage : true,
        });
      }
    } else {
      rect = new Konva.Rect({
        x: element.args[0] ? element.args[0] : 60,
        y: element.args[1] ? element.args[1] : 60,
        width: 100,
        height: 100,
        scaleX: element.args[2] ? element.args[2] : 1,
        scaleY: element.args[3] ? element.args[3] : 1,
        fill: element.bgColor
          ? element.bgColor
          : this.config.clickableZoneColor,
        opacity: this.canvaService.isMobile ? 0.7 : 0.08,
        name: 'rect',
        draggable: false,
        id: 'REDIRECT',
        _id: element._id ? element._id : new ObjectID().toString(),
        link: values[0] ? values[0] : this.space._id,
        linkTitle: values[1] ? values[1] : this.space.title,
        hideImage: hideImage === false ? hideImage : true,
      });
    }

    if (
      this.canvaService.isMobile &&
      this.event.highlightResources &&
      this.event.highlightHubdoor
    ) {
      rect.setAttr('shadowColor', this.event.highlightHubdoorColor);
      rect.setAttr('shadowBlur', 15);
      rect.setAttr('shadowOffset', { x: 0, y: 0 });
      rect.setAttr('shadowOpacity', 1);
    }

    rect.on('mousemove', (e) => {
      if (rect.opacity() === 0.08) {
        rect.setAttr('opacity', 0.7);
      }

      const mousePos = this.stage.getPointerPosition();

      if (values) {
        if (typeof values[3] != 'undefined' && values[3] != '') {
          this.setToolTip(mousePos, values[3]);
        } else {
          this.setToolTip(mousePos, e.currentTarget.attrs.linkTitle);
        }
      }
      this.tooltip.show();
      this.tooltipLayer.batchDraw();
      this.layer.batchDraw();
    });

    rect.on('mouseout', (e) => {
      this.tooltip.hide();
      this.tooltipLayer.batchDraw();
      if (e.target.className === 'Image') {
        console.log('opacity remove');

        this.layer.children[
          this.layer.children
            .toArray()
            .findIndex((data) => data.attrs._id === e.target.attrs._id)
        ].setAttr('opacity', 0.08);
        this.layer.draw();
      }

      if (values && hideImage) {
        if (!this.eventService.isMobile) {
          rect.setAttr('opacity', 0.08);
        } else rect.setAttr('opacity', 1);
        if (typeof values[2] != 'undefined' && values[2] != '') {
          (e.target as any).fill(
            element.bgColor ? element.bgColor : this.config.clickableZoneColor
          );
          (e.target as any).fillPatternImage(null);
        }
        this.layer.draw();
      }

      this.layer.draw();
    });

    this.canvaService.enterHubdoorZone$.subscribe((data) => {
      if (data.id === rect.attrs._id && data.in === true) {
        if (!values[2]) {
          rect.setAttr('opacity', 0.7);
        }
        if (values && hideImage) {
          if (typeof values[2] != 'undefined' && values[2] != '') {
            rect.setAttr('opacity', 0.7);
            rect.fill(null);
            rect.fillPatternImage(img);
            rect.fillPatternRepeat('no-repeat');
            rect.fillPatternOffset({ x: 0, y: 0 });
            rect.fillPatternScale({
              x: 100 / img.naturalWidth,
              y: 100 / img.height,
            });
          }
        }
      } else if (data.id === rect.attrs._id && data.in === false) {
        if (rect.className === 'Image') {
          this.layer.children[
            this.layer.children
              .toArray()
              .findIndex((data) => data.attrs._id === rect.attrs._id)
          ].setAttr('opacity', 0.08);
        }

        if (values && hideImage) {
          if (!this.canvaService.isMobile) {
            rect.setAttr('opacity', 0.08);
          } else rect.setAttr('opacity', 1);
          if (typeof values[2] != 'undefined' && values[2] != '') {
            rect.fill(
              element.bgColor ? element.bgColor : this.config.clickableZoneColor
            );
            rect.fillPatternImage(null);
          }
        }

        this.layer.draw();
      }
    });
    rect.on('mouseover', (e) => {
      if (!values[2]) {
        rect.setAttr('opacity', 0.7);
      }
      if (space.isPrivate) {
        const img3 = new Image();
        img3.referrerPolicy = 'no-referrer';
        if (
          space.close &&
          !space.moderator.includes(this.user._id as string & User) &&
          this.event.owner != this.user._id
        ) {
          console.log('ssssss: ', space);

          if (values && hideImage) {
            rect.setAttr('opacity', 0.7);
            img3.src = 'assets/img/icons8-lock-128.png';
          }
        } else if (
          !space.close &&
          !space.moderator.includes(this.user._id as string & User) &&
          this.event.owner != this.user._id
        ) {
          img3.src = 'assets/img/unlock.ico';
        }
        (e.target as any).fill(null);
        (e.target as any).fillPatternImage(img3);
        (e.target as any).fillPatternRepeat('no-repeat');
        (e.target as any).fillPatternOffset({ x: 0, y: 0 });
        (e.target as any).fillPatternScale({
          x: 100 / img3.naturalWidth,
          y: 100 / img3.height,
        });

        if (
          space.moderator.includes(this.user._id as string & User) ||
          this.event.owner === this.user._id
        ) {
          console.log('ato ary hoe');

          if (values && hideImage) {
            if (typeof values[2] != 'undefined' && values[2] != '') {
              rect.setAttr('opacity', 0.7);
              (e.target as any).fill(null);
              (e.target as any).fillPatternImage(img);
              (e.target as any).fillPatternRepeat('no-repeat');
              (e.target as any).fillPatternOffset({ x: 0, y: 0 });
              (e.target as any).fillPatternScale({
                x: 100 / img.naturalWidth,
                y: 100 / img.height,
              });
            }
          }
        }
      }
      if (!space.isPrivate) {
        if (values && hideImage) {
          if (typeof values[2] != 'undefined' && values[2] != '') {
            rect.setAttr('opacity', 0.7);
            (e.target as any).fill(null);
            (e.target as any).fillPatternImage(img);
            (e.target as any).fillPatternRepeat('no-repeat');
            (e.target as any).fillPatternOffset({ x: 0, y: 0 });
            (e.target as any).fillPatternScale({
              x: 100 / img.naturalWidth,
              y: 100 / img.height,
            });
          }
        }
      }
    });
    rect.on('click tap', (e) => {
      if (this.currentPossitionOnMeet) {
        this.nextLinkHub = rect.attrs.link;
        this.confirmLeave(this.leaveMeetingModal);
      } else {
        // this.$changeSpace.next(String(rect.attrs.link));
        this.changeSpace.emit(String(rect.attrs.link));
      }
    });
    rect.zIndex(2);
    this.clickableZones.push(rect);

    this.layer.add(rect);
    this.layer.draw();
  }

  addMeetingZone(element: SpaceElement, hasSeat) {
    const rect = new Konva.Rect({
      x: element.args[0] ? element.args[0] : 60,
      y: element.args[1] ? element.args[1] : 60,
      width: 100,
      height: 100,
      scaleX: element.args[2] ? element.args[2] : 1,
      scaleY: element.args[3] ? element.args[3] : 1,
      fill: element.values[0]
        ? element.values[0]
        : this.config.meetingZoneColor,
      opacity: 0.7,
      name: 'rect',
      draggable: false,
      limited: false,
      nPeople: 0,
      values: element.values ? element.values : undefined,
      id: 'ZONE',
      intro: element.intro ? element.intro : [],
      feedback: element.feedback ? element.feedback : false,
      mosaic: element.mosaic ? element.mosaic : false,
      saveMeet: element.saveMeet ? element.saveMeet : false,
      moderatorMeet: element.moderatorMeet ? element.moderatorMeet : [],
      contentFeedback: element.contentFeedback ? element.contentFeedback : [],
      _id: element._id ? element._id : new ObjectID().toString(),
      linkMeet: element.linkMeet ? element.linkMeet : '',
      codeMeet: element.codeMeet ? element.codeMeet : '',
      isActiveWelcomeSound: element.isActiveWelcomeSound
        ? element.isActiveWelcomeSound
        : false,
      welcomeSound: element.welcomeSound ? element.welcomeSound : '',
      hasSeat: hasSeat ? true : false,
    });
    rect.zIndex(2);
    rect.hide();
    this.meetingZones.push(rect);
    this.layer.add(rect);
    this.layer.draw();
    this.canvaService.setCurrentMeeting$.subscribe((meetingId: string) => {
      if (rect.attrs._id === meetingId) {
        this.currentMeeting = rect;
        this.joinDirectMeet(meetingId);
      }
    });
  }

  addResource(element: SpaceElement) {
    if (!element.values[7] || new Date(element.values[8]) <= new Date()) {
      const img = new Image();
      img.onload = () => {
        var canvas = document.createElement('canvas');
        var $this = this;
        function onDrawFrame(ctx, frame) {
          canvas.width = frame.width;
          canvas.height = frame.height;
          canvas.style.zIndex = '1';
          ctx.drawImage(frame.buffer, 0, 0);
          $this.layer.draw();
        }
        (window as any).gifler(img.src).frames(canvas, onDrawFrame);
        const rect = new Konva.Image({
          image: canvas,
          x: element.args[0] ? element.args[0] : 60,
          y: element.args[1] ? element.args[1] : 60,
          width: 100,
          height: 100,
          scaleX: element.args[2] ? element.args[2] : 1,
          scaleY: element.args[3] ? element.args[3] : 1,
          fillPatternImage: img,
          fillPatternRepeat: 'no-repeat',
          fillPatternOffset: { x: 0, y: 0 },
          fillPatternScale: { x: 100 / img.naturalWidth, y: 100 / img.height },
          opacity: 1,
          name: 'rect',
          draggable: false,
          id: 'ICON',
          _id: element._id,
          values: element.values
            ? element.values
            : [
                '',
                '',
                '',
                false,
                [],
                '',
                '',
                false,
                new Date(),
                'Nouvel icône disponible!',
                false,
                new Date(),
              ],
          // TOOLTIP, ICON IMAGE, CONTENT, NEW_TAB, IMAGE_CAROUSEL, FONT, 7 = AVAILABILITY, 8 = heure de dispo, 9 = message de l'icone
          //10 = endDate (boolean), 11 = endDate (Date)
        });
        if (
          this.canvaService.isMobile &&
          img.src.includes('/transparent.png')
        ) {
          rect.setAttr('dash', [10, 10]);
          rect.setAttr('stroke', this.event.highlightIconColor);
        }

        if (
          this.canvaService.isMobile &&
          this.event.highlightResources &&
          this.event.highlightIcon
        ) {
          rect.setAttr('shadowColor', this.event.highlightIconColor);
          rect.setAttr('shadowBlur', 15);
          rect.setAttr('shadowOffset', { x: 0, y: 0 });
          rect.setAttr('shadowOpacity', 1);
        }
        rect.on('mousemove', (e) => {
          const mousePos = this.stage.getPointerPosition();
          if (e.currentTarget.attrs.values[0].length > 0) {
            this.setToolTip(
              mousePos,
              e.currentTarget.attrs.values[0],
              e.currentTarget.attrs.values[6]
            );
            this.tooltip.show();
            this.tooltipLayer.batchDraw();
          }
        });
        rect.on('click tap', (e) => {
          if (
            e.currentTarget.attrs.values[5] &&
            e.currentTarget.attrs.values[4]
          ) {
            let carouselItem: any[] = [];
            for (
              let index = 0;
              index < e.currentTarget.attrs.values[5].length;
              index++
            ) {
              const element = e.currentTarget.attrs.values[5][index];
              carouselItem.push(element);
            }

            this.carousel = carouselItem;
            this.modalRef = this.modalService.show(this.carouselModal, {
              class: 'modal-lg',
            });
          }

          if (
            e.currentTarget.attrs.values[2] &&
            e.currentTarget.attrs.values[2].length > 3 &&
            !e.currentTarget.attrs.values[4]
          ) {
            if (e.currentTarget.attrs.values[3] === true) {
              window.open(e.currentTarget.attrs.values[2], '_blank');
            } else {
              this.openModal.emit({
                type: 'iframe',
                value: e.currentTarget.attrs.values[2],
              });
            }
          }
        });
        rect.on('mouseout', () => {
          this.tooltip.hide();
          this.tooltipLayer.draw();
        });
        rect.zIndex(2);
        rect.setZIndex(2);
        rect.moveToBottom();
        this.icons.push(rect);
        this.layer.add(rect);
        this.layer.draw();
      };
      img.referrerPolicy = 'no-referrer';
      img.src =
        element.values && element.values[1] && element.values[1].length > 5
          ? element.values[1]
          : 'assets/img/transparent.png';
    }
  }

  addChair(element: SpaceElement) {
    const rect = new Konva.Rect({
      x: element.args[0] ? element.args[0] : 60,
      y: element.args[1] ? element.args[1] : 60,
      width: 100,
      height: 100,
      scaleX: element.args[2] ? element.args[2] : 1,
      scaleY: element.args[3] ? element.args[3] : 1,
      fill: element.values[2] ? element.values[2] : this.config.chairColor,
      opacity: 1,
      name: 'rect',
      draggable: false,
      id: 'SEAT',
      occupied: element.values[1] ? element.values[1] : false,
      meetingZone: element.values[0],
      _id: element._id ? element._id : new ObjectID().toString(),
      feedback: element.feedback ? element.feedback : false,
      saveMeet: element.saveMeet ? element.saveMeet : false,
      contentFeedback: element.contentFeedback ? element.contentFeedback : [],
      moderatorMeet: element.contentFeedback ? element.contentFeedback : [],
    });
    rect.zIndex(3);
    rect.hide();
    this.chairs.push(rect);
    this.layer.add(rect);
    this.layer.draw();
    // const zone = this.meetingZones.find((e) => e.attrs._id === rect.attrs.meetingZone);
    // if (zone) zone.setAttr('limited', true);
    this.calculateAvailableForJoin();
    this.canvaService.setCurrentMeeting$.subscribe((meetingId: string) => {
      if (rect.attrs._id === meetingId) {
        this.currentMeeting = rect;
      }
    });
  }

  addHubinarZone(element: SpaceElement) {
    var x = element.args[0];
    var y = element.args[1];
    var w = element.args[2];
    var h = element.args[3];
    var _id = element._id;
    var values = element.values;
    var webinar = element.webinar;
    console.log('webinar: ', webinar);

    const imgTransparent = new Image();
    imgTransparent.referrerPolicy = 'no-referrer';
    imgTransparent.src =
      values && values[1] && values[1].length > 5
        ? values[1]
        : 'assets/img/transparent.png';
    imgTransparent.onload = () => {
      const rect = new Konva.Rect({
        x: x ? x : 60,
        y: y ? y : 60,
        width: 100,
        height: 100,
        scaleX: w ? w : 2,
        scaleY: h ? h : 2,
        fillPatternScale: {
          x: 100 / imgTransparent.naturalWidth,
          y: 100 / imgTransparent.height,
        },
        opacity: 1,
        name: 'rect',
        draggable: false,
        id: 'HUBINAR',
        _id: _id ? _id : new ObjectID().toString(),
        webinar: webinar ? webinar : new Webinar(),
        values: values
          ? values
          : [
              '',
              '',
              '',
              '',
              true,
              new Date(),
              new Date(),
              /*this.event._id,*/ this.space._id,
              'Mettre une image',
              this.config.hubinarZoneColor,
            ],
        // values: values ? values : ['', '', '', '', true, new Date(), new Date(), this.event._id, this.space._id],
        // 0=TITLE, 1=IMAGE, 2=FONT, 3=URL, 4=NEW_TAB, 5=START DATE, 6=END DATE, 7=ID EVENT, 8=ID SPACE, 9=fond couleur/image, 10=couleur de fond
      });

      if (
        !rect.attrs.values[9] ||
        rect.attrs.values[9] === 'Mettre une image'
      ) {
        rect.attrs.fillPatternImage = imgTransparent;
        rect.attrs.fillPatternRepeat = 'no-repeat';
        rect.attrs.fillPatternOffset = { x: 0, y: 0 };
      } else if (values[9] === 'Mettre un couleur') {
        rect.attrs.fill = rect.attrs.values[10]
          ? rect.attrs.values[10]
          : this.config.hubinarZoneColor;
      }

      rect.attrs.webinar._id = rect.attrs._id;
      rect.attrs.webinar.idEvent = rect.attrs.webinar.idEvent
        ? rect.attrs.webinar.idEvent
        : this.event._id;
      rect.attrs.webinar.idSpace = rect.attrs.webinar.idSpace
        ? rect.attrs.webinar.idSpace
        : this.space._id;
      rect.attrs.webinar.idZone = rect.attrs.webinar.idZone
        ? rect.attrs.webinar.idZone
        : rect.attrs._id;

      if (
        this.canvaService.isMobile &&
        this.event.highlightResources &&
        this.event.highlightHubinar &&
        rect.attrs.webinar.isExtern == true
      ) {
        rect.setAttr('shadowColor', this.event.highlightHubinarColor);
        rect.setAttr('shadowBlur', 15);
        rect.setAttr('shadowOffset', { x: 0, y: 0 });
        rect.setAttr('shadowOpacity', 1);
      }

      rect.on('click tap', () => {
        if (rect.attrs.webinar.isExtern) {
          if (rect.attrs.values[2] && rect.attrs.values[2].length > 3) {
            rect.attrs.values[4]
              ? window.open(rect.attrs.values[2], '_blank')
              : window.open(rect.attrs.values[2]);
            console.log('clicked hubinar');
            if (!rect.attrs.webinar.nbClicks) {
              rect.attrs.webinar.nbClicks = 0;
            }
            rect.attrs.webinar.nbClicks = rect.attrs.webinar.nbClicks + 1;
            this.canvaService.socket.emit('editSpace', {
              spaceId: this.space._id,
              key: 'increaseClicks',
              value: { webinar: rect.attrs.webinar },
              type: 'hubinar',
              eventId: this.event._id,
            });
            this.canvaService.socket.emit('saveEditSpace', {
              spaceId: this.space._id,
              key: 'increaseClicks',
              value: { webinar: rect.attrs.webinar },
              type: 'hubinar',
            });
            this.canvaService.$increaseClicks.next({
              id: rect.attrs._id,
              nbClicks: rect.attrs.webinar.nbClicks,
            });

            this.updateNbClicks(
              rect.attrs._id + '_clicks',
              rect.attrs.webinar.nbClicks
            );
          }
        } else {
          console.log('hubinar intern detected');
        }
      });

      rect.on('mousemove', (e) => {
        const mousePos = this.stage.getPointerPosition();
        if (
          typeof e.currentTarget.attrs.values[0] != 'undefined' &&
          e.currentTarget.attrs.values[0].length > 0
        ) {
          this.setToolTip(
            mousePos,
            e.currentTarget.attrs.values[0],
            e.currentTarget.attrs.values[3]
          );
          this.tooltip.show();
          this.tooltipLayer.batchDraw();
        }
      });
      rect.on('mouseout', () => {
        this.tooltip.hide();
        this.tooltipLayer.draw();
      });
      // if (!rect.attrs.webinar.isExtern) {
      this.availableForJoin.push(rect);
      // }

      // simple label
      var simpleLabel = new Konva.Label({
        x: x ? x : 60,
        y: y ? y : 60,
        opacity: 0.75,
      });
      simpleLabel.add(
        new Konva.Tag({
          fill: 'transparent',
        })
      );

      if (webinar.isExtern) {
        var text = new Konva.Text({
          x: x ? x + w : 60 + w,
          y: y ? y : 60,
          // text: '',
          text: '👤 ' + rect.attrs.webinar.nbClicks,
          fontSize: 20,
          fontFamily: 'Calibri',
          fill: 'white',
          width: rect.width() * rect.scaleX(),
          align: 'right',
        });
        simpleLabel.add(text);
        this.canvaService.$increaseClicks.subscribe((dataClicked) => {
          if (dataClicked?.id === rect.attrs._id) {
            text.setAttr('text', '👤 ' + dataClicked.nbClicks);
            this.layer.draw();
          }
        });
        this.canvaService.socket.on('increaseClicks', (cnt) => {
          if (cnt.id === rect.attrs._id) {
            rect.attrs.webinar.nbClicks = rect.attrs.webinar.nbClicks + 1;
            this.updateNbClicks(
              rect.attrs._id + '_clicks',
              rect.attrs.webinar.nbClicks
            );
            text.setAttr('text', '👤 ' + cnt.nbClicks);
            this.layer.draw();
          }
        });
      } else {
        var text = new Konva.Text({
          x: x ? x + w : 60 + w,
          y: y ? y : 60,
          text: 'BETA',
          fontSize: 30,
          fontFamily: 'Calibri',
          fill: 'blue',
          width: rect.width() * rect.scaleX(),
          align: 'right',
        });
        simpleLabel.add(text);
      }

      //hubinar indication
      var indication = new Konva.Label({
        x: x ? x : 60,
        y: y ? y : 60,
        opacity: 0.75,
        _id: rect.attrs._id,
        type: 'indication',
      });

      var tag = new Konva.Tag({
        fill: 'red',
      });

      indication.add(tag);

      var indicationText = new Konva.Text({
        x: x ? x : 60,
        y: y ? y : 60,
        // text: 'En cours...',
        fontSize: 20,
        fontFamily: 'Trebuchet MS',
        fill: 'white',
        _id: rect.attrs._id,
        type: 'indication',
      });
      //adala kely
      // Set the date we're counting down to
      var countDownDate = new Date(values[5]).getTime();
      console.log('countdowndate: ', countDownDate);

      var leftTime: string = '';
      var interHub = setInterval(() => {
        var now = new Date().getTime();
        var distance = countDownDate - now;
        var days = Math.floor(distance / (1000 * 60 * 60 * 24));
        var hours = Math.floor(
          (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
        );
        var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        var seconds = Math.floor((distance % (1000 * 60)) / 1000);
        // leftTime = days + ' : ' + hours + ' : ' + minutes + ' : ' + seconds;
        leftTime =
          days + 'j ' + hours + 'h ' + minutes + 'min ' + seconds + 's';

        if ((webinar as any).status === 'notStarted' && !webinar.isExtern) {
          if (seconds > 0) {
            indicationText.setAttr('text', 'Start : \n' + leftTime);
            tag.setAttr('fill', 'green');
          } else {
            indicationText.setAttr('text', 'Términé');
            tag.setAttr('fill', 'red');
          }
          indication.draw();
        }
        if ((webinar as any).status === 'finished' && !webinar.isExtern) {
          clearInterval(interHub);
          indicationText.setAttr('text', 'Terminé');
          tag.setAttr('fill', 'red');
        } else if (
          (webinar as any).status === 'inProgress' &&
          !webinar.isExtern
        ) {
          clearInterval(interHub);
          indicationText.setAttr('text', 'En cours...');
          tag.setAttr('fill', 'orange');
        }
        this.layer.draw();
        // if ((webinar as any).status === 'finished' && !webinar.isExtern) {
        //   indicationText.setAttr('text', 'Terminé');
        //   tag.setAttr('fill', 'red');
        // } else if (
        //   (webinar as any).status === 'inProgress' &&
        //   !webinar.isExtern
        // ) {
        //   indicationText.setAttr('text', 'En cours...');
        //   tag.setAttr('fill', 'orange');
        // }
        // this.layer.draw();
        if (distance < 0) {
          clearInterval(interHub);
          if ((webinar as any).status === 'finished' && !webinar.isExtern) {
            indicationText.setAttr('text', 'Terminé');
            tag.setAttr('fill', 'red');
          } else if (
            (webinar as any).status === 'inProgress' &&
            !webinar.isExtern
          ) {
            indicationText.setAttr('text', 'En cours...');
            tag.setAttr('fill', 'orange');
          }
          this.layer.draw();
        }
      }, 1000);

      // if (!webinar.isExtern) {
      switch (this.getHubinarStatus(values[5], values[6])) {
        case 'notStarted':
          if ((webinar as any).status === 'notStarted' && !webinar.isExtern) {
            indicationText.setAttr('text', 'Start : \n' + leftTime);
            tag.setAttr('fill', 'green');
          }
          break;
        case 'current':
          if (!webinar.isExtern) {
            indicationText.setAttr('text', 'En cours...');
            tag.setAttr('fill', 'orange');
          }
          break;
        case 'finished':
          if (!webinar.isExtern) {
            indicationText.setAttr('text', 'Terminé');
            tag.setAttr('fill', 'red');
          }
          break;

        default:
          break;
      }
      // }
      indication.add(indicationText);
      this.canvaService.$hubinarStatus.subscribe((data) => {
        if (data?.id === rect.attrs._id) {
          switch (data.status) {
            case 'inProgress':
              indicationText.setAttr('text', 'En cours...');
              tag.setAttr('fill', 'orange');

              this.layer.draw();
              break;
            case 'finished':
              indicationText.setAttr('text', 'Terminé');
              tag.setAttr('fill', 'red');
              this.layer.draw();
              break;
            case 'notStarted':
              indicationText.setAttr('text', 'Start : \n');
              this.layer.draw();
              break;

            default:
              break;
          }
        }
      });
      rect.zIndex(2);
      this.hubinarZones.push(rect);
      this.layer.add(rect);
      this.layer.add(simpleLabel);
      this.layer.add(indication);
      this.layer.draw();
      this.canvaService.setCurrentMeeting$.subscribe((meetingId: string) => {
        if (rect.attrs._id === meetingId) {
          this.currentMeeting = rect;
        }
      });
    };
  }

  private getHubinarStatus(
    startDate,
    endDate
  ): 'notStarted' | 'current' | 'finished' {
    const start = new Date(startDate).getTime();
    const end = new Date(endDate).getTime();
    const naw = new Date().getTime();
    if (naw > start && naw < end) {
      return 'current';
    } else if (naw < start) {
      return 'notStarted';
    } else if (naw > end) {
      return 'finished';
    }
    return 'notStarted';
  }

  setToolTip(mousePos, text, font?) {
    this.tooltip.position({
      x: mousePos.x / this.config.scale,
      y: mousePos.y / this.config.scale - 10,
    });
    if (font) {
      // @ts-ignore
      this.tooltip.children[1].fontFamily(font);
    }
    // @ts-ignore
    if (this.tooltip.children[1]) this.tooltip.children[1].text(text);
  }

  async addRedirectPolygon(element: SpaceElement) {
    console.log('polygone ********** ', element);
    var polygon = new Konva.Line({
      points: element.points,
      fill: element.bgColor ? element.bgColor : '#ff000088',
      bgColor: element.bgColor ? element.bgColor : '#ff000088',
      stroke: element.bgColor ? element.bgColor : '#ff000088',
      strokeWidth: 1,
      draggable: false,
      closed: true,
      dash: [],
      x: 0,
      y: 0,
      opacity: this.canvaService.isMobile ? 0.7 : 0.08,
      _id: element._id ? element._id : new ObjectID().toString(),
      link: element.link ? element.link : this.space._id,
      linkTitle:
        element.linkTitle !== undefined ? element.linkTitle : this.space.title,
      id: 'REDIRECT-POLYGON',
      // name: 'rect',
    });
    polygon.on('mousemove', (e) => {
      const mousePos = this.stage.getPointerPosition();
      this.setToolTip(mousePos, e.currentTarget.attrs.linkTitle);
      this.tooltip.show();
      this.tooltipLayer.batchDraw();
    });
    polygon.on('mouseover', (e) => {
      polygon.opacity(0.7);
      this.layer.draw();
    });
    polygon.on('mouseout', (e) => {
      (e.target as any).opacity(0.08);
      this.layer.draw();
      this.tooltip.hide();
      this.tooltipLayer.draw();
    });

    this.canvaService.$enterPolygonZone.subscribe((data) => {
      if (data.id && data.id === polygon.attrs._id && data.in === true) {
        polygon.opacity(0.7);
        this.layer.draw();
      } else if (
        data.id &&
        data.id === polygon.attrs._id &&
        data.in === false &&
        !this.canvaService.isMobile
      ) {
        polygon.setAttr('opacity', 0.08);
        this.tooltip.hide();
        this.tooltipLayer.draw();
        this.layer.draw();
      }
    });
    polygon.on('click tap', (e) => {
      if (this.currentPossitionOnMeet) {
        this.nextLinkHub = polygon.attrs.link;
        this.confirmLeave(this.leaveMeetingModal);
      } else {
        // this.$changeSpace.next(polygon.attrs.link);
        this.changeSpace.emit(polygon.attrs.link);
      }
    });
    polygon.zIndex(2);
    this.clickableZones.push(polygon);

    this.layer.add(polygon);
    this.layer.draw();
  }

  //set badge for each user connected
  setBadge(user: User, avatar: Konva.Group) {
    if (this.event.badge.length > 0) {
      for (let badge of this.event.badge) {
        if (badge.user.indexOf(user.email) > -1) {
          if (badge != null) {
            var img = new Image();
            img.onload = () => {
              var badgeUser = new Konva.Image({
                image: img,
                fillPatternRepeat: 'no-repeat',
                fillPatternOffset: { x: 0, y: 0 },
                fillPatternScale: {
                  x: 100 / img.naturalWidth,
                  y: 100 / img.height,
                },
                scaleX: 1,
                scaleY: 1,
                _id: user._id,
                badge: badge,
              });
              badgeUser.on('mousemove', (e) => {
                const mousePos = this.stage.getPointerPosition();
                this.setToolTip(mousePos, e.target.attrs.badge.title);
                this.tooltip.show();
                this.tooltipLayer.batchDraw();
              });
              badgeUser.setZIndex(5);
              avatar.add(badgeUser);
              this.layer.draw();
            };
            img.src = badge.icon;
          }
        }
      }
    }
  }

  avatarDragBound(rect, pos) {
    const parentPos = {
      x1: 0 + rect.getChildren()[0].radius(),
      x2:
        this.stage.width() / this.stage.scaleX() -
        rect.getChildren()[0].radius(),
      y1: 0 + rect.getChildren()[0].radius(),
      y2:
        this.stage.height() / this.stage.scaleY() -
        rect.getChildren()[0].radius(),
    };
    const rectPos = {
      x1: pos.x / this.config.scale,
      x2: pos.x / this.config.scale,
      y1: pos.y / this.config.scale,
      y2: pos.y / this.config.scale,
      width: rect.width() * rect.scaleX(),
      height: rect.height() * rect.scaleY(),
    };
    if (parentPos) {
      if (rectPos.x1 < parentPos.x1) {
        pos.x = parentPos.x1 * this.config.scale;
      }
      if (rectPos.x2 > parentPos.x2) {
        pos.x = (parentPos.x2 - rectPos.width) * this.config.scale;
      }
      if (rectPos.y1 < parentPos.y1) {
        pos.y = parentPos.y1 * this.config.scale;
      }
      if (rectPos.y2 > parentPos.y2) {
        pos.y = (parentPos.y2 - rectPos.height) * this.config.scale;
      }
      return pos;
    } else {
      return pos;
    }
  }

  moveAvatar(user: Group) {
    this.canvaService.moveAvatar(this.event._id, this.space._id, [
      user.x(),
      user.y(),
      user.scaleX(),
      user.scaleY(),
    ]);
  }

  leaveSpace() {
    // this.$changeSpace.unsubscribe();
    // var paramsLeave = {
    //   eventId: this.event._id,
    //   spaceId: this.space._id,
    // };
    this.canvaService.socket.emit('changeStatusUser', {
      ...this.user,
      type: 'disconnected',
      eventId: this.event._id,
    });
    this.canvaService.leaveSpace(this.event._id, this.space._id);
    this.allSubscriptions.unsubscribe();
    // this.canvaService.socket.disconnect();
  }

  calculateAvailableForJoin() {
    this.availableForJoin = [];
    for (const chair of this.chairs) {
      this.availableForJoin.push(chair);
    }
    for (const zone of this.meetingZones) {
      if (!zone.attrs.limited) this.availableForJoin.push(zone);
    }
    for (const hubinarZone of this.hubinarZones) {
      this.availableForJoin.push(hubinarZone);
    }
    for (const porte of this.clickableZones) {
      this.availableForJoin.push(porte);
    }
  }

  randomIntFromInterval(min, max) {
    return Math.floor(Math.random() * (max - min + 1) + min);
  }

  confirmLeave(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template, {
      ignoreBackdropClick: true,
    });
  }

  closeModal() {
    this.modalRef.hide();
    // this.stop = false;
    this.nextLinkHub = '';
  }

  ngOnDestroy() {
    this.leaveSeat();
    this.leaveSpace();
    this.canvaService.removePeopleInRoom(
      this.space._id,
      this.user._id,
      this.mediasoupService.roomId
    );
    this.exitHubinar();
    this.closeVideo();
  }

  pauseVideo($event) {
    var video = document.getElementsByClassName('videoCarousel') as any;
    for (let v of video) {
      v.pause();
      v.onplay = () => {
        this.isPaused = false;
      };
      v.onpause = () => {
        this.isPaused = true;
      };
    }
  }

  playPause(i) {
    var video = document.getElementById(
      'videoCarousel' + i
    ) as HTMLVideoElement;
    video.play();
    this.isPaused = false;
  }

  yes() {
    // this.changeSpace.emit(this.nextLinkHub);
    this.currentPossitionOnMeet = false;
    this.modalRef.hide();
    this.currentUserAvatar.getChildren()[0].attrs.connectedOn = undefined;
    this.changeSpace.emit(this.nextLinkHub);
    // this.inMeeting = false;
  }

  getFeedback() {
    if (this.currentMeeting.attrs.feedback)
      this.feedbackService
        .getBySpaceId(this.space._id)
        .subscribe((res: any) => {
          console.log(res);

          this.currentFeedbacksSpace = res.filter(
            (e) => e._id === this.currentMeeting.attrs.contentFeedback[0]
          );
        });
  }

  joinRoom(roomid) {
    this.mediasoupService.joinRoom(
      this.user.lastName + ' ' + this.user.firstName,
      roomid,
      this.canvaService.socket,
      this.user,
      false
    );
  }

  joinRoomHubinar(roomid) {
    this.mediasoupService.joinRoom(
      this.user.lastName + ' ' + this.user.firstName,
      roomid,
      this.canvaService.socket,
      this.user,
      true
    );
  }

  leaveRoom() {
    this.currentMeeting = undefined;
    this.hasCurrentMeeting = false;
    if (this.hubinar) {
      this.canvaService.removePeopleHubinar(
        this.space._id,
        this.user,
        this.hubinar._id
      );
    }
    this.canvaService.removePeopleInRoom(
      this.space._id,
      this.user._id,
      this.mediasoupService.roomId
    );
    this.mediasoupService.leaveRoom();
  }

  toggleHubinarSettings() {
    this.hubinarData.sidebar = !this.hubinarData.sidebar;
  }

  public startHubinar() {
    this.canvaService.startHubinar(this.hubinar);
    if (this.hubinar) {
      this.hubinarData.isStarted = true;
      const idx = this.availableForJoin.findIndex(
        (av) => av.attrs._id === this.hubinar._id
      );
      this.availableForJoin[idx].attrs.webinar.status = 'inProgress';
    }
  }
  stopHubinar() {
    this.hubinarData.isStarted = false;
    if (this.hubinar) {
      this.hubinarData.isStarted = true;
      const idx = this.availableForJoin.findIndex(
        (av) => av.attrs._id === this.hubinar._id
      );
      this.availableForJoin[idx].attrs.webinar.status = 'finished';
    }
    this.canvaService.stopHubinar(this.hubinar);
  }

  exitHubinar() {
    if (this.hubinarData.isModerator) {
      const user = this.peopleInHubinars.find(
        (people) => people._id !== this.user._id && people.isModerator
      );
      if (!user && this.hubinarData.isStarted) {
        this.canvaService.leaveModerator(this.hubinar);
      }
    }
    this.leaveHubinar();
  }

  startRecord() {
    this.hubinarData.record.play = true;
    this.startingRecord.emit(true);
  }

  stopRecord() {
    this.hubinarData.record.play = false;
    this.hubinarData.record.paused = false;
    this.startingRecord.emit(false);
    this.canvaService.stopRecord(this.space._id, this.hubinar._id);
  }

  changeRole(userId, type) {
    this.canvaService.changeRoleHubinar(
      userId,
      type,
      this.hubinar._id,
      this.space._id
    );
  }

  changeCanToggle() {
    const user = this.peopleInHubinars.find(
      (people) => people._id === this.user._id
    );
    this.canToggle = {
      camera: user.isCamera,
      microphone: user.isMicrophone,
      screen: user.isSharescreen,
    };
  }

  disconnectSocket() {
    this.canvaService.disconnectSocket();
  }
  public joinDirectMeet(meetingId: string) {
    const canJoinElement = this.availableForJoin.find(
      (meet) => meet.attrs._id === meetingId
    );
    this.joinIn = true;
    this.joinRoom(this.space._id + canJoinElement.attrs._id);
    this.canvaService.addPeopleInRoom(
      this.space._id,
      this.user._id,
      this.user.profilePicture,
      this.user.firstName + ' ' + this.user.lastName,
      this.space._id + canJoinElement.attrs._id
    );
    if (
      !this.currentPossitionOnMeet &&
      canJoinElement.attrs.isActiveWelcomeSound &&
      canJoinElement.attrs.welcomeSound
    ) {
      this.welcomeSong = document.getElementById('welcome') as HTMLAudioElement;
      this.welcomeSong.src = canJoinElement.attrs.welcomeSound;
      this.welcomeSong.play();
    }
    // this.eventService.currentMeeting$.next(canJoinElement);
    // if (canJoinElement.attrs.moderatorMeet.findIndex((element) => element.email === this.user.email) > -1) {
    //   this.eventService.isModeratorMeet$.next(true);
    // }
  }

  addMicrophoneOnAvatar(user, avatar?: Group, callSocket = false) {
    if (avatar) {
      var imgMicro = new Image();
      imgMicro.onload = () => {
        var imgKonvaMicroMute = new Konva.Image({
          image: imgMicro,
          fillPatternRepeat: 'no-repeat',
          scaleX: 1,
          scaleY: 1,
          width: 35,
          height: 35,
          _id: avatar.attrs._id,
          x: -45,
          y: 10,
          title: 'microMute',
        });
        if (this.micStatus) {
          imgKonvaMicroMute.hide();
        } else if (avatar.attrs.microphone) {
          imgKonvaMicroMute.hide();
        } else {
          imgKonvaMicroMute.show();
        }
        avatar.add(imgKonvaMicroMute);
        this.layer.draw();
      };
      imgMicro.src = 'assets/img/mic-mute.png'; //: 'assets/img/micro-mute.png';
      imgMicro.referrerPolicy = 'no-referrer';
    } else {
      avatar = this.userOnSpace.find((av) => av.attrs._id == user._id);
      if (avatar) {
        var imgMicroMute = avatar
          .getChildren()
          .toArray()
          .find((e) => e.attrs.title == 'microMute') as Konva.Image;
        if (imgMicroMute) {
          imgMicroMute.show();
          this.layer.draw();
        }
      }
    }
    if (callSocket) this.canvaService.changestatusMicro(this.space._id, false);
  }

  removeMicrophoneOnAvatar(user, callSocket = false) {
    var avatar = this.userOnSpace.find((av) => av.attrs._id == user._id);
    if (avatar) {
      var imgMicroMute = avatar
        .getChildren()
        .toArray()
        .find((e) => e.attrs.title == 'microMute') as Konva.Image;
      if (imgMicroMute) {
        imgMicroMute.hide();
        this.layer.draw();
      }
      if (callSocket) this.canvaService.changestatusMicro(this.space._id, true);
    }
  }
  leaveHubinar() {
    this.leaveRoom();
    this.hubinarData = {
      isContributor: false,
      isHubinarMode: false,
      isModerator: false,
      isStarted: false,
      record: {
        paused: false,
        play: false,
      },
      sidebar: false,
    };
    this.canToggle = {
      camera: false,
      microphone: false,
      screen: false,
    };
    this.hubinar = undefined;
  }

  pauseRecordHubinar() {
    this.hubinarData.record.paused = true;
    this.pauseRecord.emit(true);
  }
  resumeRecordHubinar() {
    this.hubinarData.record.paused = false;
    this.pauseRecord.emit(false);
  }

  private updateNbClicks(nodeId, newNbClicks) {
    const target = document.getElementById(nodeId);
    if (target) {
      target.innerHTML = '';
      const nbClicksNode = document.createTextNode(newNbClicks);
      const picto2 = document.getElementById('fa_users').cloneNode(true);
      target.appendChild(picto2);
      target.appendChild(nbClicksNode);
    }
  }
  noOpen() {
    this.opening.hide();
  }

  yesOpen() {
    const idx = this.event.spaces.findIndex(
      (space) => space._id === this.spaceOnModal._id
    );
    this.event.spaces[idx].close = false;
    this.canvaService.update(this.event, 'close', this.spaceOnModal, 'space');
    this.canvaService.socket.emit('openDoor', {
      spaceId: this.spaceOnModal._id,
      spaceName: this.spaceOnModal.title,
      name: this.user.firstName,
      eventId: this.event._id,
    });
    this.canvaService.$unlockDoor.next(true);
    // this.eventService.changeImage(true);
    this.opening.hide();
  }

  async addVideoInAvatar(user: User) {
    if (user._id === this.user._id) {
      const video = document.createElement('video');
      const mediaConstraints = {
        audio: false,
        video: {
          width: {
            min: 640,
            ideal: 720,
            max: 720,
          },
          height: {
            min: 400,
            ideal: 480,
            max: 480,
          },
          frameRate: { max: 15 },
          aspectRatio: {
            ideal: 1.7777777778,
          },
        },
      };
      const stream = await navigator.mediaDevices.getUserMedia(
        mediaConstraints
      );

      video.srcObject = stream;
      video.autoplay = true;
      this.streamVideoAvatar = stream;
      this.videoAvatar = video;
      const avatar = this.currentUserAvatar.getChildren()[0];
      video.onloadeddata = async () => {
        avatar?.setAttr('fillPatternScale', {
          x: 100 / video.videoWidth,
          y: 100 / video.videoHeight,
        });
        avatar?.setAttr('fillPatternImage', video);
        avatar?.setAttr('scaleX', -1);
        this.animation = new Konva.Animation(function () {
          // do nothing, animation just need to update the layer
          avatar?.setAttr('fillPatternScale', {
            x: 100 / video.videoWidth,
            y: 100 / video.videoHeight,
          });
          // @ts-ignore
          avatar?.setAttr('fillPatternImage', video);
        }, this.layer);
        this.animation.start();
        this.layer.draw();
      };
    } else {
      const videoJoin = this.remoteVideos.find(
        (video) => video.user._id === user._id
      );
      if (videoJoin) {
        const videoElement = document.createElement('video');
        videoElement.autoplay = true;
        const avatarUser = this.userOnSpace.find(
          (usr) => usr.getChildren()[0].attrs?._id === user._id
        );
        const avatar = avatarUser?.getChildren()[0];
        videoElement.srcObject = videoJoin.src;
        videoElement.onloadeddata = async () => {
          avatar?.setAttr('fillPatternScale', {
            x: 100 / videoElement.videoWidth,
            y: 100 / videoElement.videoHeight,
          });
          avatar?.setAttr('fillPatternImage', videoElement);
          avatar?.setAttr('scaleX', -1);
          const animate = new Konva.Animation(function () {
            // do nothing, animation just need to update the layer
            avatar?.setAttr('fillPatternScale', {
              x: 100 / videoElement.videoWidth,
              y: 100 / videoElement.videoHeight,
            });
            // // @ts-ignore
            avatar?.setAttr('fillPatternImage', videoElement);
          }, this.layer);
          this.animations.push({
            _id: user._id,
            animation: animate,
          });
          const animToStart = this.animations.find((e) => e._id === user._id);
          if (animToStart) animToStart.animation.start();
          this.layer.draw();
        };
      }
    }
  }

  async removeVideoInAvatar(user) {
    if (user._id === this.user._id) {
      this.animation?.stop();
      this.closeVideo();
      const avatar = this.currentUserAvatar.getChildren()[0];
      avatar.setAttr('fillPatternImage', null);
      const img = new Image();
      img.onload = async () => {
        avatar.setAttr('fillPatternImage', img);
        avatar.setAttr('scaleX', 1);
        avatar.setAttr('fillPatternScale', {
          x: 100 / img.width,
          y: 100 / img.height,
        });
        this.layer.batchDraw();
      };
      img.onerror = () => {
        img.referrerPolicy = 'no-referrer';
        img.src = `https://eu.ui-avatars.com/api/?name=${user.firstName}+${user.lastName}`;
      };
      img.referrerPolicy = 'no-referrer';
      img.src = user.profilePicture
        ? user.profilePicture
        : `https://eu.ui-avatars.com/api/?name=${user.firstName}+${user.lastName}`;
    } else {
      const img = new Image();
      const avatarUser = this.userOnSpace.find(
        (usr) => usr.getChildren()[0].attrs?._id === user._id
      );
      const avatar = avatarUser.getChildren()[0];
      avatar.setAttr('fillPatternImage', null);

      img.onload = async () => {
        avatar.setAttr('fillPatternScale', {
          x: 100 / img.width,
          y: 100 / img.height,
        });
        avatar.setAttr('fillPatternImage', img);
        avatar.setAttr('scaleX', 1);
      };
      img.onerror = () => {
        img.referrerPolicy = 'no-referrer';
        img.src = `https://eu.ui-avatars.com/api/?name=${user.firstName}+${user.lastName}`;
      };
      img.referrerPolicy = 'no-referrer';
      img.src = user.profilePicture
        ? user.profilePicture
        : `https://eu.ui-avatars.com/api/?name=${user.firstName}+${user.lastName}`;

      const animToStart = this.animations.find((e) => e._id === user._id);
      if (animToStart) animToStart.animation.stop();
      this.layer.draw();
    }
  }

  closeVideo() {
    if (this.videoAvatar) {
      this.videoAvatar.pause();
      this.videoAvatar.removeAttribute('src');
      this.streamVideoAvatar.getTracks().forEach(function (track) {
        track.stop();
      });
    }
  }
  hasBadge(email): boolean {
    if (this.event.badge) {
      for (const badge of this.event.badge) {
        if (badge.user.includes(email)) {
          return true;
        }
      }
    }
    return false;
  }

  setIndex(index) {
    if (index != this.questionIndex) {
      this.questionIndex = index;
    } else {
      this.questionIndex = undefined;
    }
  }
  checkResponse(i, r) {
    return (
      this.allIndex.findIndex((e) => e.index === i && e.feedback === r) > -1
    );
  }

  sendResponse(r) {
    this.feedback = {
      name: '',
      question: [],
      spaceId: '',
      response: false,
    };
    this.feedback.name = this.currentFeedbacksSpace[0].name;
    this.feedback.spaceId = this.space._id;
    this.feedback.response = true;
    this.feedback.proprietaire = this.currentMeeting.attrs.id;
    this.feedback.date = new Date();
    this.feedback.user = [
      {
        userId: this.user._id,
        userFristname: this.user.firstName,
        userLastname: this.user.lastName,
        userProfil: this.user.profilePicture,
      },
    ];
    const res = [r];
    const test = this.allIndex.findIndex((e) => e.index === this.questionIndex);

    if (test > -1) {
      this.allIndex = this.allIndex.filter(
        (e) => e.index != this.questionIndex
      );
    }

    this.allIndex.push({
      index: this.questionIndex,
      feedback: r,
    });
    this.feedback.question.push({
      content: this.currentQuestion,
      response: res,
    });
    const existIn = this.feedbackToSend.findIndex(
      (e) => e.question[0].content === this.feedback.question[0].content
    );
    if (existIn === -1) {
      this.feedbackToSend.push(this.feedback);
    } else {
      this.feedbackToSend = this.feedbackToSend.filter(
        (e) => e.question[0].content != this.feedback.question[0].content
      );
      this.feedbackToSend.push(this.feedback);
    }
  }

  sendFeedback() {
    this.allIndex = [];
    if (this.feedbackToSend.length > 0) {
      for (let i = 0; i < this.feedbackToSend.length; i++) {
        const type = 'newFeedback';
        const value = this.feedbackToSend[i];
        this.canvaService.socket.emit('saveEditEvent', {
          eventId: this.event._id,
          value,
          type,
        });
      }
      this.leaving.hide();
      this.toastr.success("Merci d'avoir laisser votre avis!", 'Feedback');
      this.feedback = {
        name: '',
        question: [],
        spaceId: '',
        response: false,
      };
      this.questionIndex = undefined;
      this.feedbackToSend = [];
    } else {
      this.leaving.hide();
      this.toastr.warning("Vous n'avez selectionné aucun feedback", 'Feedback');
    }
    if (this.welcomeSong) this.welcomeSong.pause();
    this.leaveRoom();
  }
  closeFeedback() {
    this.leaving.hide();
    this.feedbackType = '';
    this.feedback = {
      name: '',
      question: [],
      spaceId: '',
      response: false,
    };
    this.questionIndex = undefined;
    if (this.welcomeSong) this.welcomeSong.pause();
    this.leaveRoom();
  }

  leaveSeat() {
    if (this.currentMeeting && this.currentMeeting.attrs.id === 'SEAT') {
      this.canvaService.leaveSeat(
        this.currentMeeting.attrs._id,
        this.space._id,
        this.currentMeeting.attrs.meetingZone
      );
    }
  }

  joinMeeting() {
    this.hasCurrentMeeting = true;
    this.canvaService.addPeopleInRoom(
      this.space._id,
      this.user._id,
      this.user.profilePicture,
      this.user.firstName + ' ' + this.user.lastName,
      this.space._id + this.currentMeeting.attrs._id
    );
    this.joinRoom(this.space._id + this.currentMeeting.attrs._id);

    if (
      !this.currentPossitionOnMeet &&
      this.currentMeeting.attrs.isActiveWelcomeSound &&
      this.currentMeeting.attrs.welcomeSound
    ) {
      this.welcomeSong = document.getElementById('welcome') as HTMLAudioElement;
      this.welcomeSong.src = this.currentMeeting.attrs.welcomeSound;
      this.welcomeSong.play();
    }
    this.modalRef.hide();
  }

  async addSharescreenZone(element) {
    const rect = new Konva.Rect({
      x: element.args[0] ? element.args[0] : 60,
      y: element.args[1] ? element.args[1] : 60,
      width: 100,
      height: 100,
      scaleX: element.args[2] ? element.args[2] : 1,
      scaleY: element.args[3] ? element.args[3] : 1,
      opacity: 1,
      name: 'rect',
      draggable: false,
      limited: false,
      stroke: this.config.screenColor,
      strokeWidth: 1,
      values: element.values ? element.values : undefined,
      id: 'SHARE',
      _id: element._id ? element._id : new ObjectID().toString(),
    });
    rect.on('dblclick', (e) => {
      console.log('double click');
      this.huge = this.hugeTmp;
    });
    rect.zIndex(3);
    this.shareZones.push(rect);
    this.layer.add(rect);
    this.layer.draw();
  }

  async addVideoInSharescreenZone(src) {
    this.hugeTmp = src;
    var video = document.createElement('video');
    video.srcObject = src;
    video.autoplay = true;
    const share = this.shareZones[0];
    video.onloadeddata = async () => {
      share.setAttr('fillPatternScale', {
        x: 100 / video.videoWidth,
        y: 100 / video.videoHeight,
      });
      share.setAttr('fillPatternImage', video);
      const animate = new Konva.Animation(function () {
        // do nothing, animation just need to update the layer
        share.setAttr('fillPatternScale', {
          x: 100 / video.videoWidth,
          y: 100 / video.videoHeight,
        });
        // // @ts-ignore
        share.setAttr('fillPatternImage', video);
      }, this.layer);
      this.animations.push({
        _id: 'share',
        animation: animate,
      });
      const animToStart = this.animations.find((e) => e._id === 'share');
      if (animToStart) animToStart.animation.start();
      this.layer.draw();
    };
  }

  sessionManager() {
    this.interval = setInterval(() => {
      if (!this.currentMeeting && !this.hubinarData.isHubinarMode) {
        if (
          this.event.autoDeconnect &&
          this.timer >=
            (this.event.timeActif ? this.event.timeActif : 5) * 60 &&
          !this.statusUser.inDisconnect
        ) {
          this.statusUser.inDisconnect = true;
          this.canvaService.socket.emit('changeStatusUser', {
            ...this.user,
            type: 'changing',
            eventId: this.event._id,
            status: 'inDisconnect',
          });
          this.toastr.info(
            `Vous êtes inactifs depuis ${
              this.event.timeActif ? this.event.timeActif : 5
            } minutes, vous allez être déconnecté(e).`
          );
        }
        if (
          this.event.autoDeconnect &&
          this.timer >=
            (this.event.timeDeconnexion ? this.event.timeDeconnexion : 15) *
              60 &&
          !this.statusUser.disconnected
        ) {
          this.statusUser.disconnected = true;
          if (this.event.autoDeconnect) {
            this.statusUser.noResponse = true;
            this.confirmLeaveDec(this.deconnexion);
            this.counterInterval = setInterval(() => {
              this.statusUser.counter--;
              if (this.statusUser.counter === 0 && this.statusUser.noResponse) {
                this.modalRef.hide();
                this.statusUser.counter = 10;
                clearInterval(this.counterInterval);
                this.canvaService.socket.emit('changeStatusUser', {
                  ...this.user,
                  type: 'disconnected',
                  eventId: this.event._id,
                });
                this.router.navigate(['public', 'landing', this.event._id]);
                this.toastr.info(
                  'Vous avez été déconnecté du hub',
                  'Information'
                );
                clearInterval(this.interval);
              }
            }, 1000);
          } else {
            this.toastr.info(
              `Vous ne bougez pas pendant ${
                this.event.timeDeconnexion ? this.event.timeDeconnexion : 15
              } min, vous êtes en mode deconnexion, l'admin pourra vous virer de l'évènement`
            );
            this.canvaService.socket.emit('changeStatusUser', {
              ...this.user,
              type: 'changing',
              eventId: this.event._id,
              status: 'disconnected',
              start: new Date(),
            });
          }
        }
        this.timer++;
      }
    }, 1000);
  }

  confirmLeaveDec(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template, {
      ignoreBackdropClick: true,
    });
  }

  stayConnected() {
    this.modalRef.hide();
    this.statusUser.noResponse = false;
    clearInterval(this.counterInterval);
    this.statusUser.counter = 10;
  }
  async delay(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  addPeople() {
    this.canvaService.addPeopleInRoom(
      this.space._id,
      this.user._id,
      this.user.profilePicture,
      this.user.lastName + ' ' + this.user.firstName,
      this.mediasoupService.roomId
    );
  }

  confirmMultiSession(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template, {
      ignoreBackdropClick: true,
      class: 'to-center',
    });
  }

  handleMultiSession(deconnect: boolean) {
    this.modalRef.hide();
    if (deconnect) {
      this.canvaService.socket.emit('multiSession', {
        idUser: this.user._id,
        idEvent: this.event._id,
      });
      window.location.reload();
    } else {
      this.authService.logout();
      this.router.navigate(['']);
    }
  }

  setAnimation(x, y, r) {
    // animation
    const pointer = new Image();
    pointer.onload = async () => {
      const containerPointer = new Konva.Group({
        x: x ? x : Math.floor(Math.random() * 401) + 100, //between 100 & 500
        y: y ? y : (Math.floor(Math.random() * 199) + 50) * 2, //between 50 & 250
        _id: 'animation',
        listening: true,
      });
      const mainPointer = new Konva.Circle({
        radius: r ? r : 40,
        drawBorder: true,
        fillPatternImage: pointer,
        fillPatternRepeat: 'no-repeat',
        fillPatternOffset: { x: r ? r : 50, y: r ? r : 50 },
        fillPatternScale: {
          x: (r ? 2 * r : 100) / pointer.width,
          y: (r ? 2 * r : 100) / pointer.height,
        },
        listening: true,
      });

      var amplitude = 100;
      var period = 2000;
      // in ms
      var centerX = this.stage.width() / 2;

      this.anim = new Konva.Animation(function (frame) {
        containerPointer.x(
          amplitude * Math.sin((frame.time * 2 * Math.PI) / period) + centerX
        );
      }, this.layer);

      this.anim.start();
      containerPointer.add(mainPointer);
      this.layer.add(containerPointer);
    };
    pointer.referrerPolicy = 'no-referrer';
    pointer.src = 'assets/img/pointer.jpg';
  }
}
