import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { JwBootstrapSwitchNg2Module } from 'jw-bootstrap-switch-ng2';
import { AvatarModule } from 'ngx-avatar';
import { AlertModule } from 'ngx-bootstrap/alert';
import { CarouselModule } from 'ngx-bootstrap/carousel';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { ModalModule } from 'ngx-bootstrap/modal';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { ProgressbarModule } from 'ngx-bootstrap/progressbar';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { NgxStripeModule } from 'ngx-stripe';
import { ToastrModule } from 'ngx-toastr';
import { NgxYoutubePlayerModule } from 'ngx-youtube-player';
import { SharedComponentsModule } from '../shared/components/shared-components.module';
import { CguComponent } from './examples/cgu/cgu.component';
import { FirstConnexionPageComponent } from './examples/first-connexion-page/first-connexion-page.component';
import { LandingpageComponent } from './examples/landingpage/landingpage.component';
import { LoginpageComponent } from './examples/loginpage/loginpage.component';
import { PasswordresetComponent } from './examples/passwordreset/passwordreset.component';
import { PricingComponent } from './examples/pricing/pricing.component';
import { ProfilepageComponent } from './examples/profilepage/profilepage.component';
import { RegisterpageComponent } from './examples/registerpage/registerpage.component';
import { SubscriptionComponent } from './examples/subscription/subscription.component';
import { FooterComponent } from './footer/footer.component';
import { HeadbarComponent } from './headbar/headbar.component';
import { IndexComponent } from './index/index.component';

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    FormsModule,
    RouterModule,
    BsDropdownModule.forRoot(),
    ProgressbarModule.forRoot(),
    TooltipModule.forRoot(),
    PopoverModule.forRoot(),
    CollapseModule.forRoot(),
    JwBootstrapSwitchNg2Module,
    TabsModule.forRoot(),
    PaginationModule.forRoot(),
    AlertModule.forRoot(),
    BsDatepickerModule.forRoot(),
    CarouselModule.forRoot(),
    ModalModule.forRoot(),
    TranslateModule,
    ToastrModule.forRoot({
      preventDuplicates: true,
    }),
    AvatarModule,
    NgxStripeModule.forRoot('pk_test_51ISnGrC98Pgq3f67XGRF1dIzVa2MdxVXIYD0dJUbC03G6UYOzhutncWKRuskgCJGUybVoLtlAQCYi30NFMTVFrvn00jITgQ3Jv'),
    NgxYoutubePlayerModule,
    SharedComponentsModule,
    MatProgressSpinnerModule,
  ],
  declarations: [
    IndexComponent,
    ProfilepageComponent,
    RegisterpageComponent,
    LandingpageComponent,
    HeadbarComponent,
    FooterComponent,
    LoginpageComponent,
    CguComponent,
    PasswordresetComponent,
    PricingComponent,
    SubscriptionComponent,
    FirstConnexionPageComponent,
  ],
  exports: [IndexComponent, ProfilepageComponent, RegisterpageComponent, LandingpageComponent, HeadbarComponent, FooterComponent],
  providers: [],
})
export class PagesModule {}
