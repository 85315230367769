import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Socket } from 'ngx-socket-io';
import { ToastrService } from 'ngx-toastr';
import { Message } from 'src/app/interfaces/message';
import { Space, Event } from 'src/app/models/Event.model';
import { User } from 'src/app/models/User.model';
import { EventService } from 'src/app/services/event.service';
import { MediasoupService } from 'src/app/services/mediasoup.service';
import { UserService } from 'src/app/services/user.service';
import { MessagesService } from './../../../services/messages.service';
import { NgxLinkifyOptions } from 'ngx-linkifyjs';

const options: NgxLinkifyOptions = {
  attributes: null,
  className: 'linkified',
  defaultProtocol: 'https',
  events: null,
  format: function (value, type) {
    return value;
  },
  formatHref: function (href, type) {
    return href;
  },
  ignoreTags: [],
  nl2br: false,
  tagName: 'a',
  target: {
    url: '_blank',
  },
  validate: true,
};
interface newMessage {
  _id: string;
  profil: string;
  nb: number;
  firstName: string;
  lastName: string;
}

@Component({
  selector: 'app-messages',
  templateUrl: './messages.component.html',
  styleUrls: ['./messages.component.scss'],
})
export class MessagesComponent
  implements OnInit, AfterViewInit, OnChanges, OnDestroy
{
  @Input() currentSpace!: Space;
  @Input() user!: User;
  @Input() event!: Event;

  @Output() home = new EventEmitter();

  form!: FormGroup;
  messages: Message[] = [];

  currentMessages: Message[] = [];
  userList: any[] = [];

  hasData = true;

  messageType = 'SPACE';
  idTo!: string;
  idToShow!: string;

  activeTab = -1;
  public currentNumber: number = 0;
  public userEx: any[] = [];
  isEmojiPickerVisible = false;
  public status: boolean = true;
  public isList: boolean = false;
  public newMessages: newMessage[] = [
    {
      _id: '',
      profil: '',
      nb: 0,
      firstName: '',
      lastName: '',
    },
  ];
  public nbMessageSpace: number = 0;
  public nbMess: number = 0;
  public autoMention: boolean = false;
  public filtered: any[] = [];
  public search: string = '';
  public mention: string[] = [''];
  public usersJoin: any[] = [];
  public usersJoinTmp: any[] = [];
  public test: boolean = false;
  public showAllUsers: boolean = false;
  public toHide: any = null;
  public herMes: any;
  public chatWidth: number = 300;
  private eventResizeAdded: boolean = false;
  isCollaspe = false;
  public totalNumberMessage: number = 0;
  public isMeet;
  constructor(
    private socket: Socket,
    private fb: FormBuilder,
    private messageService: MessagesService,
    private toastr: ToastrService,
    private userService: UserService,
    private eventService: EventService,
    private mediasoupService: MediasoupService
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    if (typeof changes.currentSpace !== 'undefined') {
      this.idToShow = this.idTo;
      this.idTo = this.currentSpace._id;
      if (this.messageType === 'SPACE') {
        this.loadMessages();
      } else {
        this.currentMessage(
          this.idToShow,
          this.messageType,
          this.activeTab,
          this.herMes
        );
      }
    }
  }

  ngOnInit(): void {
    this.eventService.currentRect$.subscribe((rect) => {
      this.isMeet = rect;
    });
    this.newMessages = [];
    this.form = this.fb.group({
      message: ['', Validators.required],
    });
    this.messageService.joinUser$.subscribe((e) => {
      if (e._id) {
        const check = this.usersJoin.some((c) => c._id === e._id);
        if (!check) {
          this.usersJoin.push(e);
        }
        this.usersJoin = this.usersJoin.map((v) => {
          if (v._id === e._id) {
            return { ...v, nb: 0 };
          } else {
            return v;
          }
        });
      }
    });

    this.socket.on('peopleUpdate', async (data) => {
      if (data.type === 'newUserToSpace') {
        this.currentNumber = data.data.filter(
          (c) => c.eventId === this.event._id
        ).length;
      }
    });
    // this.messageService._idRemove$.subscribe((r) => {
    //   console.log('remove');

    //   if (r._id) {
    //     const index = this.usersJoin.findIndex((u) => u._id === r._id);
    //     this.usersJoin = this.usersJoin.filter((j) => {
    //       return j._id != r._id;
    //     });

    //     if (this.activeTab === index && this.usersJoin.length > 0) {
    //       this.currentMessage(this.usersJoin[index - 1]._id, 'USER', index - 1);
    //     } else {
    //       this.currentMessage(this.currentSpace._id, 'SPACE', -1);
    //     }
    //   }
    // });

    this.messageService.newMessage$.subscribe((message) => {
      if (message.from) {
        const join = {
          firstName: message.person.firstName,
          lastName: message.person.lastName,
          _id: message.person._id,
          createdAt: Date.now(),
        };
        const check = this.usersJoin.some((c) => c._id === join._id);
        if (!check) {
          this.usersJoin.unshift(join);
          this.usersJoin = this.usersJoin.map((v) => {
            if (v._id === join._id) {
              return { ...v, nb: 0 };
            } else {
              return v;
            }
          });
        } else {
          const idx = this.usersJoin.findIndex(
            (uJoin) => uJoin._id === join._id
          );
          if (idx !== -1) {
            this.usersJoin.splice(idx, 1);
            this.usersJoin.unshift(join);
          }
        }

        const index = this.usersJoin.findIndex(
          (u) => u._id === message.to.id[0]
        );
        this.messages.push(message);
        this.currentMessage(
          message.to.id[0],
          'USER',
          index,
          this.usersJoin[index]
        );
      }
    });

    this.loadMessages();
    this.messageSent();
  }

  async ngAfterViewInit() {
    await this.delay(3000);
    this.userService.getConnected(this.event._id).subscribe((res) => {
      this.currentNumber = res.data.filter(
        (c) => c.eventId === this.event._id
      ).length;
      this.userEx = res.data;
      this.formateUserExist();
    });
  }

  loadMessages() {
    this.messageService
      .getByEventId(this.event._id)
      // tslint:disable-next-line: deprecation
      .subscribe(
        (messages) => {
          this.messages = messages;
          // tslint:disable-next-line: max-line-length
          this.currentMessages = messages.filter(
            ({ to, spaceId }) =>
              (to.id[0] === this.idTo || to.id[0] === this.user._id) &&
              to.destType === this.messageType &&
              spaceId === this.currentSpace._id
          );
          // messages.forEach((element) => {
          //   if (element.from._id !== this.user._id) {
          //     const alreadyExist = this.userList.find(({ _id }) => _id === element.from._id);
          //   }
          // });
          this.hasData = true;
          if (this.eventResizeAdded === false) {
            setTimeout(this.chatChatResizeEvent, 500);
          }
        },
        (err) => {
          console.log(err);
        }
      );
  }

  get formControls() {
    return this.form.controls;
  }

  public checkMention(event) {
    this.search += event.data;
    let val = this.search.split('@');
    this.filtered = this.usersJoin.filter(function (str) {
      return (
        str.lastName.includes(val[val.length - 1]) ||
        str.firstName.includes(val[val.length - 1])
      );
    });
    if (this.filtered.length != 0) {
      this.autoMention = true;
    }
  }

  public removeAuto() {
    this.autoMention = false;
  }

  addEmoji(event: any) {
    this.formControls.message.setValue(
      `${this.form.value.message} ${event.emoji.native}`
    );
    this.isEmojiPickerVisible = false;
  }

  addMention(last: string, id: string) {
    this.formControls.message.setValue(`${this.form.value.message}${last}`);
    this.autoMention = false;
    this.mention.push(id);
  }

  async messageSent() {
    await this.socket.connect();
    this.socket.on('peopleUpdate', (content: any) => {
      if (content.type === 'messageWasSend') {
        if (
          content.message.to.destType === 'SPACE' &&
          content.message.spaceId === this.currentSpace._id
        ) {
          if (this.activeTab !== -1) {
            this.nbMessageSpace += 1;
          } else {
            this.currentMessages.push(content.message);
          }
        }
        if (
          (content.message.to.destType === 'SPACE' &&
            content.message.spaceId === this.currentSpace._id) ||
          this.user._id === content.message.to.id[0]
        ) {
          this.nbMess += 1;
        }

        if (this.user._id === content.message.to.id[0]) {
          const newMess = {
            _id: content.message.from._id,
            profilePicture: content.message.from.profilePicture,
            nb: 1,
            firstName: content.message.from.firstName,
            lastName: content.message.from.lastName,
            createdAt: content.message.createdAt,
          };
          const check = this.usersJoin.some(
            (c) => c._id === content.message.from._id
          );

          if (!check) {
            this.usersJoin.push(content.message.from);
            this.usersJoin = this.usersJoin.map((v) => {
              if (v._id === content.message.from._id) {
                return { ...v, nb: 0 };
              } else {
                return v;
              }
            });
          }
          const i = this.usersJoin.findIndex(
            (u) => u._id === content.message.from._id
          );

          if (this.activeTab != i) {
            this.addOrReplace(this.usersJoin, newMess);
          }

          if (this.activeTab === i) {
            this.currentMessages.push(content.message);
          }
        }
        this.messages.push(content.message);

        this.usersJoin = this.usersJoin.sort((a, b) => {
          if (a.nb > b.nb) {
            return -1;
          } else if (a.nb < b.nb) {
            return 1;
          } else {
            return 0;
          }
        });
        this.allNumberMessage();
      } else if (content.type === 'mentioned') {
        if (content.to.length != 0) {
          content.to.forEach((element) => {
            if (element === this.user._id) {
              if (content.destType === 'SPACE') {
                this.toastr.info(
                  content.from_name +
                    ' mentioned you in ' +
                    this.currentSpace.title,
                  'info'
                );
              } else {
                this.toastr.info(
                  content.from_name +
                    ' mentioned you in your private discussion',
                  'info'
                );
              }
            }
          });
        }
      }
    });
  }

  private addOrReplace(arr: any[], obj) {
    const index = arr.findIndex((e) => e._id === obj._id);
    if (index === -1) {
      arr.push(obj);
    } else {
      console.log('ato ato ato: ', this.usersJoin);
      arr[index].nb += 1;
      console.log('at ****************************o ato ato: ', this.usersJoin);
      if (this.activeTab === -1 && arr.length > 1) {
        arr.splice(index, 1);
        arr.unshift(obj);
      }
    }
  }

  async sendMessage() {
    if (this.form.invalid) {
      return;
    }
    const message = {
      spaceId: this.currentSpace._id,
      eventId: this.event._id,
      from: {
        _id: this.user._id,
        firstName: this.user.firstName,
        lastName: this.user.lastName,
        profilePicture: this.user.profilePicture,
      },
      to: {
        destType: this.messageType,
        id: [this.idTo],
      },
      content: this.form.value.message,
      createdAt: Date.now(),
    };

    this.messages.push(message);
    this.currentMessages.push(message);
    this.socket.emit('sendMessage', {
      eventId: this.event._id,
      spaceId: this.currentSpace._id,
      content: this.form.value.message,
      to: {
        destType: this.messageType,
        id: [this.idTo],
      },
    });

    this.formControls.message.setValue('');
    this.form.reset();

    this.socket.emit('mention', {
      spaceId: this.currentSpace._id,
      from_name: this.user.lastName,
      to: this.mention,
      destType: this.messageType,
    });
    this.formControls.message.setValue('');
  }

  goToHome() {
    this.home.emit(true);
  }

  sendTo(event: any) {
    if (this.toHide) {
      this.toHide.style.display = 'none';
      this.toHide = null;
    }
    event.stopPropagation();
    event.currentTarget.querySelector('.send-message-to').style.display =
      'block';
    this.toHide = event.currentTarget.querySelector('.send-message-to');
  }

  currentMessage(id: string, type: string, tab: number, user?) {
    if (user) {
      this.herMes = user;
    }
    this.messageType = type;
    this.idTo = id;
    this.activeTab = tab;
    this.nbMessageSpace = 0;
    this.usersJoin = this.usersJoin.map((v) => {
      if (v._id === id) {
        return { ...v, nb: 0 };
      } else {
        return v;
      }
    });

    this.allNumberMessage();
    if (this.messageType === 'SPACE') {
      this.herMes = undefined;
      this.currentMessages = this.messages.filter(
        ({ to, spaceId }) =>
          (to.id[0] === id || to.id[0] === this.user._id) &&
          to.destType === type &&
          spaceId === this.currentSpace._id
      );
      // this.showAllUsers = false;
    } else {
      this.currentMessages = this.messages.filter(
        ({ to, from }) =>
          (from._id === id || from._id === this.user._id) &&
          (to.id[0] === id || to.id[0] === this.user._id) &&
          to.destType === type
      );
    }
  }

  changeSender(event: any, frm: any, type: string) {
    event.preventDefault();
    this.messageType = 'USER';
    this.idTo = frm._id;
    this.messageType = type;
    const index = this.usersJoin.findIndex((u) => u._id === frm._id);
    if (index === -1) {
      this.usersJoin.push(frm);
      const i = this.usersJoin.findIndex((u) => u._id === frm._id);
      this.currentMessage(frm._id, 'USER', i);
      if (this.toHide) {
        this.toHide.style.display = 'none';
        this.toHide = null;
      }
    } else {
      this.currentMessage(frm._id, 'USER', index);
      if (this.toHide) {
        this.toHide.style.display = 'none';
        this.toHide = null;
      }
    }
  }

  ngOnDestroy() {
    this.socket.disconnect();
    this.usersJoin = [];
    this.currentMessages = [];
    this.messages = [];
    this.messageService.changeStatus(true);
    console.log('destroy message');
  }

  public statusDiscussion() {
    this.status = !this.status;
    this.newMessages = [];
    if (this.status) this.nbMess = 0;
    this.messageService.changeStatus(this.status);
    if (this.toHide) {
      this.toHide.style.display = 'none';
      this.toHide = null;
    }
  }

  public removePesron(id: string) {
    const index = this.usersJoin.findIndex((u) => u._id === id);
    this.usersJoin = this.usersJoin.filter((u) => {
      return u._id != id;
    });
    if (this.activeTab === index) {
      if (this.usersJoin.length != 0) {
        this.currentMessage(this.usersJoin[index - 1]._id, 'USER', index);
      } else {
        this.currentMessage(this.currentSpace._id, 'SPACE', -1);
      }
    }
  }

  /**
   * chat resize event
   */
  private chatChatResizeEvent = () => {
    if (this.eventResizeAdded === false) {
      console.log('*** call **');
      this.eventResizeAdded = true;

      let chatContainer = document.querySelector('#chat_body');
      let colResize = document.querySelector('#col_resize');

      const mousedown = (e: any) => {
        let prevX = e.clientX;
        const mousemove = (em: any) => {
          let container = chatContainer.getBoundingClientRect();
          let tmp = container.width + (prevX - em.clientX);
          if (tmp < document.body.offsetWidth && tmp > 250) {
            this.chatWidth = tmp;
          }
          prevX = em.clientX;
        };

        const mouseup = () => {
          window.removeEventListener('mousemove', mousemove);
          window.removeEventListener('mouseup', mouseup);
        };

        window.addEventListener('mousemove', mousemove);
        window.addEventListener('mouseup', mouseup);
      };

      colResize.addEventListener('mousedown', mousedown);
    }
  };

  public showMesssage(user, type: boolean) {
    if (type) {
      const index = this.usersJoin.findIndex((u) => u._id === user._id);
      this.currentMessage(user._id, 'USER', index);
      this.statusDiscussion();
    } else {
      this.currentMessage(this.currentSpace._id, 'SPACE', -1);
      this.statusDiscussion();
    }
  }
  // async showUsers(){
  //   this.showAllUsers = !this.showAllUsers;
  //   if(this.showAllUsers) {
  //     await this.delay(10);
  //     const s = document.getElementById('container-user');
  //     s.style.visibility = 'visible';
  //     s.style.animation = 'showing 1s both';
  //   } else {
  //     this.currentMessage(this.currentSpace._id, 'SPACE', -1);
  //   }
  // }

  async delay(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  formateUserExist() {
    if (this.userEx.length > 0) {
      for (const user of this.userEx) {
        if (user.eventId === this.event._id && user.userId != this.user._id) {
          this.userService.getUserById(user.userId).subscribe((res) => {
            const index = this.usersJoin.findIndex(
              (e) => e._id === user.userId
            );
            if (index === -1) {
              this.usersJoin.push({ ...res, nb: 0 });
            }
          });
        }
      }
    }
  }

  searchUser(key) {
    if (key != '') {
      this.usersJoinTmp = JSON.parse(JSON.stringify(this.usersJoin));
      this.usersJoin = this.usersJoin.filter(
        (user) =>
          user.firstName.toLowerCase().includes(key) ||
          user.lastName.toLowerCase().includes(key)
      );
    } else {
      this.userService.getConnected(this.event._id).subscribe((res) => {
        this.userEx = res.data;
        this.formateUserExist();
      });
    }
  }

  allNumberMessage() {
    this.totalNumberMessage = 0;
    for (const us of this.usersJoin) {
      this.totalNumberMessage += us.nb;
    }
  }

  showMosaic() {
    this.mediasoupService.changingStatusMosaic(true);
  }

  cardUser($event, user) {
    console.log('event click: ', $event);

    this.userService.changeUser(user, $event.clientX, $event.clientY);
    this.userService.changeCurrentStatus(true);
  }
}
