import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { environment } from '../../environments/environment';
import { Event } from '../models/Event.model';

@Injectable({
  providedIn: 'root',
})
export class EventService {
  API_URL = environment.apiUrl;
  eventsByCaterogy: any[] = [];
  public isMobile: boolean = false;

  hubsSubject = new Subject();
  searchSubject = new Subject();
  public switchEvent = new Subject();
  public directLinkData: any = {
    isDirect: false,
    link: ['login'],
    eventId: '',
    meetingId: '',
    codeMeet: '',
  };

  public myHubs: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public endMeeting$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );
  public initMeet$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );
  public hideFavorites$: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  public toggleFavorites$: BehaviorSubject<null | 'show' | 'hide'> =
    new BehaviorSubject<null | 'show' | 'hide'>(null);
  public userReadyOnLanding$: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  public joinIn$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );
  public saveFeedback$: BehaviorSubject<any> = new BehaviorSubject<any>({});
  public currentRect$: BehaviorSubject<any> = new BehaviorSubject<any>(
    undefined
  );
  public mosaicData$: BehaviorSubject<any> = new BehaviorSubject<any>(
    undefined
  );
  public messageData$: BehaviorSubject<any> = new BehaviorSubject<any>(
    undefined
  );
  public newBgHubdoor$: BehaviorSubject<any> = new BehaviorSubject<any>(
    undefined
  );
  public changeImage$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );
  public enterHubdoorZone$: BehaviorSubject<{ id: string; in: boolean }> =
    new BehaviorSubject<{ id: string; in: boolean }>({ id: null, in: false });
  public breaking$: BehaviorSubject<any> = new BehaviorSubject<any>({});
  public autoscroll$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );
  public chatBulle$: BehaviorSubject<any> = new BehaviorSubject<any>({});
  public toHome$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );
  public showAll$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );
  public onReady$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );
  public deletePolygon$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public showPoints$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public showSetting$: Subject<any> = new Subject<any>();
  public deletePoints$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public currentMeeting$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public isModeratorMeet$: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  public deplaceAvatar$: BehaviorSubject<any> = new BehaviorSubject<any>({
    x: null,
    y: null,
  });
  public startMeeting$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    null
  );
  public askMeetingCode$: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(null);
  public $loadBackground: Subject<boolean> = new Subject<boolean>();

  constructor(private http: HttpClient, private router: Router) {}

  public getAllMyEvents(): Observable<Array<Event>> {
    return this.http.get<Array<Event>>(this.API_URL + 'events/');
  }

  public getByMeetingPath(path: string): Observable<any> {
    return this.http.post<any>(this.API_URL + 'events/meetingPath', {
      path: path,
    });
  }

  public getRegistredEvents(): Observable<Array<Event>> {
    return this.http.get<Array<Event>>(this.API_URL + 'events/registred');
  }

  public getAllEvent(): Observable<any> {
    return this.http.get<any>(this.API_URL + 'events/all-event');
  }

  public getAllEventWithoutAuth(): Observable<any> {
    return this.http.get<any>(this.API_URL + 'events/all-events-without-auth');
  }

  public create(event: Event): Observable<any> {
    return this.http.post<any>(this.API_URL + 'events/', event);
  }

  public getById(id: string): Observable<Event> {
    return this.http.get<Event>(this.API_URL + 'events/one/' + id);
  }

  public getForHubinarById(id: string): Observable<Event> {
    return this.http.get<Event>(this.API_URL + 'events/hubinar/' + id);
  }

  public getByIdWithoutAuth(id: string): Observable<Event> {
    return this.http.get<Event>(this.API_URL + 'events/public/' + id);
  }

  public getByIdWithAuth(id: string): Observable<Event> {
    return this.http.get<Event>(this.API_URL + 'events/private/' + id);
  }

  public edit(event): Observable<any> {
    return this.http.patch<any>(this.API_URL + 'events/' + event._id, event);
  }

  public delete(event): Observable<any> {
    return this.http.delete<any>(this.API_URL + 'events/' + event._id, event);
  }

  public duplicate(event): Observable<any> {
    return this.http.post(this.API_URL + 'events/duplicate', event);
  }

  public openSpace(): Observable<any> {
    return this.http.get(this.API_URL + 'events/ouverture-space');
  }

  public changeJoin(ch: boolean) {
    this.joinIn$.next(ch);
  }

  public toHome(boolean: boolean) {
    this.toHome$.next(boolean);
  }

  public showAll(boolean: boolean) {
    this.showAll$.next(boolean);
  }

  public getAllPublicEvent(): Observable<any> {
    return this.http.get(this.API_URL + 'events/all-public-event');
  }

  public allOrMyHubs(item) {
    this.hubsSubject.next(item);
  }
  public checkHubs() {
    return this.hubsSubject.asObservable();
  }
  public searchEvent(text: string) {
    this.searchSubject.next(text);
  }
  public searchSubscribe() {
    return this.searchSubject.asObservable();
  }
  public addToFavorite(event: Event): Observable<any> {
    return this.http.post<any>(this.API_URL + 'events/add-to-favorite', event);
  }
  public redirectMyHubs(boolean: boolean) {
    this.myHubs.next(boolean);
  }
  public changeImage(boolean: boolean) {
    this.changeImage$.next(boolean);
  }
  public getOfficeOrEvent(type: String): Observable<any> {
    return this.http.get<any>(
      this.API_URL + 'events/get-hub-office-or-event/' + type
    );
  }
  public switchEventOffice(type: string) {
    this.switchEvent.next(type);
  }
  public switchEventSubscribe() {
    return this.switchEvent.asObservable();
  }
  public runBreaking(breaking: any) {
    this.breaking$.next(breaking);
  }
  public getMyFavorite(): Observable<any> {
    return this.http.get<any>(this.API_URL + 'events/my-favorite');
  }

  public saveFeedback(feed: any) {
    this.saveFeedback$.next(feed);
  }

  public changeCurrentRect(data: any) {
    this.currentRect$.next(data);
  }

  public changeMosaic(data: any) {
    this.mosaicData$.next(data);
  }

  public changeChatbulle(message: any) {
    this.chatBulle$.next(message);
  }

  public changemessage(message: any) {
    this.messageData$.next(message);
  }

  public randomString(length: number) {
    var result = '';
    var characters =
      'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  public getEventsByCategory(category) {
    return this.eventsByCaterogy.find((data) => data.category === category)
      ?.groupItem;
  }

  public getOthersEventsByIdUser(idUser) {
    return this.http
      .get<any>(this.API_URL + 'events/others-events/' + idUser)
      .toPromise();
  }
}
