import {
  Component,
  Input,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import ObjectID from 'bson-objectid';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { Socket } from 'ngx-socket-io';
import { ToastrService } from 'ngx-toastr';
import { Announcement } from 'src/app/models/announcement.model';
import { Event } from 'src/app/models/Event.model';
import { AnnouncementService } from 'src/app/services/event-core/announcement.service';
import { EventService } from 'src/app/services/event.service';

@Component({
  selector: 'app-annonce',
  templateUrl: './annonce.component.html',
  styleUrls: ['./annonce.component.scss'],
})
export class AnnonceComponent implements OnInit {
  @ViewChild('showLibraryModalManager') showLibraryManager: TemplateRef<any>;
  modalRef: BsModalRef;

  editorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: 'auto',
    minHeight: '0',
    maxHeight: 'auto',
    width: 'auto',
    minWidth: '0',
    translate: 'yes',
    enableToolbar: true,
    showToolbar: true,
    placeholder: 'Enter text here...',
    defaultParagraphSeparator: '',
    defaultFontName: '',
    defaultFontSize: '',
    fonts: [
      { class: 'arial', name: 'Arial' },
      { class: 'times-new-roman', name: 'Times New Roman' },
      { class: 'calibri', name: 'Calibri' },
      { class: 'comic-sans-ms', name: 'Comic Sans MS' },
    ],
    customClasses: [
      {
        name: 'quote',
        class: 'quote',
      },
      {
        name: 'redText',
        class: 'redText',
      },
      {
        name: 'titleText',
        class: 'titleText',
        tag: 'h1',
      },
    ],
    // uploadUrl: 'v1/image',
    // upload: (file: any) => {
    //   console.log("file: ", file);
    //   this.openModalWithClass(this.showLibraryManager, 'modal-lg');
    //   return file;
    //  },
    uploadWithCredentials: false,
    sanitize: true,
    toolbarPosition: 'top',
    toolbarHiddenButtons: [
      [
        'justifyLeft',
        'justifyCenter',
        'justifyRight',
        'justifyFull',
        'heading',
      ],
      ['customClasses', 'unlink', 'insertVideo'],
    ],
  };
  @Input() event: Event;
  @Input() selectedSpace;
  @ViewChild('templateParameter') templateParameter: TemplateRef<any>;
  @ViewChild('templateEdit') templateEdit: TemplateRef<any>;
  templateParameterModal: BsModalRef;
  templateEditModal: BsModalRef;
  breakingNews = {
    text: '',
    duration: 5,
    enabled: false,
    nombre: 0,
    index: 0,
    prochain: 0,
    repetition: 0,
  };
  public editing: string = '';
  public settings: boolean = true;
  public timeShow: boolean = false;
  public sendMores: any[] = [];
  public nbRepetition: number = 0;
  public nbInterval: number = 0;
  public currentIndex: number = 0;
  public indexModify: number = 0;
  public durationModify: number = 0;
  public restPassage: number = 0;
  public go: number = 0;
  public sec: number = 0;
  public nextPassage: string = '0 min';
  public ind: number = 0;
  public res: number = 0;
  htmlContent: any;
  public t;
  public announcement: Announcement = {
    _id: '',
    content: '',
    displayDuration: 0,
    passageNumber: 0,
  };
  public multipleAnnouncement: Announcement[] = [];
  public isModify: boolean = false;
  constructor(
    private modalService: BsModalService,
    private socket: Socket,
    private eventService: EventService,
    private toastr: ToastrService,
    private announcementService: AnnouncementService
  ) {}

  ngOnInit(): void {
    console.log(this.event.annonce);
  }

  openModalWithClass(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template, Object.assign({}));
  }

  closeModal(event: any) {
    if (event.status === 'close') {
      this.modalRef.hide();
    }
  }

  createAnnouncement() {
    this.isModify = false;
    this.announcement._id = new ObjectID().toString();
    this.announcement.passageNumber = 0;
    this.event.annonce.push(this.announcement);
    this.announcementService.creatAnnouncement(this.event, this.announcement);
    this.modalRef.hide();
    this.announcement = {
      _id: '',
      content: '',
      displayDuration: 0,
      passageNumber: 0,
    };
  }

  updateAnnouncement() {
    const idx = this.event.annonce.findIndex(
      (announcement: Announcement) => announcement._id === this.announcement._id
    );
    console.log(this.announcement);

    this.event.annonce[idx] = this.announcement;
    this.announcementService.updateAnnouncement(this.event, this.announcement);
    this.announcement = {
      _id: '',
      content: '',
      displayDuration: 0,
      passageNumber: 0,
    };
    this.modalRef.hide();
  }

  deleteAnnouncement(_id: string) {
    this.announcement = this.event.annonce.find(
      (announcement: Announcement) => announcement._id === _id
    );
    this.event.annonce = this.event.annonce.filter(
      (announcement: Announcement) => announcement._id !== _id
    );
    this.announcementService.deleteAnnouncement(this.event, this.announcement);
  }

  updateAutoscroll() {
    this.announcementService.updateAutoscroll(
      this.event,
      this.event.autoScroll
    );
  }

  playSingleAnnouncement(announcement: Announcement) {
    const idx = this.event.annonce.findIndex(
      (ann) => ann._id === announcement._id
    );
    console.log(idx);

    this.event.annonce[idx] = {
      _id: announcement._id,
      content: announcement.content,
      displayDuration: announcement.displayDuration,
      passageNumber: announcement.passageNumber + 1,
    };
    this.announcementService.playSingleAnnouncement(
      this.event,
      this.event.annonce[idx]
    );
  }

  addMultipleAnnouncement(announcement: Announcement) {
    this.multipleAnnouncement.push(announcement);
    console.log(this.multipleAnnouncement);
  }

  playMultipleAnnouncement() {
    this.announcementService.playMultipleAnnouncement(
      this.event,
      this.multipleAnnouncement
    );
  }

  updateBandSize() {
    this.announcementService.updateBandSize(this.event);
  }
}
