import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { User } from 'src/app/models/User.model';
import { MediasoupService } from 'src/app/services/event-core/mediasoup.service';

@Component({
  selector: 'app-sound-manager',
  templateUrl: './sound-manager.component.html',
  styleUrls: ['./sound-manager.component.scss'],
})
export class SoundManagerComponent implements OnInit {
  @Input() backgroundSound;
  public backgroundSoundVolume: number = 0.35;
  public backgourndSoundActive = true;
  public deviceStatus = {
    microphone: false,
  };
  public remoteAudios: { user: User; src: MediaStream; id: string }[] = [];
  public connected: boolean = false;
  constructor(private mediasoupService: MediasoupService) {}

  ngOnChanges(changes: SimpleChanges) {
    if (typeof changes.backgroundSound !== 'undefined') {
      console.log('back: ', this.backgroundSound);
      const audio = document.getElementById('backgroundSound');
      if (audio) {
        audio.removeAttribute('src');
        audio.setAttribute('src', this.backgroundSound);
      }
    }
  }
  ngOnInit(): void {
    this.initSubscibers();
  }

  initSubscibers() {
    this.mediasoupService.$inRoom.subscribe(async (inRoom) => {
      if (inRoom) {
        this.connected = inRoom;
        await this.delay(400);
        if (this.deviceStatus.microphone) this.addLocalMicrophone();
      }
    });
    this.mediasoupService.$newAudio.subscribe((audio) => {
      this.addRemoteAudio(audio);
    });

    this.mediasoupService.$removeAudio.subscribe((audio) => {
      this.removeRemoteAudio(audio);
    });

    this.mediasoupService.$userToMute.subscribe((mute) => {
      console.log('mute: ', mute);

      this.clickMute(mute);
    });
  }

  addRemoteAudio(audio: { user: User; src: MediaStream; id: string }) {
    if (this.connected) {
      this.remoteAudios.push(audio);
      let element = document.createElement('audio');
      element.srcObject = audio.src;
      element.id = audio.id;
      //@ts-ignore
      element.playsinline = false;
      element.autoplay = true;
      let remoteAudio = document.getElementById('remoteAudios');
      remoteAudio.appendChild(element);
    }
  }

  removeRemoteAudio(id) {
    const removed = document.getElementById(id);
    if (removed) {
      removed.removeAttribute('srcObject');
      this.remoteAudios = this.remoteAudios.filter((audio) => audio.id != id);
    }
  }

  async addLocalMicrophone(deviceId = null) {
    this.deviceStatus.microphone = true;
    await this.mediasoupService.produce(
      this.mediasoupService.mediaType.audio,
      deviceId
    );
    this.mediasoupService.$audioStatus.next(true);
  }

  removeLocalMicrophone() {
    this.deviceStatus.microphone = false;
    this.mediasoupService.closeProducer(this.mediasoupService.mediaType.audio);
    this.mediasoupService.$audioStatus.next(false);
  }

  clickMute(data: { user: User; isMute: boolean }) {
    const idConsumer = this.remoteAudios.find(
      (audio) => audio.user._id === data.user._id
    );
    const userToMute = document.getElementById(idConsumer.id);
    console.log('userTomute: ', userToMute);

    if (data.isMute) {
      userToMute.removeAttribute('srcObject');
    } else {
      // @ts-ignore
      userToMute.setAttribute('srcObject', idConsumer.src);
    }
  }

  changeBackgroundSound(value: number) {
    this.backgroundSoundVolume = value;
    if (value > 0) {
      this.backgourndSoundActive = true;
    } else {
      this.backgourndSoundActive = false;
    }
  }

  onSpeaking(data) {
    this.mediasoupService.onSpeaking(data);
  }

  async delay(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }
}
