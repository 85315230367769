import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule, Routes } from '@angular/router';
import { CoreComponent } from './pages/event-core/core/core.component';
import { CguComponent } from './pages/examples/cgu/cgu.component';
import { LandingpageComponent } from './pages/examples/landingpage/landingpage.component';
import { LoginpageComponent } from './pages/examples/loginpage/loginpage.component';
import { PasswordresetComponent } from './pages/examples/passwordreset/passwordreset.component';
import { PricingComponent } from './pages/examples/pricing/pricing.component';
import { ProfilepageComponent } from './pages/examples/profilepage/profilepage.component';
import { RegisterpageComponent } from './pages/examples/registerpage/registerpage.component';
import { SubscriptionComponent } from './pages/examples/subscription/subscription.component';
import { IndexComponent } from './pages/index/index.component';
import { MemberGuardService } from './services/member-guard.service';
import { ConnectedGuard } from './shared/guard/connected.guard';

const routes: Routes = [
  { path: '', redirectTo: 'member', pathMatch: 'full' },
  { path: 'home', component: IndexComponent, canActivate: [ConnectedGuard] },
  {
    path: 'profile',
    component: ProfilepageComponent,
    canActivate: [MemberGuardService],
  },
  {
    path: 'register',
    component: RegisterpageComponent,
    canActivate: [ConnectedGuard],
  },
  {
    path: 'login',
    component: LoginpageComponent,
    canActivate: [ConnectedGuard],
  },
  { path: 'cgu', component: CguComponent },
  { path: 'landing', component: LandingpageComponent },
  { path: 'member', loadChildren: './pages/member/member.module#MemberModule' },
  { path: 'public', loadChildren: './pages/public/public.module#PublicModule' },
  { path: 'reset-password/:token', component: PasswordresetComponent },
  { path: 'pricing', component: PricingComponent },
  { path: 'subscription/:id', component: SubscriptionComponent },
  {
    path: '403',
    loadChildren: './pages/forbidden/forbidden.module#ForbiddenPageModule',
  },
  { path: 'meeting/:id', component: CoreComponent },
  { path: 'space/:id', component: CoreComponent },
  {
    path: 'core-test',
    loadChildren: './pages/event-core/event-core.module#EventCoreModule',
  },
];

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    RouterModule.forRoot(routes, {
      useHash: true,
    }),
  ],
  exports: [],
})
export class AppRoutingModule {}
