import { Component, OnInit } from '@angular/core';
import { Offer } from 'src/app/models/Offer.model';
import { OfferService } from 'src/app/services/offer.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-pricing',
  templateUrl: './pricing.component.html',
  styleUrls: ['./pricing.component.scss']
})
export class PricingComponent implements OnInit {
  offer: Offer[];
  constructor(private offerService: OfferService, private router: Router) { }
  
  ngOnInit(): void {
    this.offerService.getOffers().subscribe((offer: Offer[]) => {
      if(offer) {
        this.offer = offer;
      }
    });
    console.log(this.offer);
  }

  onCommand(offer) {
    console.log(offer);
    this.router.navigate(['subscription/' + offer]);
  }
  
}
