import { Component, Input } from '@angular/core';
import { File } from 'src/app/interfaces/library-manager';

@Component({
    selector: 'app-other',
    templateUrl: './other.component.html',
    styleUrls: ['./other.component.scss']
})
export class OtherComponent {
    @Input() file: File | null;

    formatName(title: string) {
        return title.length > 15 && typeof title !== 'undefined' ? title?.substr(0, 15) + '...' : title;
    }
}