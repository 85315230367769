import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class MessagesService {
  API_URL = environment.apiUrl;

  public status$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

  public newMessage$: BehaviorSubject<any> = new BehaviorSubject<any>({});

  public joinUser$: BehaviorSubject<any> = new BehaviorSubject<any>({});

  public _idRemove$: BehaviorSubject<any> = new BehaviorSubject<any>({});

  constructor(private http: HttpClient) {}

  public getByEventId(id: string) {
    return this.http.get<any>(`${this.API_URL}messages/by-eventid/${id}`);
  }

  public changeStatus(status: boolean) {
    this.status$.next(status);
  }

  public changeUser(user: any) {
    this.joinUser$.next(user);
  }

  public removeUser(user: any) {
    this._idRemove$.next(user);
  }

  public newMessage(message: any) {
    this.newMessage$.next(message);
  }
}
