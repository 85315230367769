import { Guest } from './Event.model';
import { User } from './User.model';

export class Webinar {
  _id: string;
  owner: User | string;
  title: string = '';
  startDate = new Date();
  endDate = new Date();
  url = '';
  descriptionShort: string = '';
  descriptionLong: string = '';
  image = '';
  capacity: number = 10;
  socialFacebook = '';
  socialInstagram = '';
  socialTwitter = '';
  socialLinkedin = '';
  guestList = [] as Array<GuestWebinar>;
  isRecord: boolean = true;
  isPublic: boolean = false;
  color = '#ce008e';
  idEvent = '';
  idSpace = '';
  idZone = '';
  top: number = 30;
  font = '';
  isStarted: boolean = false;
  startedAt: number = 0;
  finishedAt: number = 0;
  recordStartedAt: number = 0;
  recordPausedAt: number = 0;
  recordResumeAt: number = 0;
  recordFinishedAt: number = 0;
  newTab: boolean = true;
  isExtern: boolean = true;
  definePlace: boolean = false;
  presenting: boolean = false;
  sharePosition: string = '';
  rScreenFull: boolean = true; // Partage des écrans en fullscreen : [oui]  / non
  rAffichageType: AffichageType = AffichageType.ANIMATOR_PARTICIPANT; //Affichage des visages : [animateur(s) + participant(s)]  /  animateur(s) uniquement / Personne, juste l'écran partagé
  rAffichageCamera: boolean = false; //Affichage des camera : [FULLSCREEN pour celui qui parle] = true  / Mosaic = false
  canToggleAffichage: boolean = true; //invités peuvent changer l'affichage des camera FULLSCREEN pour celui qui parle vers Mosaic : [Oui] / non
  nbClicks: number = 0;
}

export class GuestWebinar {
  user: Guest;
  role: Role;
}

export enum Role {
  'GUEST' = 'GUEST',
  'CONTRIBUTOR' = 'CONTRIBUTOR',
  'MODERATOR' = 'MODERATOR',
}

export enum AffichageType {
  'ANIMATOR_PARTICIPANT' = 'ANIMATOR_PARTICIPANT',
  'ANIMATOR_ONLY' = 'ANIMATOR_ONLY',
  'SCREEN_ONLY' = 'SCREEN_ONLY',
}
