import { animate, style, transition, trigger } from "@angular/animations";
import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { User } from "src/app/models/User.model";
import { AuthService } from "src/app/services/auth.service";
import { EventService } from "./../../../services/event.service";
import { TranslateService } from "@ngx-translate/core";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-login-form",
  templateUrl: "./login-form.component.html",
  styleUrls: ["./login-form.component.scss"],
  animations: [
    trigger("fadeInAnimation", [
      transition(":enter", [
        style({ opacity: 0 }),
        animate(".9s", style({ opacity: 1 })),
      ]),
    ]),
  ],
})
export class LoginFormComponent implements OnInit {
  API_URL = environment.apiUrl;
  terms = false;
  loading = false;
  registred = false;
  resetPassword = false;
  userMailReset = "";
  user = new User();
  confirmPassword: string;
  isRegister = false;
  @Input() id: string | null;
  @Input() loginPage: boolean | false;
  @Output() isGuest = new EventEmitter();
  redirectPage: string = null;
  fieldTextType: boolean;
  fieldTextType2: boolean;

  constructor(
    private authService: AuthService,
    private toastr: ToastrService,
    private router: Router,
    private eventService: EventService,
    private translate: TranslateService
  ) {}
  toggleFieldTextType() {
    this.fieldTextType = !this.fieldTextType;
  }
  toggleFieldTextType2() {
    this.fieldTextType2 = !this.fieldTextType2;
  }
  ngOnInit(): void {
    // var cookie = this.getCookie('userActived');
    // if(cookie) {
    //     this.toastr.success('Votre email est activé');
    //     this.user.email = String(cookie).replace('%40','@');
    //     document.cookie = "userActived=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    // }
    var redirect = localStorage.getItem("redirect");
    if (redirect) {
      this.redirectPage = redirect;
      // localStorage.removeItem('redirect');
    }
    var emailPassowrdReset = localStorage.getItem("userPasswordReset");
    if (emailPassowrdReset) {
      this.user.email = emailPassowrdReset;
      localStorage.removeItem("userPasswordReset");
    }
  }

  getCookie(name) {
    var value = "; " + document.cookie;
    var parts = value.split("; " + name + "=");
    if (parts.length >= 2) return parts.pop().split(";").shift();
  }

  onLoginWithGoogle() {
    this.loading = true;
    this.authService.loginWithGoogle().then(
      (result) => {
        if (!!result) {
          this.toastr.success(this.translate.instant("CONNECTED"));
          console.log("eto ve");
          const directLinkSpace = JSON.parse(
            localStorage.getItem("directLinkSpace")
          );
          const directLinkMeet = JSON.parse(
            localStorage.getItem("directLinkMeet")
          );
          if (directLinkSpace) {
            this.router.navigate([
              "core-test",
              "event",
              directLinkSpace.eventId,
            ]);
          } else if (directLinkMeet) {
            this.router.navigate([
              "core-test",
              "event",
              directLinkMeet.eventId,
            ]);
          } else {
            if (!this.loginPage) {
              window.open("/#/public/landing/" + this.id, "_self");
            } else {
              this.router.navigate(["member"]);
            }
          }
        } else {
          this.toastr.warning("Impossible de se connecter");
        }
        this.loading = false;
      },
      () => {
        this.toastr.warning("Impossible de se connecter");
        this.loading = false;
      }
    );
  }

  onLoginWithFacebook() {
    this.loading = true;
    this.authService.loginWithFacebook().then(
      (result) => {
        if (!!result) {
          this.toastr.success("CONNEXION REUSSIE");
          const directLinkSpace = JSON.parse(
            localStorage.getItem("directLinkSpace")
          );
          const directLinkMeet = JSON.parse(
            localStorage.getItem("directLinkMeet")
          );
          if (directLinkSpace) {
            this.router.navigate([
              "core-test",
              "event",
              directLinkSpace.eventId,
            ]);
          } else if (directLinkMeet) {
            this.router.navigate([
              "core-test",
              "event",
              directLinkMeet.eventId,
            ]);
          } else {
            if (!this.loginPage) {
              window.open("/#/public/landing/" + this.id, "_self");
            } else {
              this.router.navigate(["member"]);
            }
          }
        } else {
          this.toastr.warning("Impossible de se connecter");
        }
        this.loading = false;
      },
      () => {
        this.toastr.warning("Impossible de se connecter");
        this.loading = false;
      }
    );
  }

  onLogin() {
    this.loading = true;
    this.authService
      .loginWithPassword(this.user.email, this.user.password)
      .then(
        (result) => {
          console.log("login 1: ", result);

          if (result.success) {
            this.toastr.success("CONNEXION REUSSIE");
            const directLinkSpace = JSON.parse(
              localStorage.getItem("directLinkSpace")
            );
            const directLinkMeet = JSON.parse(
              localStorage.getItem("directLinkMeet")
            );
            if (directLinkSpace) {
              this.router.navigate([
                "core-test",
                "event",
                directLinkSpace.eventId,
              ]);
            } else if (directLinkMeet) {
              this.router.navigate([
                "core-test",
                "event",
                directLinkMeet.eventId,
              ]);
            } else {
              if (this.loginPage) {
                if (this.redirectPage != null) {
                  window.open(
                    "/#/public/landing/" + this.redirectPage,
                    "_self"
                  );
                } else {
                  if (result.firstConnexion) {
                    localStorage.setItem("firstConnexion", "true");
                  }
                  this.router.navigate(["member"]);
                }
              } else {
                window.open("/#/public/landing/" + this.id, "_self");
              }
            }
          } else {
            this.toastr.warning(result.msg);
          }
          this.loading = false;
        },
        () => {
          this.toastr.warning("Impossible de se connecter");
          this.loading = false;
        }
      );
  }

  register() {
    this.loading = true;
    let link = "";
    const currentUrl: string = window.location.toString();
    // if (currentUrl.includes("app.hublive")) {
    //   link = " ";
    // } else if (currentUrl.includes("localhost")) {
    //   link = "http://localhost:4200/";
    // } else if (currentUrl.includes("int.hublive")) {
    //   link = "https://int.hublive.io/secure/";
    // } else if (currentUrl.includes("upstream")) {
    //   link = "https://upstream.upskilling.com/secure/";
    // } else {
    //   link = "https://hublive.io/secure/";
    // }
    const data = {
      user: this.user,
      link: this.API_URL,
    };
    this.authService.registerWithPassword(data).subscribe((result) => {
      console.log(result);
      if (result.success) {
        this.registred = true;
        if (!this.loginPage) {
          localStorage.setItem("redirect", this.id);
        }
      } else {
        if (result.exists) {
          this.toastr.warning("l'email que vous avez saisi est déjà pris");
        } else if (result.length) {
          this.toastr.warning("La taille de votre nom doit être supérieur à 3");
        }
      }
      this.loading = false;
    });
  }

  areAllFieldFillRegister() {
    return (
      this.user.password === this.confirmPassword &&
      this.terms &&
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
        this.user.email
      ) &&
      this.user.firstName &&
      this.user.lastName
    );
  }

  areAllFieldFillLogin() {
    return (
      this.user.password &&
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
        this.user.email
      )
    );
  }

  checkMail() {
    return /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
      this.userMailReset
    );
  }

  sendResetMail() {
    this.loading = true;
    let link = "";
    const currentUrl: string = window.location.toString();
    if (currentUrl.includes("app.hublive")) {
      link = "https://app.hublive.io";
    } else if (currentUrl.includes("localhost")) {
      link = "http://localhost:4200";
    } else if (currentUrl.includes("int.hublive")) {
      link = "https://int.hublive.io";
    } else if (currentUrl.includes("upstream")) {
      link = "https://upstream.upskilling.com";
    } else {
      link = "https://hublive.io";
    }
    this.authService
      .resetPassword(this.userMailReset, link)
      .subscribe((res) => {
        console.log("res ==========> ", res);
        if (res.success) {
          this.userMailReset = "";
          this.resetPassword = false;
          this.toastr.success(this.translate.instant("VERIFY_EMAIL_SPAM"));
        } else if (res.error) {
          this.toastr.warning(this.translate.instant("NO_ANY_MAILS_IN_FILE"));
        }
        this.loading = false;
      });
  }

  onGuest() {
    this.isGuest.emit("true");
  }
}
