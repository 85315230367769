import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Socket } from 'ngx-socket-io';
import { Observable, Subject } from 'rxjs';
import { Message } from 'src/app/models/Message.model';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ChatService {
  private API_URL = environment.apiUrl;
  public newMessage$ = new Subject<Message>();
  public numberUnreadMessage$ = new Subject<{
    type: 'add' | 'remove' | 'init';
    nb: number;
  }>();

  constructor(private httpClient: HttpClient, private socket: Socket) {
    this.initSocket();
  }

  private initSocket() {
    this.socket.connect();
    this.socket.on('newMessage', (message: Message) => {
      this.newMessage$.next(message);
    });
  }

  public getAllConversations(eventId: string, spaceId: string, userId: string): Observable<Message[]> {
    return this.httpClient.get<Message[]>(`${this.API_URL}messages/allConversations/${eventId}/${spaceId}/${userId}`);
  }

  public sendMessage(message: Message) {
    this.socket.emit('sendNewMessage', {
      ...message,
      // from: message.from._id,
      // to: message.to ? message.to._id : null,
    });
  }

  public markAsRead(spaceId: string, eventId: string, from: string, to: string, isPrivate: boolean, userId: string): Observable<boolean> {
    return this.httpClient.post<boolean>(`${this.API_URL}messages/mark-as-read`, {
      spaceId,
      eventId,
      from,
      to,
      isPrivate,
      userId,
    });
  }
}
