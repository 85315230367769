import { Component, Input } from '@angular/core';
import { File } from 'src/app/interfaces/library-manager';

@Component({
    selector: 'app-img',
    templateUrl: './img.component.html',
    styleUrls: ['./img.component.scss']
})
export class ImgComponent {
    @Input() file: File | null;

    formatName(title: string) {
        return title.length > 10 && typeof title !== 'undefined' ? title?.substr(0, 10) + '...' : title;
    }
}