import { Component, Input } from '@angular/core';
import { File } from 'src/app/interfaces/library-manager';

@Component({
    selector: 'app-song',
    templateUrl: './song.component.html',
    styleUrls: ['./song.component.scss']
})

export class SongComponent {
    @Input() file: File | null;

    formatName(title: string) {
        return title.length > 15 && typeof title !== 'undefined' ? title?.substr(0, 15) + '...' : title;
    }
}