import { LibraryManagerService } from './../../../../services/library-manager.service';
import { Component, Input, Output, EventEmitter } from '@angular/core';
import { LibraryManager, File } from 'src/app/interfaces/library-manager';
import { UploadService } from 'src/app/services/upload.service';

@Component({
  selector: 'app-uploads',
  templateUrl: './uploads.component.html',
  styleUrls: ['./uploads.component.scss']
})
export class UploadsComponent {
  files: any[] = [];
  loading = false;

  @Input() activeFile!: File[] | null;
  @Input() activeLib!: LibraryManager | null;
  @Input() activeView!: number | null;

  @Output() img = new EventEmitter();
  @Output() selectImg = new EventEmitter();

  constructor(
    private libraryManagerService: LibraryManagerService, 
    private uploadService: UploadService) {}

  /**
   * on file drop handler
   */
  onFileDropped($event) {
    this.prepareFilesList($event);
  }

  /**
   * handle file from browsing
   */
  fileBrowseHandler(files) {
    this.prepareFilesList(files);
  }

  /**
   * Delete file from files list
   * @param index (File index)
   */
  deleteFile(index: number) {
    this.files.splice(index, 1);
    this.activeFile.splice(index, 1);
  }

  /**
   * Simulate the upload process
   */
  uploadFilesSimulator(index: number) {
    setTimeout(() => {
      if (index === this.files.length) {
        return;
      } else {
        const progressInterval = setInterval(() => {
          if (this.files[index].progress === 100) {
            clearInterval(progressInterval);
            this.uploadFilesSimulator(index + 1);
          } else {
            this.files[index].progress += 5;
          }
        }, 200);
      }
    }, 1000);
  }

  /**
   * Convert Files list to normal array list
   * @param files (Files List)
   */
  prepareFilesList(files: Array<any>) {
    this.activeFile.push({
      _id: 'in_progress',
      title: files[0].name,
      date: new Date(),
      type: '',
      url: ''
    });
    (this.activeFile[this.activeFile.length - 1] as any ).progress = 50;
    this.libraryManagerService.uploadFilesWithId(files, this.activeLib._id)
    // tslint:disable-next-line: deprecation
    .subscribe((data: any) => {
      (this.activeFile[this.activeFile.length - 1] as any ).progress = 100;
      setTimeout(() => {
        for (const item of data.files) {
          this.activeFile[this.activeFile.length - 1] = ({
            _id: item._id,
            title: item.title,
            date: new Date(item.date),
            type: item?.type,
            url: item.url
          });
          this.img.emit(item);
        }
        this.files = [];
        this.uploadFilesSimulator(0);
      }, 1000);
    }, err => {
      console.error(err);
    })
  }

  /**
   * format bytes
   * @param bytes (File size in bytes)
   * @param decimals (Decimals point)
   */
  formatBytes(bytes, decimals) {
    if (bytes === 0) {
      return '0 Bytes';
    }
    const k = 1024;
    const dm = decimals <= 0 ? 0 : decimals || 2;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }

  formatName(title: string) {
    return title.length > 15 && typeof title !== 'undefined' ? title?.substr(0, 15) + '...' : title;
  }

  selectFile(file: File) {
    console.log('file ', file);
    this.selectImg.emit(file);
  }

  deletFile(e) {
    this.loading = true;
    var nameArray = String(e.url).split('/');
    var name = nameArray[nameArray.length - 1];
    console.log('name ', name);
    var url = new URL(e.url);
    var file = {url: url.pathname.replace('/uploads', '')};
    this.uploadService.deleteFile(name, file).subscribe((res) => {
      console.log('file =======> ', res);
      this.libraryManagerService.deleteFile({idFile: e._id, idLib: this.activeLib._id}).subscribe((res) => {
        console.log('file on lib =======> ', res);
        var index = this.activeLib.files.findIndex(f => f._id == e._id);
        if(index => 0) {
          this.activeLib.files.splice(index, 1);
        }
        this.loading = false;
      });
    });
  }
}
