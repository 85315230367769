import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Subscription } from '../models/Subscription.model';

@Injectable({
  providedIn: 'root'
})
export class SubscriptionService {
  API_URL = environment.apiUrl;
  
  constructor(private http: HttpClient) { }

  getSubscription(id): Observable<any> {
    return this.http.get<any>(this.API_URL + 'subscription/' + id);
  }

  createSubscritpion(data: any): Observable<any> {
    return this.http.post<any>(this.API_URL + 'subscription/create', data);
  }

  subscription(data: any): Observable<any> {
    return this.http.post<any>(this.API_URL + 'subscription/stripe', data);
  }
  cancelSubscription(subscription: Subscription): Observable<any> {
    return this.http.post<any>(this.API_URL + 'subscription/cancel-subscription', subscription);
  }

  reactivateSubscription(subscription: Subscription): Observable<any> {
    return this.http.post<any>(this.API_URL + 'subscription/reactivate-subscription', subscription);
  }

  changeSubscription(params: any): Observable<any> {
    return this.http.post<any>(this.API_URL + 'subscription/change-subscription', params);
  }

  updateSubscription(params: any): Observable<any> {
    return this.http.post<any>(this.API_URL + 'subscription/update-subscription', params);
  }
}
