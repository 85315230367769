import { Injectable } from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {AuthService} from './auth.service';
import {ToastrService} from 'ngx-toastr';
import {TranslateService} from '@ngx-translate/core';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MemberGuardService implements CanActivate {

  constructor(private auth: AuthService,
              private router: Router,
              private toastr: ToastrService,
              private translate: TranslateService) { }

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        if (!this.auth.isAuthenticated()) {
            this.toastr.warning('', this.translate.instant('YOU_NEED_TO_LOGIN'));
            this.router.navigate(['login']);
            return false;
        }
        return true;
    }
}
