import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Socket } from 'ngx-socket-io';
import { Notification } from 'src/app/models/Notification.model';
import { Space, Event, Hours } from 'src/app/models/Event.model';
import { EventService } from 'src/app/services/event.service';
import { NotificationService } from 'src/app/services/notification.service';
import { UserService } from 'src/app/services/user.service';
import { User } from '../../models/User.model';
import { AuthService } from '../../services/auth.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-headbar',
  templateUrl: './headbar.component.html',
  styleUrls: ['./headbar.component.scss'],
})
export class HeadbarComponent implements OnInit {
  user: User;
  currentUrl: string;

  show = true;
  isMyFavorites: boolean = false;
  availableLanguages = [];
  selectedLanguage = 'en';
  public isLandingPage: boolean = false;
  isMyHubs = true;
  hubEvent = false;
  hubOffice = false;
  isDashboard = false;
  notifications: Notification[];
  numberOfNotification = 0;
  limitNotification = 5;
  loadingNotification = false;
  loadingDelNotif = false;
  navbarOpen = false;
  event: Event;
  toggleNavbar() {
    this.navbarOpen = !this.navbarOpen;
  }
  constructor(
    private authService: AuthService,
    private route: ActivatedRoute,
    private router: Router,
    private translateService: TranslateService,
    private userService: UserService,
    private eventService: EventService,
    private notificationService: NotificationService,
    private socket: Socket,
    private toastr: ToastrService,
    private translate: TranslateService
  ) {}

  ngOnInit(): void {
    this.authService.getCurrentUser().subscribe(
      (user) => {
        this.user = user;
        setTimeout(() => {
          this.connectSocketIo(this.user._id);
          this.getNotification(this.user._id, this.limitNotification);
        }, 2000);
      },
      () => {
        localStorage.removeItem('token');
        this.router.navigate(['login']);
      }
    );

    this.userService.modifyUser$.subscribe((res) => {
      if (res) {
        this.userService.current().subscribe((value) => {
          // this.user = value;
          this.authService.user.next(value);
        });
      }
    });
    this.router.events.subscribe((event: any) => {
      if (event.url) {
        this.currentUrl = event.url;
        if (
          this.currentUrl.includes('/public/event/') ||
          this.currentUrl.includes('/public/guest/') ||
          this.currentUrl.includes('/hubinar') ||
          this.currentUrl.includes('/welcome') ||
          this.currentUrl.includes('/login') ||
          this.currentUrl.includes('/reset-password') ||
          this.currentUrl.includes('/core-test')
        ) {
          this.show = false;
        } else {
          this.show = true;
        }
        //remove login buton
        if (event.url.includes('/public/landing/')) {
          this.isLandingPage = true;
        } else this.isLandingPage = false;
        if (this.currentUrl.includes('/member/dashboard')) {
          this.isDashboard = true;
        } else this.isDashboard = false;
      }
    });

    // Get languages
    this.availableLanguages = this.translateService.getLangs();
    console.log(this.availableLanguages);
    this.selectedLanguage = this.translateService.currentLang;
    this.eventService.hideFavorites$.subscribe((hide) => {
      if (hide) {
        this.isMyFavorites = false;
      }
    });
  }

  // Because en is not a flag :/
  getFlagLanguage(language: string) {
    switch (language) {
      case 'en':
        return 'gb';
      default:
        return language;
    }
  }

  changeLanguage(language: string) {
    localStorage.setItem('language', language);
    this.translateService.use(language);
    this.selectedLanguage = language;
  }

  logout() {
    this.authService.logout();
  }

  // getMyHugs() {
  //   this.eventService.hideFavorites$.next(true);
  //   if (!this.currentUrl.includes('/member/dashboard')) {
  //     this.eventService.redirectMyHubs(true);
  //     this.router.navigate(['/member/dashboard']);
  //   } else {
  //     this.eventService.allOrMyHubs(this.isMyHubs);
  //   }
  //   if (this.isMyHubs) {
  //     this.isMyHubs = false;
  //   } else {
  //     this.isMyHubs = true;
  //   }
  // }

  searchEvent(text) {
    console.log('headbar ', text);
    this.eventService.searchEvent(text);
  }

  goToHubEvent() {
    console.log('hubEvent');
    this.hubOffice = false;
    this.hubEvent = true;
    this.router.navigate(['/member/dashboard']);
    this.eventService.switchEventOffice('event');
  }

  goToHubOffice() {
    console.log('hubOffice');
    this.hubEvent = false;
    this.hubOffice = true;
    this.router.navigate(['/member/dashboard']);
    this.eventService.switchEventOffice('office');
  }

  getNotification(userId, limit) {
    this.notificationService
      .getAllNotification(userId, limit)
      .subscribe((res: Notification[]) => {
        if (res) {
          this.notifications = res;
          for (let notif of this.notifications) {
            if (notif.isView) {
              this.numberOfNotification += 1;
            }
          }
        }
        if (this.loadingNotification) {
          this.loadingNotification = false;
        }
      });
  }

  async connectSocketIo(userId) {
    await this.socket.connect();
    this.socket.emit('joinToNotify', { userId: userId });
    this.socket.on('notify', (content) => {
      console.log('notify **** ', content);
      this.numberOfNotification++;
      switch (content.type) {
        case 'share':
          this.notifications.unshift(content.value);
          break;
        default:
          break;
      }
    });
  }

  toggleNotificationMenu(menu) {
    menu.classList.toggle('showElement-notification');
    if (this.numberOfNotification > 0) {
      this.numberOfNotification = 0;
      this.socket.emit('restoreNumberOfNotification', {
        userId: this.user._id,
      });
    }
  }

  viewNotification(target) {
    console.log('target **** ', target);
    this.router.navigate(['/public/landing/' + target]);
  }

  showMoreNotification() {
    this.limitNotification += 5;
    this.loadingNotification = true;
    this.getNotification(this.user._id, this.limitNotification);
  }

  deleteNotification(_id) {
    this.loadingDelNotif = true;
    this.notificationService.deleteNotification(_id).subscribe((res) => {
      if (res.success) {
        const index = this.notifications.findIndex((nt) => nt._id == _id);
        if (index >= 0) {
          this.notifications.splice(index, 1);
        }
      }
      this.loadingDelNotif = false;
    });
  }
  goToDash() {
    this.router.navigate(['member', 'dashboard']).then(() => {
      // window.location.reload();
    });
  }

  getMyFavorites() {
    this.isMyHubs = true;
    this.isMyFavorites = !this.isMyFavorites;
    if (this.isMyFavorites) {
      this.eventService.toggleFavorites$.next('show');
    } else {
      this.eventService.toggleFavorites$.next('hide');
    }
  }

  createEvent() {
    this.event = new Event();
    this.event.title = this.translate.instant('NEW_EVENT');
    if (this.event.spaces.length === 0) {
      const newSpace = new Space();
      newSpace.isDefault = true;
      newSpace.title = this.translate.instant('HOME');
      this.event.spaces.push(newSpace);
    }
    if (!this.event.agenda) {
      this.event.agenda = [];
    }
    if (!this.event.hours) {
      this.event.hours = new Hours();
    }
    this.eventService.create(this.event).subscribe(
      (result) => {
        if (result.success) {
          this.toastr.success('', this.translate.instant('EVENT_CREATED'));
          this.router.navigate([
            'member/event/add-edit-event/' + result.event._id,
          ]);
          // window.location.reload();
        } else if (result.cantCreate) {
          this.toastr.warning(
            '',
            this.translate.instant('CANNOT_CREATE_EVENT')
          );
        } else {
          this.toastr.warning('', this.translate.instant('ERROR'));
        }
      },
      (e) => {
        console.log(e);
        this.toastr.warning('', this.translate.instant('ERROR'));
      }
    );
  }
}
